import * as angular from 'angular';
import * as moment from "moment";
import {saveAs} from 'file-saver';
import * as $ from "jquery";
import {scanStationCtrlObj} from './ScanStationCtrlObj';
import {ColourScheme} from './ColourScheme.interface';
import {AutoGenerateData} from './AutoGenerateData.interface';
import {DataFiltering} from './DataFiltering.interface';
import {AllDataInterface} from './AllData.interface';
import FieldRulesToAdd from "../../components/field-rule/model/fieldRulesToAdd";
import SignaturePad from 'signature_pad/src/signature_pad';
import {type} from "os";


'use strict';

angular.module('scanStationCtrl')
    .controller('scanStationController', ScanStationController);

ScanStationController.$inject = ['$scope', 'scanStation', 'Auth', '$state', '$templateCache', '$window',
    '$interval', '$filter', 'User', '$sce', 'Data', 'FilterByFieldService', 'ScanStationService',
    'PieChartField', 'InvalidEmailExceptionFactory', 'fieldParameterFactory', 'DataFromStationFieldsFactory',
    'fieldService', 'fieldValidation', 'SuperuserService', '$rootScope', 'Configuration', 'RolesAndPermissionsFactory',
    'fieldRuleValidationFactory', 'GridFactory', 'FormFactory', 'CrossBlocDataUpdatesFieldFactory',
    'status', 'ScanStationTinyMceService', 'SubBlocFieldFactory', 'LinkFieldFactory', 'MindeeFactory', 'MailAlertFactory',
    'FileBuilderFactory', 'FormValidator', 'MessageService', 'DateService', 'pageState', 'BlocworxGatewayFactory'
];

function ScanStationController($scope, scanStation, Auth, $state, $templateCache, $window,
                               $interval, $filter, User, $sce, Data, FilterByFieldService, ScanStationService,
                               PieChartField, InvalidEmailExceptionFactory, fieldParameterFactory, DataFromStationFieldsFactory,
                               fieldService, fieldValidation, SuperuserService, $rootScope, Configuration, RolesAndPermissionsFactory,
                               fieldRuleValidationFactory, GridFactory, FormFactory, CrossBlocDataUpdatesFieldFactory,
                               status, ScanStationTinyMceService, SubBlocFieldFactory, LinkFieldFactory, MindeeFactory, MailAlertFactory,
                               FileBuilderFactory, FormValidator, MessageService, DateService, pageState, BlocworxGatewayFactory) {

    let vm = this;

    vm.$onInit = async function () {

        MessageService.new();
        vm.updateLaterOn = undefined;
        vm.reloadData = false;
        vm.auth = Auth;
        vm.userData = await vm.auth.isLoggedIn();
        vm.scanStationService = scanStation;
        vm.id = $state.params.stationId;
        vm.jobID = $state.params.id;
        vm.stateParams = $state.params;
        vm.modalStatus = 'off';
        vm.dataToAdd = {};
        vm.filesToAdd = {};
        vm.form = FormValidator;
        vm.prompt = false;
        vm.yellowPrompt = false;
        vm.focusFormFirst = false;
        vm.focusFormSecond = false;
        vm.submitForm = false;
        vm.preventFormSubmission = false;
        vm.focusWhere = '';
        vm.error = false;
        vm.showUndo = false;
        vm.updating = false;
        vm.takePhotoStatus = 'closed';
        vm.stateName = $state.current.name;
        vm.cartolyticsCustomerID = $window.localStorage.getItem('cartolyticsCustomerID');
        vm.submittedStatus = 1;
        vm.entryCycleKey = 1;
        vm.entryCycleKeySlug = '';
        vm.hiddenDataToPopulate = {};
        vm.updating = false;
        vm.userSearchKey = [];
        vm.returnedStationID = false;
        vm.userEmail = "";
        vm.showAlertFieldPopUp = false;
        vm.scanStationEditMode = false;
        vm.increaseTimeFieldInterval = false;
        vm.totalsField = {};
        vm.timeDiffFields = {};
        vm.parseResultsBoxArray = {};

        vm.resultBoxArray = {};
        vm.resultBoxHeaderData = {};
        vm.resultBoxData = {};

        vm.showDatePickerField = [];
        vm.showDateInput = [];
        vm.andGateParamArray = {};
        vm.subformArray = {};
        vm.dataToFormArray = [];
        vm.domIndexes = {};
        vm.filterByFields = {};
        vm.preventRuleCall = [];
        vm.uniqueModuleSlug = $state.params.uniqueModuleSlug;
        vm.currentSelectedFieldParameters = {};
        vm.tokenRef = localStorage.getItem('satellizer_token');


        vm.scanStationObj = new scanStationCtrlObj();
        vm.scanStationObj.id = $state.params.stationId;
        vm.scanStationObj.jobID = $state.params.id;
        vm.scanStationObj.stateParams = $state.params;
        vm.scanStationObj.stateName = $state.current.name;
        vm.scanStationObj.cartolyticsCustomerID = $window.localStorage.getItem('cartolyticsCustomerID');
        vm.scanStationObj.restarted = false;

        vm.currentTarget = false;

        vm.fieldRulesToAdd = [];
        vm.editNewRuleCase = false;
        vm.fieldRulesToDelete = [];
        vm.existingFileRulesDisplay = [];

        vm.showFormceptionPopup = false;

        vm.fieldsToDelete = [];
        vm.fieldsToUpdate = [];

        vm.selectedSOPFiles = [];

        vm.selectedRolesForLoadingUsers = [];

        vm.stationDataForDropDown = [];

        vm.searchResult = [];

        vm.showMoreDataBox = false;
        vm.scanStationFieldDesignOptions = [];
        vm.selectedRolesForLoadingUsers = [];
        vm.actions = false;
        vm.editRule = false;
        vm.superUser = SuperuserService;
        let randomValue = Configuration.getRandomValue();
        let version = Configuration.getVersion();
        vm.editFieldsUrl = 'views/bloc-editor-html-files/edit-create-fields.html?v=' + version + 'rand=' + randomValue;
        vm.fieldRulesParameters = 'views/bloc-editor-html-files/field-rules-parameters.html?v=' + version + 'rand=' + randomValue;
        vm.gridPreview = 'views/bloc-editor-html-files/grid-preview.html?v=' + version + 'rand=' + randomValue;
        vm.blocHasDataAlert = 'views/bloc-editor-html-files/bloc-has-data-alert.html?v=' + version + 'rand=' + randomValue;
        vm.mainBlocSettings = 'views/bloc-editor-html-files/main-bloc-settings.html?v=' + version + 'rand=' + randomValue;
        vm.uniqueSlugs = 'views/bloc-editor-html-files/unique-slugs.html?v=' + version + 'rand=' + randomValue;
        vm.blocLayoutAndVisibilitySettings = 'views/bloc-editor-html-files/bloc-layout-and-visibility-settings.html?v=' + version + 'rand=' + randomValue;
        vm.dataDisplaySettings = 'views/bloc-editor-html-files/data-display-settings.html?v=' + version + 'rand=' + randomValue;
        vm.userRestrictedDataSettings = 'views/bloc-editor-html-files/user-restricted-data-settings.html?v=' + version + 'rand=' + randomValue;
        vm.mailAlertSettings = 'views/bloc-editor-html-files/mail-alert-settings.html?v=' + version + 'rand=' + randomValue;
        vm.mailAlertSettings = 'views/bloc-editor-html-files/mail-alert-settings.html?v=' + version + 'rand=' + randomValue;
        vm.manageDocExportTemplates = 'views/bloc-editor-html-files/manage-doc-export-templates.html?v=' + version + 'rand=' + randomValue;
        vm.manageDataUploadingSettings = 'views/bloc-editor-html-files/manage-data-uploading-settings.html?v=' + version + 'rand=' + randomValue;
        vm.userRoleAccess = 'views/bloc-editor-html-files/user-role-access.html?v=' + version + 'rand=' + randomValue;
        vm.specialSystemBlocFeatures = 'views/bloc-editor-html-files/special-system-bloc-features.html?v=' + version + 'rand=' + randomValue;
        vm.revisionsAndDuplicates = 'views/bloc-editor-html-files/revisions-and-duplicates.html?v=' + version + 'rand=' + randomValue;
        vm.functionalBehaviourSettings = 'views/bloc-editor-html-files/functional-behaviour-settings.html?v=' + version + 'rand=' + randomValue;
        vm.lookupDataSettings = 'views/bloc-editor-html-files/lookup-data-settings.html?v=' + version + 'rand=' + randomValue;

        // Rewrite of the tinyMCE to a service
        vm.tinyMceService = ScanStationTinyMceService;
        vm.tinymceModel = vm.tinyMceService.model;
        vm.tinymceOptions = vm.tinyMceService.tinymceOptions;
        vm.tinymceOptionsLight = vm.tinyMceService.tinymceOptionsLight;

        vm.colourSchemeArray = {};

        vm.showLoadMoreData = false;
        vm.showMoreSingleDataBox = false;
        vm.filterData = false;
        vm.specificFieldSearch = false;

        // time picker function & settings
        vm.newDate = new Date();
        vm.date = new Date();

        vm.inlineOptions = {
            customClass: getDayClass,
            minDate: new Date(),
            showWeeks: false
        };

        vm.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2020, 5, 22),
            minDate: new Date(),
            startingDay: 1
        };

        vm.format = 'dd/MM/yyyy';
        vm.altInputFormats = ['d!/M!/yyyy'];

        vm.popup1 = {
            opened: []
        };

        vm.parseResultsBoxFieldsArray = {};
        vm.parseBoxResults = {};
        vm.removedEmailAddress = [];

        vm.dataAddSuccess = 0;

        vm.dataToFormStations = [];
        vm.dataToFormSearchData = [];
        vm.dataToFormSearchResults = [];
        vm.jsonDataForDataToFormFields = [];
        vm.ableToSyncToProfiles = true;
        vm.instanceSyncToProfiles = false;

        if (Auth.tokenExists()) {

            await vm.getScanStationData(vm.scanStationObj.id, 'main-query');

            if (typeof ($state.params.stationDataID) != 'undefined') {
                vm.scanStationObj.stationDataID = $state.params.stationDataID;
                vm.scanStationObj.isInEditMode = true;
                vm.scanStationObj.isInViewMode = false;
            } else {
                vm.scanStationObj.isInEditMode = false;
                vm.scanStationObj.isInViewMode = false;
            }

            if (vm.stateName === 'module-edit-form' || vm.scanStationObj.stateName === 'module-form' || vm.scanStationObj.stateName === 'bloc') {
                let refresh = true;
                vm.scanStationObj.jobID = vm.jobID = await Data.getStaticJobID($state.params.uniqueModuleSlug, refresh);
            }

            if ((typeof ($state.params.stationDataID) === 'undefined' && vm.stateName === 'scan-station')
                || vm.stateName === 'module-form' || vm.stateName === 'bloc' || vm.stateName == 'edit-bloc' || vm.stateName == 'scan-station' || vm.stateName === 'scan-station-with-data') {

                // if we have a filtering query in place we have no reason to ever call getData, especially not when the bloc is during the loading period
                // TODO we need an official function to determine when our bloc is in a state of filtering
                if (vm.dataFilteringData == null || (vm.dataFilteringData != null && ((vm.dataFilteringData.filterDataToQuery != null && vm.dataFilteringData.filterDataToQuery.length == 0) || vm.dataFilteringData.filterDataToQuery == null))) {
                    await vm.getData(vm.scanStationObj.id);
                }

                if (vm.scanStationObj.stationDetails.uses_automatic_saving === 1
                    || vm.scanStationObj.stationDetails.single_entry_form === 1
                    || vm.scanStationObj.stationDetails.populate_latest_data === 1
                    || vm.scanStationObj.stationDetails.has_populate_latest_data_specific_fields === 1) {

                    if (vm.scanStationObj.dataToAdd == null) {
                        vm.scanStationObj.dataToAdd = {};
                    }
                    await vm.getLastEntryByEntryCycleKey();
                }
                vm.scanStationEditMode = false;
            } else {
                try {
                    vm.scanStationEditMode = true;
                    let singleEntryExistingData = await scanStation.getSingleDataEntryForStationEdit($state.params.stationDataID,
                        vm.stateParams.publicFormKey, vm.stateParams.publicFormValue);

                    vm.singleEntryExistingData = singleEntryExistingData.data.data;

                    angular.forEach(vm.scanStationObj.scanStationFields, function (value) {
                        vm.scanStationObj.dataToAdd[value.field_slug] = vm.singleEntryExistingData[value.field_slug];
                    });

                    $scope.$apply();

                } catch (e) {
                    console.log(e);
                }
            }

            await vm.getScanStationRules(vm.scanStationObj.id);
            await vm.getStationUsers(vm.scanStationObj.id);
            await vm.getSOPSignedForUser();

            if (vm.scanStationObj.stateName === 'scan-station' || vm.scanStationObj.stateName === 'public-form') {
                document.querySelector('video').addEventListener('play', function () {
                });
            }

            vm.toggleMin();

            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            let afterTomorrow = new Date();
            afterTomorrow.setDate(tomorrow.getDate() + 1);
            vm.events = [
                {
                    date: tomorrow,
                    status: 'full'
                },
                {
                    date: afterTomorrow,
                    status: 'partially'
                }
            ];

            /**
             * TODO: Replace this
             * @deprecated: Hardcoded code here
             */
            if (vm.scanStationObj.jobID == 815) {
                vm.troyUserDataForDropDown = [];
                vm.troyUserData = [];
                vm.troySearchResult = [];
            }

            if (!vm.checkInIFrame()) {
                vm.scanStationObj.jsonFields = $state.params.jsonFields;
            }

            await vm.checkInstanceForProfileSync();
            await vm.getAntiStaticSignedForUser();

            $scope.$on('savingDataBroadcast', function () {
                vm.savingData = Data.savingData;
            });

            $scope.$on('dataForDataFilteringCount', function () {
                if (vm.filterData) {
                    vm.upperLimit = Data.updatedData;
                }
            });

            $('input').on('focusin', (item: any) => {
                vm.currentTarget = item.currentTarget.id;
            });
        }
        
        if (vm.scanStationObj.stationDetails.showPopUpWhenExitingBloc && vm.scanStationObj.stateName != 'edit-bloc') {
            pageState.startEventListenersForPageChange();
        }
    };

    /**
     *  This function trigger Results Box on initiation
     *
     * @param resultsBoxStationAndFields
     */
    vm.triggerResultBoxOnInit = (resultsBoxStationAndFields) => {

        angular.forEach(vm.scanStationObj.scanStationFields, function (value) {

            if (value.id == resultsBoxStationAndFields.localFieldId) {
                vm.getResultsBoxArrayData(value.field_slug, vm.scanStationObj.dataToAdd[value.field_slug]);
            }
        })
    };

    /**
     *  This function will trigger all results boxes in a bloc
     *  It loops through all the fields and checks if its a results box and does a check based on that
     *
     */
    vm.triggerAllResultsBoxes = () => {

        angular.forEach(vm.scanStationObj.scanStationFields, function(field){

            if(field.field_type == 'results-box') {
                angular.forEach(vm.scanStationObj.scanStationFields, function (value) {
                    if (field.resultsBoxStationAndFields != null && (value.id == field.resultsBoxStationAndFields.localFieldId)) {
                        vm.getResultsBoxArrayData(value.field_slug, vm.scanStationObj.dataToAdd[value.field_slug]);
                    }
                })
            }
        });
    };

    /**
     * This will be responsible to clean data from the dropdown.
     */
    vm.cleanDataFromDropdown = () => {
        vm.stationDataForDropDown = [];
    }

    /**
     * TODO: Check why we have this constructor here that we never used!
     * @param canvas
     * @param img
     */

    function drawCanvas(canvas, img) {
        try {
            // canvas.width = getComputedStyle(canvas).width.split('px')[0];
            // canvas.height = getComputedStyle(canvas).height.split('px')[0];
            //
            // let ratio = Math.min(canvas.width / img.width, canvas.height / img.height);
            // let x = (canvas.width - img.width * ratio) / 2;
            // let y = (canvas.height - img.height * ratio) / 2;
            //
            // canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
            // canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height, x, y, img.width * ratio, img.height * ratio);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This method will be responsible for triggering the background data update, that
     * will be checked the submittedStatus argument on the ScanStation or ScanStationObject.
     * @param dataToAdd
     * @param scanStationID
     * @param data
     */
    vm.triggerBackgroundDataUpdate = async function (dataToAdd, scanStationID, data) {
        try {
            if (vm.scanStationObj.isInEditMode != true && data != null) {
                if (vm.scanStationObj.stationDetails.uses_automatic_saving === 1) {
                    vm.submittedStatus = 0;
                    vm.scanStationObj.submittedStatus = 0;
                    await vm.AddData(vm.scanStationObj.dataToAdd, scanStationID);
                }
            }

        } catch (e) {
            console.log(e);
        }
    };

    vm.checkIfUserExists = async function (dataToAdd) {
        try {
            let userEmail = '';
            vm.userExists = false;

            if (vm.stationDetails.is_profile_station === 1 || vm.scanStationObj.stationDetails.is_profile_station === 1) {

                angular.forEach(vm.scanStationObj.scanStationFields, function (stationFields) {
                    if ((stationFields.field_type === 'cartolytics-add-user-email')) {
                        angular.forEach(dataToAdd, function (data, dataIndex) {
                            if (dataIndex === stationFields.field_slug && stationFields.field_type === 'cartolytics-add-user-email') {
                                userEmail = data;
                            }
                        })
                    }
                });

                let response = await User.checkIfUserExists(userEmail);
                if (response.data.result) {
                    alert("The email is already registered");
                    vm.userExists = true;
                    return true;
                }

            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.buildRuleDataToCheck = async () => {
        angular.forEach(vm.scanStationObj.scanStationFields, async function (field, index) {
            if (field.status == 1) {

                // remove fields that are not supposed to be checked due to the conditional visibility

                let isVisibleFromShowCondition = GridFactory.scanStationFormValidate(vm, field);

                if (isVisibleFromShowCondition) {

                    let data = (field.field_type === 'file' && vm.scanStationObj.filesToAdd[field.field_slug] !== undefined)
                        ? vm.scanStationObj.filesToAdd[field.field_slug]['name']
                        : vm.scanStationObj.dataToAdd[field.field_slug];

                    let aditionalData = await vm.buildAdditionalDataForRules(field.id, field.field_slug);
                    if (aditionalData) {
                        data = aditionalData;
                    }


                    vm.ruleDataToCheck.push({
                        field_id: field.id,
                        data: data,
                        index: index
                    });
                }
            }
        });
    }

    vm.buildAdditionalDataForRules = async (field_id, fieldSlug) => {

        let data = '';

        // loading scan station data
        if (vm.fieldRules === undefined) {
            let id = vm.scanStationObj.id;
            let scanStationRules = await scanStation.getScanStationRules(field_id);
            vm.fieldRules = scanStationRules.data.fieldRules;
        }

        return data === '' ? false : data;

    }


    vm.triggerRulesAndWaitFor1Second = async function (field) {
        return new Promise(resolve => {
            vm.getRules('after-submission', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
            setTimeout(() => {
                resolve('1 seconds');
            }, 100);
        });
    }

    /**
     * This will be responsible to control the action of save data into a scan station.
     * @param data
     */
    vm.saveButtonAction = async (data) => {
        try {
            vm.filterData == true ? vm.filterDataOptions = data.dataFilteringData : '';

            // this is the scan station data
            let scanStationData = {
                scanStationID: vm.scanStationObj.id,
                dataToAdd: vm.scanStationObj.dataToAdd,
                jobID: vm.scanStationObj.jobID
            };

            // this will validate the form
            await vm.form.validate(scanStationData);

            // this will run only if the form is validated!
            await vm.checkAllRulesThenAddData(vm.scanStationObj.dataToAdd, vm.scanStationObj.id);

        } catch (exception) {

            if (exception.data.errors != undefined) {
                exception.data.errors.forEach(function (error, index) {
                    MessageService.addErrorMessage(error);
                });
            }

            $scope.$apply();
        }

    }

    vm.checkAllRulesThenAddData = async function (dataToAdd, scanStationID) {
        try {
            vm.ruleDataToCheck = [];
            await vm.buildRuleDataToCheck();

            if (vm.scanStationObj.isInEditMode != true) {
                vm.scanStationObj.stationDataID = null;
            }

            let allFieldsData = vm.getAllFieldsData();

            await scanStation.getMultipleRules(vm.ruleDataToCheck, vm.scanStationObj.stationDataID, allFieldsData);

            if (vm.thisStationIsProfileStation === true && !vm.scanStationObj.isInEditMode) {
                let syncWithProfileUsernameCheck: boolean;
                let data = await User.checkIfUserExists(vm.scanStationObj.dataToAdd[vm.primaryProfileSyncField]);
                syncWithProfileUsernameCheck = data.data.result;
                if (syncWithProfileUsernameCheck === true) {
                    vm.savingData = false;
                    vm.prompt = 'Username is already used, please use another';
                    await vm.playSound('error');
                    return;
                } else {
                    await vm.AddData(dataToAdd, scanStationID);
                }

            } else {
                await vm.AddData(dataToAdd, scanStationID);
            }

            vm.ruleCheckingInProgress = false;

            status.successfulScan();

            $rootScope.$broadcast('cleanMindeeFiles', null);

            vm.scanStationObj.showForm = vm.scanStationObj.stationDetails.hide_form ? false : true

        } catch (res) {

            vm.scrollTop();
            console.log(res)
            let metaData = res.data.metaData;
            let responseData = res.data.responseData;
            let failedResponseType = res.data.failedResponseType;


            if (metaData !== undefined && metaData.populateOtherFieldDataFromRuleFailure != 'undefined') {
                angular.forEach(metaData.populateOtherFieldDataFromRuleFailure, function (value, index) {
                    if (typeof vm.scanStationFieldIdsToField[index] != 'undefined') {
                        vm.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                        vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                    }
                });
            }

            if (metaData !== undefined && metaData.populateOtherFieldDataFromRulePassing != 'undefined') {
                angular.forEach(metaData.populateOtherFieldDataFromRulePassing, function (value, index) {
                    if (typeof vm.scanStationFieldIdsToField[index] != 'undefined') {
                        vm.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                        vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                    }
                });
            }

            if (responseData !== undefined && responseData.populate_fields != 'undefined') {
                angular.forEach(responseData.populate_fields, function (value, index) {
                    vm.dataToAdd[index] = value;
                    vm.scanStationObj.dataToAdd[index] = value;
                });
            }

            if (failedResponseType !== null) {
                if (failedResponseType == 'Red Warning Reject') {
                    vm.prompt = res.data.error;
                    let failedFieldId = res.data.failedFieldRule.field_id;
                    let fieldIndex = vm.scanStationFieldIdsToField[failedFieldId].fieldIndex;
                    await vm.triggerPrompt(fieldIndex);
                } else {
                    if (failedResponseType == 'Yellow Warning Accept') {
                        vm.yellowPrompt = res.data.error;
                        let failedFieldId = res.data.failedFieldRule.field_id;
                        let fieldIndex = vm.scanStationFieldIdsToField[failedFieldId].fieldIndex;
                        await vm.triggerYellowPrompt(fieldIndex, true);
                    }
                }
            }

        } finally {
            $scope.$apply();
        }
    };

    /**
     * This will be looping though the scan station fields, it will check if the
     * soundFieldToCheck exist and contain value = 1 (meaning it is turned it on), otherwise
     * it will be returning false.
     *
     * @param soundFieldToCheck
     */
    vm.shouldPlaySound = (soundFieldToCheck) => {

        let play = false;

        angular.forEach(vm.scanStationObj.scanStationFields, function (field, key) {
            if (field[soundFieldToCheck] !== undefined && field[soundFieldToCheck] === 1) {
                play = true
            }
        });
        return play;
    }

    vm.setTransitionScreenData = function () {
        try {
            if ((typeof (vm.scanStationObj.stationDataID) === 'undefined'
                    && vm.stateName === 'scan-station') || vm.stateName === 'module-form' || vm.stateName == 'bloc') {
                vm.savingDataMsg = 'Submitting Data';
            } else {
                vm.savingDataMsg = 'Updating Data';
            }
            if (vm.scanStationObj.stationDetails.popup_on_data_submission === 1) {
                // FIXME: This sounds wrong
                vm.savingDataMsg = vm.savingDataMsg;
            }


        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This will be responsible to process an action of adding data, and this will
     * be prevented on AddData.
     *
     * @param dataToAdd
     * @param scanStationID
     */
    vm.processData = async (dataToAdd, scanStationID) => {

        for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
            let field = vm.scanStationObj.scanStationFields[i];
            // this has a similar name to triggerRulesBeforeSubmissionOfForm, but this is correctly happening before the
            // form submits, the other is happening after, there is a change that needs to be done for the one later on to rename it

            if (field.triggerRulesBeforeProcessSubmitData == 1) {
                await vm.getRules('after-submission', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
                $scope.$apply();
            }
        }

        let metaData;
        vm.savingData = true;
        vm.setTransitionScreenData();
        vm.returnedStationID = false;
        metaData = null;

        if (vm.scanStationObj.isInEditMode) {
            metaData = {};
            metaData.stationDataID = vm.scanStationObj.stationDataID;
        }

        // when editing data inside the results

        if (vm.inDataEditRowId != null) {
            metaData = {};
            metaData.stationDataID = vm.inDataEditRowId;
        }


        angular.forEach(vm.fieldsThatDontSaveData, function (fieldSlug, index) {
            delete dataToAdd[fieldSlug];
        });

        // process Cross Bloc Data Fields on Submit
        if (vm.crossBlocDataUpdateFieldsOnDataSubmit.length > 0) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let crossBlocDataUpdateFieldsOnDataSubmitToKeep = [];
            angular.forEach(vm.crossBlocDataUpdateFieldsOnDataSubmit, function (fieldId, index) {
                let isVisibleFromShowCondition = GridFactory.scanStationFormValidate(vm, vm.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    crossBlocDataUpdateFieldsOnDataSubmitToKeep.push(fieldId);
                }
            });

            let actionResponses = await CrossBlocDataUpdatesFieldFactory.triggerActions(crossBlocDataUpdateFieldsOnDataSubmitToKeep, dataToAdd);
            angular.forEach(crossBlocDataUpdateFieldsOnDataSubmitToKeep, function (fieldId) {
                dataToAdd[vm.scanStationFieldIdsToField[fieldId].field_slug] = actionResponses.data.data['fieldId_' + fieldId];
            });
        }

        // Process Mail Alert Fields on Submit

        if (vm.mailAlertFieldsOnSubmit.length > 0 && !vm.scanStationObj.isInEditMode) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let mailAlertFieldsOnSubmitToKeep = [];

            angular.forEach(vm.mailAlertFieldsOnSubmit, function (fieldId, index) {
                let isVisibleFromShowCondition = GridFactory.scanStationFormValidate(vm, vm.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    mailAlertFieldsOnSubmitToKeep.push(fieldId);
                }
            });

            if (mailAlertFieldsOnSubmitToKeep.length > 0) {
                let actionResponses = await MailAlertFactory.triggerActions(vm.scanStationObj.id, mailAlertFieldsOnSubmitToKeep, dataToAdd);
            }

        }

        // Process Blocworx Gateway Fields on Submit

        if (vm.blocworxGatewayFieldsOnSubmit.length > 0 && !vm.scanStationObj.isInEditMode) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let blocworxGatewayFieldsOnSubmitToKeep = [];

            angular.forEach(vm.blocworxGatewayFieldsOnSubmit, function (fieldId, index) {
                let isVisibleFromShowCondition = GridFactory.scanStationFormValidate(vm, vm.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    blocworxGatewayFieldsOnSubmitToKeep.push(fieldId);
                }
            });

            let blocworxGatewayFieldsOnSubmitToKeepLength = blocworxGatewayFieldsOnSubmitToKeep.length;
            
            for(let i=0;i<blocworxGatewayFieldsOnSubmitToKeepLength;i++) {
                let response = await BlocworxGatewayFactory.triggerActions (blocworxGatewayFieldsOnSubmitToKeep[i], vm.scanStationObj.dataToAdd, vm.scanStationObj.filesToAdd);
                $scope.$broadcast('triggerPostBlocworxGatewayCall', {fieldId :blocworxGatewayFieldsOnSubmitToKeep[i], response : response});
                // we need to force a delay to allow the rules to process
                // TODO find a better way to call component controllers from this controller but also have async/await, $broadcast doesnt have this.
                await scanStation.delay(1000);
                $scope.$apply();
            }
                   
                    
        }

        // Process Mail Alerts Fields When Editing
        if (vm.mailAlertFieldsWhenEditing.length > 0 && vm.scanStationObj.isInEditMode) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let mailAlertFieldsWhenEditingToKeep = [];

            angular.forEach(vm.mailAlertFieldsWhenEditing, function (fieldId, index) {
                let isVisibleFromShowCondition = GridFactory.scanStationFormValidate(vm, vm.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    mailAlertFieldsWhenEditingToKeep.push(fieldId);
                }
            });

            if (mailAlertFieldsWhenEditingToKeep.length > 0) {
                let actionResponses = await MailAlertFactory.triggerActions(vm.scanStationObj.id, mailAlertFieldsWhenEditingToKeep, dataToAdd);
            }

        }

        // Process Blocworx Gateway Fields When Editing
        if (vm.blocworxGatewayFieldsWhenEditing.length > 0 && vm.scanStationObj.isInEditMode) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let blocworxGatewayFieldsWhenEditingToKeep = [];

            angular.forEach(vm.blocworxGatewayFieldsWhenEditing, function (fieldId, index) {
                let isVisibleFromShowCondition = GridFactory.scanStationFormValidate(vm, vm.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    blocworxGatewayFieldsWhenEditingToKeep.push(fieldId);
                }
            });
            
           let blocworxGatewayFieldsWhenEditingToKeepLength = blocworxGatewayFieldsWhenEditingToKeep.length;

           for(let i=0;i<blocworxGatewayFieldsWhenEditingToKeepLength;i++) {
                let response = await BlocworxGatewayFactory.triggerActions (blocworxGatewayFieldsWhenEditingToKeep[i], vm.scanStationObj.dataToAdd, vm.scanStationObj.filesToAdd);
                $scope.$broadcast('triggerPostBlocworxGatewayCall', {fieldId :blocworxGatewayFieldsWhenEditingToKeep[i], response : response});
                // TODO find a better way to call component controllers from this controller but also have async/await, $broadcast doesnt have this.
                await scanStation.delay(1000);
                $scope.$apply();
           }

        }

        let data = await scanStation.AddData(dataToAdd, scanStationID, 1, vm.scanStationObj.jobID, vm.scanStationObj.filesToAdd, vm.scanStationObj.submittedStatus, metaData);

        $rootScope.$broadcast('addedScanStationData', {
            scanStationID: scanStationID,
            scanStationDataID: data.data.returnedStationID,
            dataToAdd: dataToAdd,
            jobID: vm.scanStationObj.jobID
        });

        vm.savingData = false;

        if (vm.scanStationObj.submittedStatus === 1) {
            await vm.setDataAddSuccess(1);
        }


        if (vm.inDataEditRowId == null) {
            vm.returnedStationID = data.data.returnedStationID;
            if (vm.filterData) {
                await vm.getDataForDataFiltering('with-filter', vm.filterDataOptions);
            } else {
                await vm.getData(scanStationID);
            }
        }


        if (vm.scanStationObj.submittedStatus === 1) {
            vm.confirmSubmit = false;
            vm.addNew = false;
            // no need to reset data for single entry forms or if we are editing data based on a single entry ID
            await vm.setDataAddSuccess(1);
            if (vm.scanStationObj.stationDetails.single_entry_form === 0) {
                vm.scanStationObj.stationDataID = undefined;
                await vm.resetData(1);
            }

            // Fix for the revert edit cant be possible
            if (vm.scanStationObj.showUndo !== undefined) {
                vm.showUndo = vm.scanStationObj.showUndo;
            } else {
                vm.showUndo = true;
            }

            if (vm.scanStationObj.isInEditMode != true) {
                $('.image-preview').each(function () {
                    $(this).attr('src', '');
                });
            }

            if (vm.inDataEditRowId == null) {
                $(vm.fieldToFocus).trigger('focus');
            }

            vm.inDataEditRowId = null;

            $('html, body').animate({
                //   scrollTop: "0px"
            }, 0);
            if (vm.scanStationObj.stationDetails.popup_on_data_submission === 1) {
                if (vm.scanStationObj.stationDetails.popup_on_data_submission_message == null || vm.scanStationObj.stationDetails.popup_on_data_submission_message == '') {
                    alert('Form Successfully Submitted');
                } else {
                    alert(vm.scanStationObj.stationDetails.popup_on_data_submission_message);
                }
            }

            await vm.hideDataAddSuccessMsg();

            // TODO Resolve misunderstanding of the name of this parameter
            // it is saying triggerRulesBeforeSubmissionOfForm, but its really happening after not before.
            // this is important because we also now have one that really happens before
            for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
                let field = vm.scanStationObj.scanStationFields[i];
                if (field.triggerRulesBeforeSubmissionOfForm == 1) {
                    await vm.triggerRulesAndWaitFor1Second(field);
                    $scope.$apply();
                }
            }

            $scope.$apply();
        }

        if (vm.scanStationObj.isInEditMode === true) {
            vm.scanStationObj.isInEditMode = false;
            await vm.resetData(1);
        }

        if (vm.scanStationObj.stationDetails.closeFormAfterSubmit == 1) {
            vm.scanStationObj.showForm = false;
        }

        // if we're in a sub bloc then process expected behaviour
        if (vm.inSubBlocOwnerFieldDetails != null) {
            await SubBlocFieldFactory.processAfterSubmitDataBehaviour(vm.inSubBlocOwnerFieldDetails);
        }

        if (vm.showFormceptionPopup && vm.scanStationObj.stationDetails.formception_close_after_submit) {
            await vm.returnScanStation();
        }

        $scope.$apply();
    }

    vm.triggerProcessAfterSubmitDataBehaviourFunction = async function () {
        await SubBlocFieldFactory.processManualCloseBehaviour(vm.inSubBlocOwnerFieldDetails);
        $scope.$apply();
    }


    /**
     * Method that will trigger the action of add a new data,
     * we have implemented a formFactory that will ensure
     * that one only request will be done per time.
     *
     * @param dataToAdd
     * @param scanStationID
     * @constructor
     */
    vm.AddData = async function (dataToAdd, scanStationID) {
        try {
            await FormFactory.send(vm.processData, [dataToAdd, scanStationID]);

        } catch (e) {
            console.log(e);
            vm.savingData = false;
            if (typeof (e.data) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error in system, please contact administrator or developer.';
            }
        }

    };

    vm.disableEnableField = async function (index, status) {
        try {
            vm.scanStationObj.scanStationFields[index].status = status;
            await vm.updateScanStation('disable-enable-field');
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Main method that will be updating data for a scan station, this is being used
     * wide in the system, as a main function to make sure that scan station have
     * all the requirements before change anything.
     *
     * @param queryType
     */
    vm.updateScanStation = async function (queryType) {
        try {
            await scanStation.updateScanningStation(
                vm.scanStationObj.jobID,
                vm.scanStationObj.scanStationFields,
                vm.fieldsToDelete,
                vm.scanStationObj.id,
                vm.fieldRulesToAdd,
                vm.fieldRulesToDelete,
                vm.selectedSOPFiles,
                vm.stationUnits,
                vm.editNewRuleCase
            );

            vm.fieldRulesToAdd = [];
            vm.fieldRulesToDelete = [];
            vm.fieldsToDelete = [];

            if (queryType != 'prevent-field-rebuild') {
                await vm.getScanStationData(vm.scanStationObj.id, queryType);
            }

            await vm.getScanStationRules(vm.scanStationObj.id);

            $scope.$apply();


        } catch (e) {
            alert('An error has occurred, please check browser console to read error.');
            // TODO: find a way to reload the page when this happens: location.reload();
            console.log(e);
            vm.error = e.data !== null ? e.data.error : e;
        }
    };

    /**
     * This will be responsible to check if exist a first key ['0'], if so
     * it will be cleaning the field localFieldValue.
     */
    vm.cleanLocalFieldMatchingFieldPairs = (localFieldMatchingFieldPairs) => {
        if (Object.keys(localFieldMatchingFieldPairs).length >= 1) {
            if (localFieldMatchingFieldPairs[0].localFieldValue !== undefined) {
                localFieldMatchingFieldPairs[0].localFieldValue = ''
            }
        }
    }

    vm.resetData = async function (keepEntryCycleKey) {

        vm.fieldsToRetriggerRules = {};

        // We are looping though the scan station object that contain fields, and doing some data clean
        angular.forEach(vm.scanStationObj.scanStationFields, async function (scanStationFieldValue, scanStationFieldIndex) {

            vm.scanStationObj.restarted = true;
            vm.preventRuleCall[scanStationFieldValue.field_slug] = true;
            vm.filesToAdd = {};
            vm.scanStationObj.filesToAdd = {};

            // by default will reset things, but a bunch of criteria we can avoid to reset
            let canReset = true;
            let inArray = vm.fieldIDsThatKeepData !== undefined ? vm.fieldIDsThatKeepData.indexOf(scanStationFieldValue.id) : -1;
            let fieldShowCondition = scanStationFieldValue.field_show_condition;

            if (inArray != -1 && fieldShowCondition == null) {
                canReset = false;
                vm.fieldToFocus = '#station-field-' + (scanStationFieldIndex + 1);
                vm.hasKeptData = true;
                vm.fieldsToRetriggerRules[scanStationFieldValue.field_slug] = {
                    'data': vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug],
                    'domIndex': vm.domIndexes[scanStationFieldValue.field_slug],
                    'field_id': scanStationFieldValue.id,
                    'special_field_key': scanStationFieldValue.special_field_key
                };
            }

            // TODO: this can refactored to a function
            if (fieldShowCondition != null) {

                // this show that if you have keepDataOnSubmit can reset became false
                if (typeof (fieldShowCondition.keepDataOnSubmit) != 'undefined' && fieldShowCondition.keepDataOnSubmit === 1) {
                    canReset = false;
                }
                if (typeof (fieldShowCondition.keepDataOnSubmit) != 'undefined' && fieldShowCondition.keepDataOnSubmit == 0) {
                    canReset = true;
                }
            }

            if (scanStationFieldValue.is_entry_cycle_field == 1 && keepEntryCycleKey == 1) {
                canReset = false;
            }

            if (scanStationFieldValue.field_type == 'use-select') {
                vm.dataToAdd[scanStationFieldValue.field_slug] = [];
                vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = [];
                canReset = false;
            }

            if (vm.scanStationObj.isInEditMode) {
                canReset = false;
            }

            if (scanStationFieldValue.field_type == 'logged-in-user-info') {
                canReset = false;
            }
            // TODO: this can refactored to a function

            if (canReset == false) {
                await vm.getResultsBoxArrayData(scanStationFieldValue.field_slug, vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug]);
            }

            if (canReset == true) {

                // As we are on the "can reset" case, we can assume to delete data from the multi button
                if (scanStationFieldValue.field_type == 'multi-button') {

                    // Cleaning all buttons with class .selected
                    $('.form-scan-station-view button.selected').removeClass('selected');

                    // making sure that the dataToAdd is also clean for this field_slug
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = ''
                }

                angular.forEach(vm.dropdownFilteredDataFields, function (dropdownFilteredDataFieldValue) {
                    if (dropdownFilteredDataFieldValue[2] == scanStationFieldValue.field_slug) {
                        vm.stationDataForDropDown[dropdownFilteredDataFieldValue[0]] = {};
                    }
                });

                if (scanStationFieldValue.field_type == 'results-box') {
                    vm.parseBoxResults[scanStationFieldValue.field_slug] = {};
                }

                if (scanStationFieldValue.field_type == 'checkbox-dynamic-checked-value') {
                    vm.checkboxesWithDynamicDataFields[scanStationFieldValue.field_slug] = 'blocworx-empty';
                }

                if (scanStationFieldValue.field_type == 'digital-signature') {
                    if (typeof (vm.digitalSignatureButtons) != 'undefined') {
                        vm.digitalSignatureButtons[scanStationFieldValue.field_slug] = true;
                    } else {
                        vm.digitalSignatureButtons = [];
                        vm.digitalSignatureButtons[scanStationFieldValue.field_slug] = true;
                    }
                }

                if (scanStationFieldValue.field_type == 'checkbox' || scanStationFieldValue.field_type == 'button') {
                    vm.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.pressedUnpressedValues.unPressed;
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.pressedUnpressedValues.unPressed;
                } else if (scanStationFieldValue.field_type == 'plain-data') {
                    vm.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.plainDataFieldValue;
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = null;
                } else if (scanStationFieldValue.field_type == 'multi-button') {
                    if (scanStationFieldValue.defaultButtonValue != null) {
                        vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.defaultButtonValue;
                    }
                } else if (scanStationFieldValue.field_type.includes('auto-generate')) {
                    // TODO: Adrian now we have object destructing, so we can load like this:
                    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
                    await vm.getAutoGenerateFieldData(scanStationFieldValue, scanStationFieldValue.field_type, scanStationFieldValue.field_slug)

                } else if (scanStationFieldValue.field_type == 'date-time-selector'
                    && (
                        scanStationFieldValue.conditionallyShow == 'show'
                        || scanStationFieldValue.conditionalShowParameters.includes(vm.scanStationObj.dataToAdd[scanStationFieldValue.conditionalShowFieldSlug])
                        && scanStationFieldValue.status == 1
                    )
                ) {
                    if (scanStationFieldValue.dateTimeSetting == 'time') {
                        vm.newDate = new Date();
                        vm.scanStationTimePickerFields[scanStationFieldValue.field_slug] = vm.newDate;
                        vm.setSeconds(scanStationFieldValue.field_slug);
                        vm.updateTime(scanStationFieldValue.field_slug);
                    }

                    if (scanStationFieldValue.field_data == 'date') {
                        await vm.today(scanStationFieldValue.field_slug);
                        vm.showDatePickerField[scanStationFieldValue.field_slug] = true;
                        vm.showDateInput[scanStationFieldValue.field_slug] = false;
                    }
                } else if (scanStationFieldValue.field_type == 'maths') {
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = 'blocworx-unset-maths';
                    vm.dataToAdd[scanStationFieldValue.field_slug] = 'blocworx-unset-maths';
                } else if (scanStationFieldValue.field_type == 'checkbox-buttons-from-station') {

                    // This is cleaning the case of checkboxFromStation
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = {};
                    vm.stationDataForDropDown[scanStationFieldValue.field_slug] = [];

                } else {

                    // Main part that cleans basic fields
                    if (scanStationFieldValue.status == 1) {
                        vm.dataToAdd[scanStationFieldValue.field_slug] = '';
                        vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = '';
                    } else {
                        vm.dataToAdd[scanStationFieldValue.field_slug] = 'N/A';
                        vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = 'N/A';
                    }

                    if (vm.scanStationFieldTypes[scanStationFieldValue.field_type] == 'file') {
                        vm.filesToAdd[scanStationFieldValue.field_slug] = {};
                        vm.scanStationObj.filesToAdd[scanStationFieldValue.field_slug] = {};
                        $('.file-upload-container.' + scanStationFieldValue.field_slug + ' .photo-preview')
                            .css('position', 'fixed');
                    }


                    if (scanStationFieldValue.defaultFieldValue != null) {
                        vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.defaultFieldValue;
                    }
                }
            }

        });

        // override any existing field to focus values if we have set a parameter
        if (typeof vm.priorityFocus != 'undefined') {
            vm.fieldToFocus = vm.priorityFocus;
        }

        if (vm.hasKeptData != true) {
            vm.fieldToFocus = '#station-field-0';
        }

        angular.forEach(vm.scanStationObj.scanStationFields, function (scanStationFieldValue) {
            vm.preventRuleCall[scanStationFieldValue.field_slug] = false;
        });

        // TODO: check if is necessary to wait for the async function
        await angular.forEach(vm.fieldsToRetriggerRules, async function (field, fieldSlug) {
            vm.preventRuleCall[fieldSlug] = false;
            await vm.getRules('no-event', field.field_id, field.special_field_key, field.data, field.domIndex, fieldSlug);
        });

    }

    /**
     * This function isnt being used, so you must figure it out how to fix using this
     * function, how to update the data from the digest when scan station rule
     * data is changed.
     *
     * @param fieldRules
     * @param fieldId
     * @param updatedParams
     */
    vm.updateScanStationRuleData = function (fieldRules, fieldId, updatedParams) {
        try {
            let array = []
            angular.forEach(fieldRules, function (value) {
                // we only need to update if the value is equal
                if (fieldId === value.field_id) {
                    value.rules_parameters = updatedParams;
                }
                array.push(value);
            });

            return array;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This method will get the scan station rules
     * by informing the rule id.
     * @param id id of the rule.
     */
    vm.getScanStationRules = async function (id) {
        try {
            let data = await scanStation.getScanStationRules(id);
            vm.fieldRules = data.data.fieldRules;
            // we need to find the field IDs that keep the data for next scan
            vm.fieldIDsThatKeepData = [];
            vm.autoGeneratedFieldSlugs = [];
            vm.fieldWithMustBeEqualToAnotherLocalField = [];

            angular.forEach(vm.fieldRules, function (value) {

                // TODO: check this one how we can do this, (talk with ADRIAN)
                if (value.rule_condition_id == 'keep_data_for_next_scan') {
                    vm.fieldIDsThatKeepData.push(value.field_id);
                }

                if (value.rule_condition_id == 'must_be_equal_to_another_local_field') {
                    vm.fieldWithMustBeEqualToAnotherLocalField.push([
                        value.field_id
                        , value.rules_parameters.param1
                        , value.json_response_data.failureResponseType
                        , value.custom_response
                        , value.field_name
                    ]);
                }
            });


        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    };

    vm.getAutoGeneratedData = async function () {
        try {
            // checking if we have value on the array vm.autoGeneratedFieldSlugs
            if (vm.autoGeneratedFieldSlugs.length > 0 && vm.id !== 'undefined') {

                let data = await scanStation.getAutoGeneratedData(vm.id, vm.autoGeneratedFieldSlugs);
                vm.autoGeneratedData = data.data.data;

                angular.forEach(vm.autoGeneratedData, function (value) {
                    vm.dataToAdd[value.slug] = value.value;
                    // vm.scanStationObj.dataToAdd[value.slug] = value.value;
                });
                $scope.$apply();
            }

        } catch (e) {
            console.log(e);
        }
    };

    vm.editFieldRules = async function (field, fieldOrderData, scrollTo = null) {
        try {

            vm.fieldIDForUpdatingRules = field.id;
            vm.fieldSlugforUpdatingRules = field.field_slug;
            vm.initAndGateParams(vm.fieldSlugforUpdatingRules);
            vm.fieldShowConditionForUpdatingRules = field.field_show_condition;
            vm.parentFieldIDForUpdatingRules = field.parent_field_id;
            vm.fieldShowCounter = field.show_counter;
            vm.fieldNextLine = field.field_next_line;
            vm.isEntryCycleField = field.is_entry_cycle_field;
            vm.fieldWidth = field.field_width;
            vm.fieldOrderData = fieldOrderData[0] + ',' + fieldOrderData[1];
            vm.currentSelectedFieldIndex = fieldOrderData[0];
            vm.editFieldRuleName = field.field_name;
            await vm.getRuleData();
            vm.fieldnameForRules = field.field_name;
            vm.showFieldRulesFromSideClick = true;
            vm.fieldHideLabel = field.hide_label;
            vm.fieldAutoComplete = field.autocomplete;
            vm.isStationMetaDataField = field.is_station_meta_data_field;
            vm.hideFromGetDataList = field.hide_from_get_data_list;
            vm.resultCharacterCount = field.result_character_count;

            vm.createFieldVisibilityParameters();

            await vm.getFieldVisibilityOptions(vm.fieldIDForUpdatingRules);
            await vm.getStationStationFieldsForRule(vm.scanStationObj.id);

            if (vm.fieldShowConditionForUpdatingRules != null) {
                if (vm.fieldShowCondition == null) {
                    vm.fieldShowCondition = {};
                }
                vm.fieldShowCondition.field_slug = vm.fieldShowConditionForUpdatingRules.field_slug;
                vm.fieldShowCondition.parameters = vm.fieldShowConditionForUpdatingRules.parameters;
                if (vm.fieldShowConditionForUpdatingRules.keepDataOnSubmit != null) {
                    if (vm.keepDataOnSubmit == null) {
                        vm.keepDataOnSubmit = {};
                    }
                    vm.keepDataOnSubmit.parameters = vm.fieldShowConditionForUpdatingRules.keepDataOnSubmit;
                }
            }

            await vm.getSelectedRolesForFieldID(vm.fieldIDForUpdatingRules);
            vm.fieldHeight = (field.field_height === "auto") ? field.field_height : parseInt(field.field_height);

            // get latest field Parameters
            let latestFieldParameters = await scanStation.getFieldParameters(vm.fieldIDForUpdatingRules);
            field.parameters = latestFieldParameters.data.data;
            console.log(field.parameters);
            if (field.parameters.length > 0) {
                angular.forEach(field.parameters, function (fieldParam) {

                    // TODO: make sure that each value contain the correct type later on in a function
                    // This checks if we have an integer, and we transform to int, so a select option can be selected.
                    let parameterValue = /^[0-9]+$/.test(fieldParam.parameter_value) ? parseInt(fieldParam.parameter_value, 10) : fieldParam.parameter_value;
                    vm.currentSelectedFieldParameters[fieldParam.scan_station_field_parameter] = parameterValue;

                    // TODO come up with better solution for this, this is workaround for V3 deadline
                    if (fieldParam.scan_station_field_parameter == 'multiFieldShowConditions') {
                        vm.multiFieldShowConditionsNew = JSON.parse(angular.copy(fieldParam.parameter_value));
                        vm[fieldParam.scan_station_field_parameter] = [];
                        vm[fieldParam.scan_station_field_parameter][vm.fieldIDForUpdatingRules] = vm.multiFieldShowConditionsNew;
                        delete vm.multiFieldShowConditionsNew;
                    }
                });
            }


            $scope.$apply();

            if (scrollTo != null) {
                vm.scrollTo(scrollTo, '.field-rules', true);
            }

        } catch (e) {
            console.log(e);
        }
    };

    vm.createAGFields = function () {
        vm.fieldDataToUpdateTo = {};
    };

    vm.editFieldDetails = async function (fieldID, fieldName, fieldSlug, fieldType, fieldData, fieldParameters) {
        try {

            vm.showFieldUpdatePopUp = true;

            if (typeof vm.newField == 'undefined') {
                vm.newField = {};
            }
            vm.fieldIDForUpdatingRules = fieldID;
            vm.fieldSlugforUpdatingRules = fieldSlug;
            vm.editFieldRuleName = fieldName;
            vm.newField.field_type = fieldType;
            vm.newField.field_name = fieldName;
            vm.newField.field_data = fieldData;
            vm.originalFieldData = fieldData;
            vm.tempHideFieldEditor = true;


        } catch (e) {
            console.log(e);
        }
    };

    vm.updateField = async function (initialFieldParams = null) {
        try {
            vm.updating = true;
            let canUpdate = true;

            angular.forEach(vm.scanStationObj.scanStationFields, function (value) {
                if (value.id != null && value.field_name === vm.newField.field_name && value.id !== vm.fieldIDForUpdatingRules) {
                    canUpdate = false;
                }
            });

            if (canUpdate === true) {

                vm.newField.initialFieldParams = initialFieldParams;

                await scanStation.updateFieldDetails(vm.scanStationObj.id, vm.fieldIDForUpdatingRules,
                    vm.newField.field_name, vm.newField.field_type, vm.newField.field_data, vm.newField.initialFieldParams);

                await vm.getScanStationData(vm.scanStationObj.id, 'main-query');
                vm.updating = false;
                vm.showFieldUpdatePopUp = false;
                vm.newField = {};
                vm.newField.field_type = 'text';
                vm.modalOff();

                $rootScope.$broadcast('reloadSpecificGrids', [vm.modifyingGridId]);

                $scope.$apply();

            } else {
                vm.updating = false;
                alert('Please choose a field name that is already not in this station');
            }

        } catch (e) {
            console.log(e);
            vm.updating = false;
            alert('An error occurred, please check the console in the' + ' browser');
        }

    };

    vm.getRuleData = async function () {
        try {
            vm.ruleResponseErrors = null;
            let data = await scanStation.getRuleData();
            vm.ruleData = data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    vm.getRuleDetails = function () {
        try {
            let selectedRuleKey = vm.newRuleID;
            if (selectedRuleKey) {
                vm.ruleInstructions = vm.ruleData[selectedRuleKey].rule_instructions;
                vm.ruleDataEntryMedium = vm.ruleData[selectedRuleKey].data_entry_medium;
                vm.selectedRuleID = vm.ruleData[selectedRuleKey].id;
            }

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This function is responsible to send a new rule.
     */
    vm.submitNewRule = async function () {
        try {
            vm.ruleResponseErrors = [];
            let selectedRuleKey = vm.newRuleID;
            let ruleValidationResponse = await fieldRuleValidationFactory.validateRule(vm.ruleData[selectedRuleKey], vm.newRule.rules_parameters);
            if (ruleValidationResponse.length > 0) {
                vm.ruleResponseErrors = ruleValidationResponse;
                return false;
            }

            if (typeof (vm.fieldIDForUpdatingRules) == 'undefined') {
                vm.fieldIDToSend = 0;
            } else {
                vm.fieldIDToSend = vm.fieldIDForUpdatingRules;
            }

            // for sending to the backend
            vm.fieldRulesToAdd = vm.fieldRulesToAdd.concat([{
                rule_parameters_to_add: vm.newRule.rules_parameters,
                rule_name: vm.ruleData[selectedRuleKey].rule_name,
                field_id: vm.fieldIDToSend,
                response_data: vm.newRule.responseData,
                custom_response: vm.newRule.customResponse,
                scan_station_id: vm.id,
                rule_condition_id: selectedRuleKey,
                'field_name_for_rules': vm.fieldnameForRules
            }]);

            // reset new rule
            vm.newRule.rules_parameters = {};

            // TODO: Adrian: check how to fix this
            vm.newRule.responseData = {};
            // TODO: Adrian: check how to fix this

            vm.newRule.customResponse = null;
            await vm.updateScanStation('prevent-field-rebuild');
            await vm.triggerBlocUpdateSuccess('Rule Added');

            vm.scrollTo('add-rules', '.field-rules', true);

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    };

    vm.editNewRule = async function (fieldId) {
        try {
            let selectedRuleKey = vm.newRuleID;
            let fieldRuleId = vm.fieldRuleId === undefined ? fieldId : vm.fieldRuleId;

            if (typeof (vm.fieldIDForUpdatingRules) == 'undefined') {
                vm.fieldIDToSend = 0;
            } else {
                vm.fieldIDToSend = vm.fieldIDForUpdatingRules;
            }

            // for sending to the backend
            let fieldRuleToAdd = new FieldRulesToAdd().build(
                vm.newRule,
                vm.ruleData,
                vm.fieldIDToSend,
                vm.id,
                selectedRuleKey,
                vm.fieldnameForRules,
                fieldRuleId
            );

            vm.fieldRulesToAdd = vm.fieldRulesToAdd.concat([fieldRuleToAdd]);

            await vm.updateScanStation('prevent-field-rebuild');

            // await vm.updateScanStationRuleData(vm.fieldRules, fieldRuleToAdd.field_id, fieldRuleToAdd.rule_parameters_to_add);

            // We could add the thing here to change
            vm.fieldRules = {};
            await vm.getScanStationRules(vm.scanStationObj.id);
            $scope.$apply();


        } catch (e) {
            console.log(e);
        } finally {

            // This will reset after running this
            vm.newRule.rules_parameters = {};
            vm.newRule.responseData = {};
            vm.newRule.customResponse = null;
        }

    };

    vm.updateFieldParameter = async function (fieldID, toUpdate, parameters) {
        try {
            await scanStation.updateFieldParameter(fieldID, toUpdate, parameters);
            await vm.updateScanStation('prevent-field-rebuild');
            $rootScope.$broadcast('reloadSpecificGrids', [vm.modifyingGridId]);
            await vm.triggerBlocUpdateSuccess('Field Parameter Updated');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This is the main point to remove rules from a field,
     * this should delete the selected ruleID and later on
     * organize the order for each rule of this field.
     *
     * @param existingOrNew
     * @param ruleID
     * @param index
     */
    vm.addtoRulesToDelete = async function (existingOrNew, ruleID, index) {
        try {
            if (existingOrNew == 'new_rule') {
                vm.fieldRulesToAdd.splice(index, 1);
            } else {
                //  this will remove the last element of vm.existingFileRulesDisplay
                vm.existingFileRulesDisplay.splice(index, 1);

                vm.fieldRulesToDelete = vm.fieldRulesToDelete.concat([{
                    id: ruleID
                }]);
            }

            await vm.updateScanStation('prevent-field-rebuild');
            await vm.triggerBlocUpdateSuccess('Rule Deleted');


        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    };

    vm.addScanStationField = async function (initialFieldParams = null) {
        try {
            if (typeof (vm.newField.field_name) == 'undefined') {
                alert('Please Enter a field name before continuing');
                return false;
            }

            let validInitialFieldParams = await fieldValidation.validateInitialFieldParams(vm.newField.field_type, initialFieldParams);

            if (validInitialFieldParams !== true) {
                alert(validInitialFieldParams);
                return false;
            }

            vm.modalOff();

            if (vm.newField.field_type === 'checkbox' || vm.newField.field_type === 'button') {
                if (typeof (initialFieldParams.pressedUnpressedValues) == 'undefined') {
                    initialFieldParams.pressedUnpressedValues.pressed = 1;
                    initialFieldParams.pressedUnpressedValues.unPressed = 0;
                }

                if (typeof initialFieldParams.pressedUnpressedValues.pressed == 'undefined' || initialFieldParams.pressedUnpressedValues.pressed == '') {
                    initialFieldParams.pressedUnpressedValues.pressed = 1;
                }

                if (typeof initialFieldParams.pressedUnpressedValues.unPressed == 'undefined' || initialFieldParams.pressedUnpressedValues.unPressed == '') {
                    initialFieldParams.pressedUnpressedValues.unPressed = 0;
                }
            }

            vm.newField.initialFieldParams = initialFieldParams;

            vm.fieldOrder = vm.scanStationObj.scanStationFields.length + 1;
            vm.scanStationObj.scanStationFields = vm.scanStationObj.scanStationFields.concat(
                [{
                    field_name: vm.newField.field_name,
                    field_data: vm.newField.field_data,
                    field_order: vm.fieldOrder,
                    field_type: vm.newField.field_type,
                    field_next_line: vm.fieldNextLine,
                    field_width: vm.fieldWidth,
                    is_entry_cycle_field: vm.isEntryCycleField,
                    initial_field_params: vm.newField.initialFieldParams,
                    new_field_grid_id: vm.newFieldGridId
                }]);

            await vm.updateScanStation('add-new-field');
            vm.newField = {};
            vm.newField.field_type = 'text';
            // TODO when we've more time investigate a nicer way to call this function using binding rather than a broadcast
            $rootScope.$broadcast('reloadSpecificGrids', [vm.newFieldGridId])
            vm.showAddNewField = false;

            $scope.$apply();
            return true;

        } catch (e) {
            console.log(e);
        }
    };

    vm.addToFieldsToDelete = async function (field_slug, index, elementId) {
        try {
            vm.scanStationObj.scanStationFields.splice(index, 1);

            vm.fieldsToDelete = vm.fieldsToDelete.concat([{
                field_slug: field_slug
            }]);

            await vm.updateScanStation('prevent-field-rebuild');
            $rootScope.$broadcast('reloadSpecificGrids', [elementId]);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.addSelectedSOPFiles = async function (SOPFile) {
        try {
            let SOPFileFileArray = SOPFile.split("|");

            // check if its already there
            vm.isInArray = false;
            angular.forEach(vm.selectedSOPFiles, function (file) {
                if (file.id == SOPFileFileArray[0]) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.selectedSOPFiles = vm.selectedSOPFiles.concat([{
                    id: SOPFileFileArray[0],
                    title: SOPFileFileArray[1]
                }]);
            }

            await vm.updateScanStation('prevent-field-rebuild');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.addSelectedRoleForLoadingUsers = async function (roleDetails) {
        try {
            let RoleDetailsArray = roleDetails.split("|");

            // check if its already there
            vm.isInArray = false;
            angular.forEach(vm.selectedRolesForLoadingUsers, function (role) {
                if (role.id == RoleDetailsArray[0]) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.selectedRolesForLoadingUsers = vm.selectedRolesForLoadingUsers.concat([{
                    id: RoleDetailsArray[0],
                    title: RoleDetailsArray[1]
                }]);
            }

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.addStationUnit = async function (unit) {
        try {
            if (typeof (vm.stationUnits) == 'undefined' || vm.stationUnits === null) {
                vm.stationUnits = [];
            }
            vm.isInArray = false;
            angular.forEach(vm.stationUnits, function (value) {
                if (value == unit) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.stationUnits.push(unit);
            }

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeSelectedSOPFiles = async function (id) {
        try {
            angular.forEach(vm.selectedSOPFiles, function (file, index) {
                if (file.id == id) {
                    vm.selectedSOPFiles.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    vm.removeSelectedRolesForLoadingUsers = async function (id) {
        try {
            angular.forEach(vm.selectedRolesForLoadingUsers, function (role, index) {
                if (role.id == id) {
                    vm.selectedRolesForLoadingUsers.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeStationUnit = async function (unit) {
        try {
            angular.forEach(vm.stationUnits, function (value, index) {
                if (value == unit) {
                    vm.stationUnits.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.processScanStationFields = async function () {

        // first loop creates some variables that the second loop relies on
        angular.forEach(vm.scanStationObj.scanStationFields, async function (value, index) {
            value.fieldIndex = index;
            vm.scanStationFieldSlugs.push(value.field_slug);
            vm.scanStationFieldIDsToSlugs[value.id] = value.field_slug;
            vm.scanStationFieldIdsToField[value.id] = value;
            vm.scanStationFieldSlugsToField[value.field_slug] = value;
            if (value.parameters != null && value.parameters.length > 0) {
                angular.forEach(value.parameters, function (fieldParam) {
                    try {
                        value[fieldParam.scan_station_field_parameter] = JSON.parse(fieldParam.parameter_value);
                    } catch (e) {
                        value[fieldParam.scan_station_field_parameter] = fieldParam.parameter_value;
                    }

                    if (fieldParam.scan_station_field_parameter == 'excludeFromGetLatestData' && value[fieldParam.scan_station_field_parameter] == '1') {
                        vm.fieldsToExcludeFromGetData.push(value.field_slug);
                    }
                });
            }
        });

        // string data getting mixed with int data
        angular.forEach(vm.scanStationObj.scanStationFields, async function (value, index) {

            if (value.parameters != null && value.parameters.length > 0) {

                let builtFieldParameters = await fieldParameterFactory.buildParameterValues(vm.fieldsToExcludeFromGetData, value);
                vm.fieldsToExcludeFromGetData = builtFieldParameters.fieldsToExcludeFromGetData;
                value = builtFieldParameters.field;

                angular.forEach(value.parameters, function (parameterValue) {

                    //check if its a primary calendar field
                    if (parameterValue.scan_station_field_parameter == 'primaryCalendarField' && parameterValue.parameter_value == '1') {
                        if (value.field_type == 'auto-generate-date') {
                            vm.calendarPrimaryFieldFormat = value.dateFormat;
                        } else if (value.field_type == 'date-time-selector' && value.dateTimeSetting == 'date') {
                            vm.calendarPrimaryFieldFormat = 'DD/MM/YYYY';
                        }
                        if (value.field_type == 'auto-generate-date') {
                            vm.calendarPrimaryFieldFormat = value.dateFormat;
                        }
                    }
                    if (parameterValue.scan_station_field_parameter == 'andParam') {
                        vm.andGateParamArray[value.field_slug] = JSON.parse(parameterValue.parameter_value);
                    }

                    if (parameterValue.scan_station_field_parameter == 'filterByField' || parameterValue.scan_station_field_parameter == 'filterByFieldSubBloc') {
                        vm.filterByFields[value.field_slug] = JSON.parse(parameterValue.parameter_value);
                    }

                    if (parameterValue.scan_station_field_parameter == 'populateLatestDataField' && parameterValue.parameter_value == 1) {
                        vm.scanStationObj.stationDetails.has_populate_latest_data_specific_fields = 1;
                        vm.populateLatestDataFields.push(value.field_slug);
                    }

                    if (parameterValue.scan_station_field_parameter == 'doesntSaveData' && parameterValue.parameter_value == 1) {
                        vm.fieldsThatDontSaveData.push(value.field_slug);
                    }
                });
            }

            if (typeof value.focusAfterSubmission != 'undefined' && value.focusAfterSubmission == 1) {
                vm.priorityFocus = '#station-field-' + index;
            }

            if (value.field_type == 'date-time-selector' && value.numberOfDaysFieldId != null && vm.scanStationFieldIdsToField[value.numberOfDaysFieldId] != null) {

                let dateToAddFromFieldId = null;

                if (value.dateToAddFromFieldId != null && vm.scanStationFieldIdsToField[value.dateToAddFromFieldId] != null) {
                    dateToAddFromFieldId = value.dateToAddFromFieldId;
                }

                vm.fieldIdsThatChangeDates.push({
                    'fieldSlugWithNumberValue': vm.scanStationFieldIdsToField[value.numberOfDaysFieldId].field_slug,
                    'fieldIdSrc': value.numberOfDaysFieldId,
                    'fieldSlugToChange': value.field_slug,
                    'dateToAddFromFieldId': dateToAddFromFieldId,
                    'triggerIfChanges' : vm.scanStationFieldIdsToField[dateToAddFromFieldId],
                });
            }

            if (value.field_type == 'field-merge-builder') {
                vm.fieldsUsedForMergeField[value.field_slug] = [
                    value.fieldMergeFormat,
                    vm.getMergedFieldSlugs(value.fieldMergeFormat)
                ];
            }

            if (value.field_type == 'cross-bloc-data-updates' && (value.whenActionHappens != null && value.whenActionHappens == 'on-form-submit')) {
                vm.crossBlocDataUpdateFieldsOnDataSubmit.push(value.id);
            }

            if (value.field_type == 'mail-alert' && (value.applyOnNewSubmission != null && value.applyOnNewSubmission == 1)) {
                vm.mailAlertFieldsOnSubmit.push(value.id);
            }

            if (value.field_type == 'mail-alert' && (value.applyWhenEditing != null && value.applyWhenEditing == 1)) {
                vm.mailAlertFieldsWhenEditing.push(value.id);
            }

            if (value.field_type == 'blocworx-gateway' && (value.applyOnNewSubmission != null && value.applyOnNewSubmission == 1)) {
                vm.blocworxGatewayFieldsOnSubmit.push(value.id);
            }

            if (value.field_type == 'blocworx-gateway' && (value.applyWhenEditing != null && value.applyWhenEditing == 1)) {
                vm.blocworxGatewayFieldsWhenEditing.push(value.id);
            }

            if (value.field_type == 'link-data-field') {
                vm.crossBlocDataUpdateFieldsOnDataSubmit.push(value.id);
            }

            if (value.field_type == 'dropdown-from-station' || value.field_type == 'radio-buttons-from-station'
                || value.field_type == 'autocomplete-field' || value.field_type == 'checkbox-buttons-from-station' || value.autocomplete == 1) {

                let dropDownFilterDataFields = await DataFromStationFieldsFactory.buildDropDownFilterDataFields(value);
                vm.dropdownFilteredDataFields.push(dropDownFilterDataFields);
            }

            if (value.hidden_options !== undefined && value.hidden_options.length > 0) {
                angular.forEach(value.hidden_options, function (hideOptions) {
                    if (hideOptions.hidden_option == 'report_list') {
                        value.reportListHide = true;
                    } else if (hideOptions.hidden_option == 'on_screen_list') {
                        value.screenListHide = true;
                    } else if (hideOptions.hidden_option == 'show_more') {
                        value.showMoreHide = true;
                    } else if (hideOptions.hidden_option == 'export_print') {
                        value.exportPrintHide = true;
                    } else if (hideOptions.hidden_option == 'in_form') {
                        value.inFormHide = true;
                    } else if (hideOptions.hidden_option == 'is_filter') {
                        value.isFilterHide = true;
                    } else if (hideOptions.hidden_option == 'hide_from_results') {
                        value.hide_from_get_data_list = 1;
                    }
                });
            }

            if (value.field_type === 'digital-signature') {
                if (typeof (vm.digitalSignatureButtons) == 'undefined') {
                    vm.digitalSignatureButtons = [];
                }
            }

            if (value.parent_field_id != null) {
                vm.childParentStation = true;
            }

            vm.scanStationObj.scanStationFields[index].field_order = parseInt(value.field_order);

            // get the field slugs so we can generate a sample report


            // field types for use in the resulting data to determine how to present it
            vm.scanStationFieldTypes[value.field_slug] = value.field_type;
            vm.scanStationFieldNames[value.field_slug] = value.field_name;
            vm.scanStationHideOptions[value.field_slug] = value.hide_from_get_data_list;

            if (value.field_type === 'checkbox' || value.field_type === 'button') {
                value.trueValue = value.pressedUnpressedValues.pressed;
                value.falseValue = value.pressedUnpressedValues.unPressed;
            }

            if (value.field_type == 'maths') {
                vm.scanStationMathsResultsFields[value.field_slug] = {};
                vm.scanStationMathsResultsFields[value.field_slug].equation = value.mathsEquation;
                vm.scanStationMathsResultsFields[value.field_slug].defaultText = 'Syntax Error';
                if (typeof value.syntaxErrorDefaultText != 'undefined') {
                    vm.scanStationMathsResultsFields[value.field_slug].defaultText = value.syntaxErrorDefaultText;
                }
            }

            if (value.field_type == 'total-results') {
                vm.scanStationObj.hasTotalsField = true;
                let totalsResultFieldData = value.totalResultsAssociatedFields;
                if (typeof vm.scanStationFieldIdsToField[value.totalResultsAssociatedFields.keyFieldId] != 'undefined' && vm.scanStationFieldIdsToField[value.totalResultsAssociatedFields.fieldIdToCount] != 'undefined')
                    vm.totalsField[value.field_slug] = {
                        'parentField': vm.scanStationFieldIdsToField[value.totalResultsAssociatedFields.keyFieldId].field_slug,
                        'childField': vm.scanStationFieldIdsToField[value.totalResultsAssociatedFields.fieldIdToCount].field_slug,
                        'totalsField': value.field_slug,
                        'totalResultsAssociatedFields': value.totalResultsAssociatedFields
                    };
            }

            if (value.field_type == 'time-diff') {
                if (value.startAndFinishFields !== undefined
                    && typeof vm.scanStationFieldIdsToField[value.startAndFinishFields.startFieldId] != 'undefined'
                    && vm.scanStationFieldIdsToField[value.startAndFinishFields.finishFieldId] != 'undefined') {
                    vm.timeDiffFields[value.field_slug] = {
                        'startFieldSlug': vm.scanStationFieldIdsToField[value.startAndFinishFields.startFieldId].field_slug,
                        'finishFieldSlug': vm.scanStationFieldIdsToField[value.startAndFinishFields.finishFieldId].field_slug,
                        'timeDiffFieldSlug': value.field_slug,
                        'field': value
                    };
                }
            }


            if (value.field_type == 'formception-2') {

                let jsonParentChildFields = {};

                value.formceptionLink = '/module/' + value.formceptionTwoStationAndJob.uniqueModuleSlug + '/bloc/' + value.formceptionTwoStationAndJob.stationId;


                if (typeof vm.subformArray[value.field_slug] == "undefined") {
                    vm.subformArray[value.field_slug] = {};
                }

                vm.subformArray[value.field_slug]['subFormMasterField'] = value.subFormParentChildFields;
                vm.subformArray[value.field_slug]['subFormElementID'] = value.field_slug;
                vm.subformArray[value.field_slug]['subFormID'] = value.formceptionTwoStationAndJob.stationId;
                vm.subformArray[value.field_slug]['subFormParentChildFields'] = value.subFormParentChildFields;
                vm.subformArray[value.field_slug]['clickShowMoreLastEntry'] = value.clickShowMoreLastEntry;

            }

            if (value.field_type == 'data-to-form') {

                let localFormListFieldsForPassingData = [];
                angular.forEach(value.formListLocalRemoteFields, function (fieldPair) {
                    localFormListFieldsForPassingData.push(fieldPair.localFieldId);
                });

                vm.dataToFormArray.push({
                    linkedStationDetails: value.linkedStationDetails,
                    dataToPass: value.formListLocalRemoteFields,
                    dataToFormFieldSlug: value.field_slug,
                    jobID: value.formListJobAndStationId.jobId,
                    localFormListFieldsForPassingData: localFormListFieldsForPassingData,
                    localLinkedStationFieldId: (value.linkedStationDetails != null) ? value.linkedStationDetails.localFieldId : ''
                });

                if (value.formListLatestDataSlugFilters != null) {
                    vm.dataToFormLatestDataFilters[value.field_slug] = value.formListLatestDataSlugFilters;
                }
            }


            // check for parameters
            if (value.field_show_condition != null) {
                value.conditionallyShow = 'hide';
                value.conditionalShowFieldSlug = value.field_show_condition.field_slug;
                value.conditionalShowParameters = value.field_show_condition.parameters.split(',');
            } else {
                value.conditionallyShow = 'show';
                value.conditionalShowParameters = false;
            }

            if (value.show_counter == 1) {
                vm.scanStationFieldCounterArray.push({
                    slug: value.field_slug,
                    name: value.field_name
                });
            }

            if (value.field_type == 'auto-generate-number-text' || value.field_type == 'auto-generate-number' || value.field_type == 'auto-generate-number-date') {
                if (typeof (value.referenceField) != 'undefined' && typeof vm.scanStationFieldIdsToField[value.referenceField] != 'undefined') {
                    vm.autogenerateReferenceFields[value.field_slug] = [vm.scanStationFieldIdsToField[value.referenceField].field_slug, value, value.field_type];
                }
            }

            value.fieldNextLine = value.field_next_line;
            value.fieldWidth = value.field_width;
            value.isEntryCycleField = value.is_entry_cycle_field;
            if (value.isEntryCycleField == 1) {
                vm.entryCycleKeySlug = value.field_slug;
            }

            return true;

        });
    }

    vm.getScanStationData = async function (id, queryType, reload = false) {
        try {
            let data = await scanStation.getScanStationData(id, vm.scanStationObj.stateName, reload);
            if (typeof(data.data.message) != 'undefined' && data.data.message == 'Not Allowed') {
                vm.hasScanStationPermission = false;
                return;
            } else {
                vm.hasScanStationPermission = true;
            }

            vm.scanStationFieldDesignOptions = [];
            vm.scanStationObj.stationDetails = data.data.stationDetails;

            if (vm.scanStationObj.stationDetails.parameters && vm.scanStationObj.stationDetails.parameters.length > 0) {
                angular.forEach(vm.scanStationObj.stationDetails.parameters, function (stationParam) {
                    if (stationParam.scan_station_parameter == 'email_alert_options') {
                        vm.scanStationObj.stationDetails[stationParam.scan_station_parameter] = JSON.parse(stationParam.parameter_value);
                        if (vm.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailAddressParam != null) {
                            vm.emailAddressParam = vm.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailAddressParam;
                        }

                        if (vm.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailParam != null) {
                            vm.emailParamDefaultText = vm.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailParam;
                        }
                    } else if (stationParam.scan_station_parameter == 'restrictDataByUsername') {
                        vm.scanStationObj.stationDetails[stationParam.scan_station_parameter] = (stationParam.parameter_value).replaceAll('"', '').split(',');
                    } else if (stationParam.scan_station_parameter == 'sync-with-profile') {

                        let syncWithProfilesPrimaryParameters = JSON.parse(stationParam.parameter_value);

                        if (syncWithProfilesPrimaryParameters.primaryField != null) {
                            vm.profileSyncingPrimaryField = syncWithProfilesPrimaryParameters.primaryField;
                        }

                        if (syncWithProfilesPrimaryParameters.profileSyncingFullNameField != null) {
                            vm.profileSyncingFullNameField = syncWithProfilesPrimaryParameters.profileSyncingFullNameField;
                        }

                        if (syncWithProfilesPrimaryParameters.secondaryFields != null) {
                            vm.profileSyncingSecondaryArray = syncWithProfilesPrimaryParameters.secondaryFields;
                        }

                    } else if (stationParam.scan_station_parameter == 'sync-with-profile-extra-data') {

                        let syncWithProfilesPrimaryParameters = JSON.parse(stationParam.parameter_value);

                        if (syncWithProfilesPrimaryParameters.primaryField != null) {
                            vm.profileSyncingPrimaryField = syncWithProfilesPrimaryParameters.primaryField;
                        }

                        if (syncWithProfilesPrimaryParameters.secondaryFields != null) {
                            vm.profileSyncingSecondaryArray = syncWithProfilesPrimaryParameters.secondaryFields;
                        }

                        if (syncWithProfilesPrimaryParameters.secondaryFields != null) {
                            vm.profileSyncingExtraFields = syncWithProfilesPrimaryParameters.extraFields;
                        }

                    } else if (stationParam.scan_station_parameter == 'blocRedirect' && vm.stateName == 'bloc') {
                        let parameterValueUrl = stationParam.parameter_value;
                        let url = parameterValueUrl.replace(/\\/g, '');
                        url = url.replaceAll('"', '');
                        window.location = url;
                    } else {
                        vm.scanStationObj.stationDetails[stationParam.scan_station_parameter] = JSON.parse(stationParam.parameter_value);
                    }
                });
            }

            if (vm.scanStationObj.stationDetails.defaultNumberOfResults != null) {
                vm.resultLimit = vm.scanStationObj.stationDetails.defaultNumberOfResults;
            }

            if (queryType == 'add-new-field') {
                vm.scanStationObj.scanStationFields.push(data.data.scanStationFields[data.data.scanStationFields.length - 1]);
            } else if (queryType != 'non-fields-related') {
                vm.scanStationObj.scanStationFields = data.data.scanStationFields;
            }

            vm.scanStationObj.showForm = vm.scanStationObj.stationDetails.hide_form != 1;
            vm.scanStationFieldSlugs = [];
            vm.scanStationFieldTypes = [];
            vm.scanStationFieldNames = [];
            vm.scanStationFieldCounterArray = [];
            vm.scanStationHideOptions = [];
            vm.scanStationTimePickerFields = [];
            vm.scanStationDatePickerFields = [];
            vm.childParentStation = false;
            vm.scanStationMathsResultsFields = {};
            vm.checkboxesWithDynamicDataFields = [];
            vm.dropdownFilteredDataFields = [];
            vm.fieldsToExcludeFromGetData = [];
            vm.fieldsUsedForMergeField = {};
            vm.autogenerateReferenceFields = {};
            vm.calendarPrimaryFieldFormat = 'YYYY-MM-DD';
            vm.scanStationFieldIDsToSlugs = {};
            vm.scanStationFieldIdsToField = {};
            vm.scanStationFieldSlugsToField = {};
            vm.populateLatestDataFields = [];
            vm.fieldsThatDontSaveData = [];
            vm.crossBlocDataUpdateFieldsOnDataSubmit = [];
            vm.mailAlertFieldsOnSubmit = [];
            vm.mailAlertFieldsWhenEditing = [];
            vm.blocworxGatewayFieldsOnSubmit = [];
            vm.blocworxGatewayFieldsWhenEditing = [];
            vm.dataToFormLatestDataFilters = {};
            vm.fieldIdsThatChangeDates = [];

            await vm.processScanStationFields();
            await vm.loadJsonDataFromUrl();

            vm.scanStationFieldsReady = true;

            // to send as get request
            vm.scanStationFieldSlugs = angular.toJson(vm.scanStationFieldSlugs);
            vm.selectedSOPFiles = data.data.stationFiles.SOPFiles;
            vm.selectedRolesForLoadingUsers = data.data.selectedRolesForLoadingUsers;
            vm.stationUnits = data.data.stationUnits;
            vm.stationName = data.data.stationName;
            vm.scanStationObj.stationName = data.data.stationName;
            vm.requires_anti_static = data.data.stationRequiresAntiStatic;
            vm.hide_from_reports = data.data.hideFromReports;
            vm.useCustomisedLayout = data.data.useCustomisedLayout;
            vm.previousButtonURL = data.data.previousButtonURL;
            vm.nextButtonURL = data.data.nextButtonURL;
            vm.linkedStationInstruction = data.data.linkedStationInstruction;
            vm.scan_limit = data.data.scanLimit;
            vm.scan_limit_time_logged = data.data.scanLimitTimeLogged;
            vm.sections = data.data.sections;
            vm.stationSectionID = data.data.sectionID;
            vm.stationRevision = data.data.stationRevision;
            vm.nextRevision = parseInt(vm.stationRevision) + 1;
            vm.checkIfLoadMore();

            $scope.$apply();

        } catch (e) {
            console.log(e)
        }
    };

    vm.getStationDataForDropDown = async function (fieldSlug, stationAndField, dropdownFromStationOnlyDataAfterDate, localFieldMatchingFieldPairs,
                                                   reverse, dropDownFromStationExcludeMatchingDataFromAnotherFieldData, localFieldExclusionMatchingFieldPairs) {
        try {

            let data = await scanStation.getStationDataForDropDown(vm.id, stationAndField, dropdownFromStationOnlyDataAfterDate, 'dropdown-from-another-station', localFieldMatchingFieldPairs, reverse, dropDownFromStationExcludeMatchingDataFromAnotherFieldData, localFieldExclusionMatchingFieldPairs);

            if (typeof (vm.stationIDForStationDataForDropDown) == 'undefined') {
                vm.stationIDForStationDataForDropDown = [];
            }

            vm.stationIDForStationDataForDropDown[fieldSlug] = stationAndField.stationId;
            vm.preventRuleCall[fieldSlug] = true;
            vm.stationDataForDropDown[fieldSlug] = data.data.data;
            vm.preventRuleCall[fieldSlug] = false;

            $scope.$apply();

        } catch (e) {
            console.log(e)
        }

    };

    /**
     * Searches the array of {@param fieldSlug} with the user input {@param input} and returns and array of the matches
     * it has found.
     * @param fieldSlug
     * @param input
     *
     */
    vm.getSearchData = function (fieldSlug, input) {
        try {
            let result = []
            angular.forEach(vm.stationDataForDropDown[fieldSlug], function (foundData) {
                if (foundData.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                    result.push(foundData);
                }
            });

            vm.searchResult[fieldSlug] = result;


        } catch (e) {
            console.log(e);
        }

    };

    vm.accumulateRulesForSubmitButton = function (e, field_id, data, index) {
        try {
            if (typeof (vm.ruleDataToTest) == 'undefined') {
                vm.ruleDataToTest = [];
            }
            if (e.keyCode == 13 || e.keyCode == 9) {
                vm.ruleDataToTest.push({
                    field_id: field_id,
                    data: data,
                    index: index
                });
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This will load all actions.
     */
    vm.loadAllActions = async function (data = null) {
        /*
        // TODO discuss this with Raf, we may leave it out until V3.1 so we can decide how it works, in its current state it seems to be causing some rule issues
        console.log('loading all ACTIONS')
        let allFieldsData = vm.getAllFieldsData();
        let scanStationId = vm.scanStationObj.id;

        if (!vm.actions) {
            vm.actions = await scanStation.loadAllActions(scanStationId, allFieldsData, data);
        }

        angular.forEach(vm.actions.data.populate_fields, function (value, index) {
           vm.dataToAdd[index] = value;
            vm.scanStationObj.dataToAdd[index] = value;
        });*/

    }

    vm.isFieldAsFilterCase = async function (field_id, data) {
        try {

            let field = vm.scanStationFieldIdsToField[field_id];
            if (field.filterByFieldSubBloc != null && field.filterByFieldSubBloc == 1) {

                if (!$('body').hasClass('blocworx-iframe')) {
                    return false;
                }

            }
            // Loading the field object
            let fieldObject = ScanStationService.init(vm.scanStationObj.scanStationFields).getFieldByFieldId(field_id);

            // checking if this filed is a field filter kind
            if (FilterByFieldService.isFieldAsFilter(field_id, fieldObject)) {
                let options = {'filterDataToQuery': {}};

                // allow for filtering of multiple fields

                if (vm.filterDataOptions != null && vm.filterDataOptions.filterDataToQuery != null) {
                    options = vm.filterDataOptions;
                }

                options.filterDataToQuery[fieldObject.field_slug] = [data];

                if (data == '' && options.filterDataToQuery[fieldObject.field_slug] != null) {
                    delete options.filterDataToQuery[fieldObject.field_slug];
                }

                if (data == '' && vm.filterDataOptions.filterDataToQuery[fieldObject.field_slug] != null) {
                    delete vm.filterDataOptions.filterDataToQuery[fieldObject.field_slug];
                }

                vm.filterDataOptions = options;

                Data.updateEnterDataController(options, 'updateDataForDataFiltering');

                // we don't trigger the data look up if its just a filter only and not a triggering one
                if (fieldObject.filterValueOnlyField != 1) {
                    if (Object.keys(options.filterDataToQuery).length > 0) {
                        await vm.getDataForDataFiltering('with-filter', options);
                    } else {
                        await vm.getData(vm.scanStationObj.id);
                    }
                }
            }

            // TODO figure out how to guarantee this happens after the data is finished displaying on the page
            setTimeout(function () {
                if (vm.thisSubBlocLoadsLatestData == true && vm.clickTriggerFinished != true) {
                    $('.form-results-section.table .form-results-tbody tr:first-child td:last-child a i').trigger('click');
                    vm.clickTriggerFinished = true;
                }
            }, 1000);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Function that will remove all lastfield class
     * from the whole form, it will also go though all the elments
     * and only on the last one it will be adding the lastfield
     * class in it.
     *
     * @param field_id
     */
    vm.updateLastField = function (field_id) {
        try {
            let fieldsInput = $("div[id^='scan-station-fields'] input:not(.hide), div[id^='scan-station-fields'] textarea, div[id^='scan-station-fields'] button:not(.submit-button)");
            let length = fieldsInput.length;

            // should remove from all elements the last
            $("div[id^='scan-station-fields'] input, div[id^='scan-station-fields'] textarea, div[id^='scan-station-fields'] button").removeClass('lastfield');

            // loops though the array of fields and set the last one
            fieldsInput.each(function (index, value) {
                if (index === length - 1) { // This will be only the last element
                    $(value).addClass('lastfield');
                }
            });

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getAllFieldsData = () => {

        let allFieldsData = {};
        let dataToAdd = <AllDataInterface>{};

        angular.forEach(vm.scanStationObj.scanStationFields, (field) => {
            let dataToAdd = {
                fieldId: field.id,
                field_name: field.field_name,
                data: vm.scanStationObj.dataToAdd[field.field_slug],
                slug: field.field_slug,
            };
            allFieldsData[field.id] = dataToAdd;
        });

        vm.allFieldsData = allFieldsData;
        return allFieldsData;

    }

    vm.updateDataValue = async function () {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve('ready');
            }, 200);
        });
    }

    /**
     * This will be the trigger of the action to clean all broken rules
     * by clicking to the field.
     */
    vm.fieldClick = (selector) => {

        // at anytime that you select a field, must be removing the class rule-broken due to, it must be an attempt to fix it
        let scanStationField = document.querySelector(`${selector} .rule-broken`);

        // just a basic check if exist or not the classList, so we can remove the rule-broken
        if(scanStationField != undefined && scanStationField.classList != undefined){
            scanStationField.classList.remove('rule-broken');
        }
    }

    vm.getRules = async function (e, field_id, special_field_key, data, index, fieldSlug) {
        try
        {

            // field parameter that prevents rules from being triggered while filling out the form, but submits them afterwards
            // for sub blocs only
            if (vm.scanStationFieldIdsToField[field_id].preventRuleSubmissionInSubBlocBeforeSubmitting && e != 'after-submission') {
                if ($('body').hasClass('blocworx-iframe')) {
                    return false;
                }
            }

            // field parameter that prevents rules from being triggered while filling out the form, but submits them afterwards
            // for sub blocs only
            if (vm.scanStationFieldIdsToField[field_id].preventRuleSubmissionInBeforeSubmitting && e != 'after-submission') {
                return true;
            }


            // ng-keydown is causing our value to be 1 entry behind when we are not hitting enter, so field parameters like trigger rules on key press arent working properly
            // for now we must for a $scope.$apply() to ensure we are working with the latest data value
            await vm.updateDataValue();
            $scope.$apply();
            data = vm.scanStationObj.dataToAdd[fieldSlug];

            $('#station-field-' + index).removeClass('minor-issue');

            if (data == 'blocworx-unset-maths') {
                return true;
            }

            if (vm.scanStationFieldIdsToField[field_id].preventRuleTriggerValues != null && vm.scanStationFieldIdsToField[field_id].preventRuleTriggerValues != '') {
                let ruleFirePreventionValues = vm.scanStationFieldIdsToField[field_id].preventRuleTriggerValues.split(',');
                if (ruleFirePreventionValues.includes(data)) {
                    return true;
                }
            }

            // factory step
            await vm.generateFileForBartender(fieldSlug);

            // factory step
            vm.detectFieldAndBuildMerge(fieldSlug);

            vm.linkedDataFieldJson = await LinkFieldFactory.rebuildLinkedDataJson(vm.scanStationFieldIdsToField, vm.scanStationObj.dataToAdd);

            // validation
            if (typeof (vm.preventRuleCall[fieldSlug]) != 'undefined' && vm.preventRuleCall[fieldSlug] == true) {
                return true;
            }

            // TODO: this uses jquery, could be nice to use the object to check this out
            vm.updateLastField(field_id);

            // factory step
            if (typeof (vm.scanStationObj.dataToAdd[vm.entryCycleKeySlug]) != 'undefined') {
                vm.entryCycleKey = vm.dataToAdd[vm.entryCycleKeySlug];
                vm.scanStationObj.entryCycleKey = vm.scanStationObj.dataToAdd[vm.scanStationObj.entryCycleKeySlug];
            } else {
                vm.entryCycleKey = 1;
                vm.scanStationObj.entryCycleKey = 1;
            }

            // factory step
            if (vm.scanStationObj.entryCycleKeySlug == fieldSlug && vm.scanStationObj.stationDetails.uses_entry_cycles == 1) {
                await vm.getLastEntryByEntryCycleKey();
            }

            // TODO: why we have a success message before finish all the things?
            // Answer: The function name was bad, it should have been hideDataAddSuccessMsg() not showDataAddSuccessMsg
            if (vm.dataAddSuccess == 1) {
                await vm.hideDataAddSuccessMsg();
            }

            // we use this state to check if this is being fired while filling the form or after the form as been submitted.
            let afterSubmitState = false;

            if (e == 'after-submission') {
                afterSubmitState = true;
            }

            if (e == 'no-event' || e == 'after-submission') {

                if (data == null || data == '') {
                    // no event along with no data either, this means it came from a plain data field or some other field where there was no input or new data set
                    // in this case there is no point checking the rules.
                    return true;
                }
                e = {};
                e.keyCode = 13;
            } else {
                vm.prompt = false;
                vm.yellowPrompt = false;
            }

            await vm.parseAndGateParam(field_id, special_field_key, index);

            await vm.checkShowCounter(fieldSlug);

            // TODO: refactor to key action, will trigger validations
            if (e.keyCode === 13 || e.keyCode === 9 || e.type === 'click' || e === 'select-change' || e === 'force-rule' || vm.scanStationFieldIdsToField[field_id].triggerRuleOnValueChange == 1) {


                // we want to reset the autocomplete searches, but not while the person is typing with the keypress rule trigger parameter
                if (typeof(vm.searchResult[fieldSlug]) != 'undefined' && e.type == 'click') {
                    vm.searchResult[fieldSlug] = null;
                }

                await MindeeFactory.updateBulkCases();

                await vm.updateDateFieldsWithNewValues(fieldSlug, data);


                await vm.setNumericField(field_id, fieldSlug, data, index);

                // factory step: vm.subformArray
                angular.forEach(vm.subformArray, function (subFormDatum, subFormSlug) {
                    let fieldSlugToUpdate = null;
                    let field = vm.scanStationFieldSlugsToField[subFormSlug];
                    angular.forEach(field.subFormParentChildFields, function (parentChildCombo) {
                        if (field_id == parentChildCombo.parentFieldId) {
                            vm.updateSubForm(data, subFormDatum['subFormParentChildFields'], parentChildCombo.childFieldId, subFormSlug);
                        }
                    });
                });

                // for sub blocs, send data to parent

                await SubBlocFieldFactory.sendDataToParent(data, vm.scanStationFieldIdsToField[field_id]);


                await vm.triggerChartUpdates(fieldSlug);

                await vm.isFieldAsFilterCase(field_id, data);

                angular.forEach(vm.dataToFormArray, async function (dataToFormField, dataToFormIndex) {
                    if (typeof data != 'undefined') {

                        if (dataToFormField.localFormListFieldsForPassingData.includes(field_id)) {
                            if (typeof dataToFormField.dataToFormFieldSlug != 'undefined') {
                                await vm.buildDataToFormsJSONData(dataToFormField.dataToFormFieldSlug);
                            }
                        }

                        if (typeof (dataToFormField.jobID) == 'undefined') {
                            dataToFormField.jobID = vm.jobID;
                        }

                        if (field_id == dataToFormField.localLinkedStationFieldId && typeof dataToFormField.linkedStationDetails != 'undefined' && dataToFormField.linkedStationDetails != {} && Object.keys(dataToFormField.linkedStationDetails).length > 0 && data != '') {
                            await vm.dataToFormCheckLinkedStation(data, dataToFormField.linkedStationDetails.remoteFieldToSearchId,
                                dataToFormField.linkedStationDetails.remoteFieldWithBlocNamesId, dataToFormField.linkedStationDetails.stationId,
                                dataToFormField.dataToFormFieldSlug, dataToFormField.jobID);
                        }
                    }
                });

                angular.forEach(vm.dataToFormLatestDataFilters, function (dataToFormFilters, dataToFormFieldSlug) {
                    angular.forEach(dataToFormFilters, async function (dataToFormFilterFieldSlug) {
                        if (fieldSlug == dataToFormFilterFieldSlug) {
                            await vm.buildBlocListLatestData(dataToFormFieldSlug);
                            $scope.$apply();
                        }
                    });
                });

                if (vm.totalsField && vm.scanStationObj.isInEditMode == false) {
                    angular.forEach(vm.totalsField, async function (totalField) {
                        if (totalField.parentField == fieldSlug) {
                            await vm.getTotals(totalField.totalsField, totalField.totalResultsAssociatedFields, data);
                        }

                        if (vm.showFormceptionPopup) {
                            await vm.getTotalsResults();
                        }
                    });
                }

                if (vm.timeDiffFields) {
                    //TODO check for fields that are startFieldSlug or finishFieldSlug then calculate difference
                    angular.forEach(vm.timeDiffFields, function (timeDiffField) {
                        if (timeDiffField.startFieldSlug == fieldSlug || timeDiffField.finishFieldSlug == fieldSlug) {
                            vm.calculateTimeDifference(timeDiffField.timeDiffFieldSlug, timeDiffField.field, timeDiffField);
                        }
                    });
                }

                await vm.getResultsBoxArrayData(fieldSlug, data);

                // a change in local field that is used to match data can trigger the dropdown look up rule

                let canGetLocalFieldMatchingFieldPairs = await DataFromStationFieldsFactory.canGetLocalFieldMatchingFieldPairs(field_id, vm.dropdownFilteredDataFields);
                if (canGetLocalFieldMatchingFieldPairs.length > 0) {

                    angular.forEach(canGetLocalFieldMatchingFieldPairs, async function (value) {
                        angular.forEach(value.localFieldMatchingFieldPairs, function (fieldPair) {
                            fieldPair.localFieldValue = vm.scanStationObj.dataToAdd[vm.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                        });

                        angular.forEach(value.localFieldExclusionMatchingFieldPairsArray, function (fieldPair) {
                            fieldPair.localFieldValue = vm.scanStationObj.dataToAdd[vm.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                        });

                        await vm.getStationDataForDropDown(value.fieldListSlug, value.dropdownFromStationLookUp, value.dropdownFromStationOnlyDataAfterDate, value.localFieldMatchingFieldPairs, value.dropdownFromStationReverseOrder, value.dropDownFromStationExcludeMatchingDataFromAnotherFieldData, value.localFieldExclusionMatchingFieldPairsArray);

                    });
                }

                // a change in the local field that is used to exclude data can also trigger the rule

                let canGetLocalFieldExclusionMatchingFieldPairs = await DataFromStationFieldsFactory.canGetLocalFieldExclusionMatchingFieldPairs(field_id, vm.dropdownFilteredDataFields);
                if (canGetLocalFieldExclusionMatchingFieldPairs.length > 0) {

                    angular.forEach(canGetLocalFieldExclusionMatchingFieldPairs, async function (value) {
                        angular.forEach(value.localFieldMatchingFieldPairs, function (fieldPair) {
                            fieldPair.localFieldValue = vm.scanStationObj.dataToAdd[vm.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                        });

                        angular.forEach(value.localFieldExclusionMatchingFieldPairsArray, function (fieldPair) {
                            fieldPair.localFieldValue = vm.scanStationObj.dataToAdd[vm.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                        });

                        await vm.getStationDataForDropDown(value.fieldListSlug, value.dropdownFromStationLookUp, value.dropdownFromStationOnlyDataAfterDate, value.localFieldMatchingFieldPairs, value.dropdownFromStationReverseOrder, value.dropDownFromStationExcludeMatchingDataFromAnotherFieldData, value.localFieldExclusionMatchingFieldPairsArray);
                        $scope.$apply();

                    });
                }


                await vm.updateLocalMathsResults();
                $scope.$apply();

                angular.forEach(vm.autogenerateReferenceFields, async function (autoGenerateReferenceField, referenceFieldSlug) {
                    if (autoGenerateReferenceField[0] == fieldSlug) {
                        await vm.getAutoGenerateFieldData(autoGenerateReferenceField[1], autoGenerateReferenceField[2], referenceFieldSlug);
                    }
                });

                if (typeof (vm.scanStationObj.liveData.countLimitInfo) != 'undefined'
                    && vm.scanStationObj.liveData.countLimitInfo.limit_reached == 1) {
                    vm.prompt = 'Scan Limit has been reached';
                    await vm.triggerPrompt(index);
                } else {

                    try {
                        // send data of several fields for field matching rule
                        let aditionalData = await vm.buildAdditionalDataForRules(field_id, fieldSlug);
                        if (aditionalData) {
                            data = aditionalData;
                        }

                        // we dont want to go any further if we meet this criteria as we have all actions triggered that we needed
                        if (afterSubmitState == true && vm.scanStationFieldIdsToField[field_id].preventBackEndRulesFromFiring == 1) {
                            return true;
                        }

                        let allFieldsData = vm.getAllFieldsData();

                        // todo: check DATA here!
                        let ruleResponse = await scanStation.getRules(field_id, data, vm.scanStationObj.stationDataID, allFieldsData);

                        if (typeof (ruleResponse.data.metaData.populateOtherFieldDataFromRulePassing) != 'undefined') {
                            angular.forEach(ruleResponse.data.metaData.populateOtherFieldDataFromRulePassing, function (value, index) {
                                if (typeof vm.scanStationFieldIdsToField[index] != 'undefined') {
                                    vm.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                                    vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                                    if (vm.scanStationFieldIdsToField[index] != null && vm.scanStationFieldIdsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                        let field = vm.scanStationFieldIdsToField[index];
                                        vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                    }
                                }
                            });
                        }

                        // TODO is this is ever being used?
                        angular.forEach(ruleResponse.data.metaData.responseData.populate_fields, function (value, index) {
                            vm.dataToAdd[index] = value;
                            vm.scanStationObj.dataToAdd[index] = value;
                            if (vm.scanStationFieldSlugsToField[index] != null && vm.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                let field = vm.scanStationFieldSlugsToField[index];
                                vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                            }

                        });

                        if ((e.keyCode == 13 || e.keyCode == 9) && !e.shiftKey) {
                            await vm.checkLastThenSubmit(e, vm.scanStationObj.id, field_id);
                        }

                        if (e.type == 'click' && vm.scanStationFieldIdsToField[field_id].submitDataField != null && vm.scanStationFieldIdsToField[field_id].submitDataField == 1) {
                            //TODO Raf we will need to make a ticket to see how we can resolve this and remove the timeout, problem is we need to let other rules finish happening from the response of the data
                            setTimeout(async function () {
                                await vm.checkAllRulesThenAddData(vm.scanStationObj.dataToAdd, vm.scanStationObj.id);
                            }, 500);
                        }

                        $scope.$apply();

                    } catch (res) {

                        console.log(res);
                        let metaData = res.data.metaData;
                        let responseData = res.data.responseData;
                        let failedFieldRule = res.data.failedFieldRule;
                        let failedResponseType = res.data.failedResponseType;

                        // This will populate when we have failures
                        if (metaData !== undefined && metaData.populateOtherFieldDataFromRuleFailure != 'undefined') {
                            angular.forEach(metaData.populateOtherFieldDataFromRuleFailure, function (value, index) {
                                if (typeof vm.scanStationFieldIdsToField[index] != 'undefined') {
                                    vm.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                                    vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                                    if (vm.scanStationFieldIdsToField[index] != null && vm.scanStationFieldIdsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                        let field = vm.scanStationFieldIdsToField[index];
                                        vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                    }
                                }
                            });
                        }

                        // This will populate when we have success case
                        if (metaData !== undefined && metaData.populateOtherFieldDataFromRulePassing != 'undefined') {
                            angular.forEach(metaData.populateOtherFieldDataFromRulePassing, function (value, index) {
                                if (typeof vm.scanStationFieldIdsToField[index] != 'undefined') {
                                    vm.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                                    vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[index].field_slug] = value;
                                    if (vm.scanStationFieldIdsToField[index] != null && vm.scanStationFieldIdsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                        let field = vm.scanStationFieldIdsToField[index];
                                        vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                    }
                                }
                            });
                        }

                        // TODO is this being used?

                        if (responseData !== undefined && responseData.populate_fields != 'undefined') {
                            angular.forEach(responseData.populate_fields, function (value, index) {
                                vm.dataToAdd[index] = value;
                                vm.scanStationObj.dataToAdd[index] = value;
                                if (vm.scanStationFieldSlugsToField[index] != null && vm.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                    let field = vm.scanStationFieldSlugsToField[index];
                                    vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                }
                            });
                        }

                        if (responseData !== undefined && responseData.populate_fields != 'undefined') {

                            // TODO is this being used? We dont send back field slugs anymore
                            angular.forEach(res.data.responseData.populate_fields, function (value, index) {
                                if (value != null && value != '') {
                                    vm.dataToAdd[index] = value;
                                    vm.scanStationObj.dataToAdd[index] = value;
                                    if (vm.scanStationFieldSlugsToField[index] != null && vm.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                        let field = vm.scanStationFieldSlugsToField[index];
                                        vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                    }
                                }
                                if (typeof (vm.scanStationObj.dataToAdd[index]) != 'undefined') {
                                    if (value != null && value != '') {
                                        vm.dataToAdd[index] = value;
                                        vm.scanStationObj.dataToAdd[index] = value;
                                        if (vm.scanStationFieldSlugsToField[index] != null && vm.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                            let field = vm.scanStationFieldSlugsToField[index];
                                            vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                        }
                                    }
                                } else {
                                    angular.forEach(vm.scanStationObj.scanStationFields, function (fieldValue) {
                                        if (fieldValue.field_slug == index) {
                                            vm.hiddenDataToPopulate[index] = value;
                                            if (value != null && value != '') {
                                                vm.dataToAdd[index] = value;
                                                vm.scanStationObj.dataToAdd[index] = value;
                                                if (vm.scanStationFieldSlugsToField[index] != null && vm.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                                    let field = vm.scanStationFieldSlugsToField[index];
                                                    vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                                }
                                            }
                                        }

                                    });
                                }
                            });
                        }

                        if (failedResponseType !== null) {
                            if (failedResponseType == 'Red Warning Reject') {
                                vm.prompt = res.data.error;
                                await vm.triggerPrompt(index);
                            } else {
                                if (failedResponseType == 'Yellow Warning Accept') {
                                    vm.yellowPrompt = res.data.error;
                                    await vm.triggerYellowPrompt(index, true);
                                }
                            }
                        }

                    }
                }
            }

            $scope.$apply();
            return true;


        } catch (e) {
            console.log(e);
        }
    };

    vm.triggerPrompt = async function (index) {
        try {
            let fieldIndex = Number.isInteger(index) ? '#station-field-' + index : '#' + index;

            vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFields[index].field_slug] = '';
            $(fieldIndex).val('');
            $(fieldIndex).trigger("focus");
            $(fieldIndex).addClass('rule-broken');

            await vm.playSound('error');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.triggerYellowPrompt = async function (index, confirmSubmit) {
        try
        {
            $('#station-field-' + index).trigger("focus");
            $('#station-field-' + index).addClass('rule-broken');
            await vm.playSound('warning');
            vm.confirmSubmit = confirmSubmit;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.playSound = async function (sound) {
        try {
            let snd = new Audio();
            if (sound == 'error') {
                snd = new Audio("/resources/error.mp3");
            }
            if (sound == 'warning') {
                snd = new Audio("/resources/warning-sound.mp3");
            }
            if (sound == 'scan') {
                snd = new Audio("/resources/scan.mp3");
            }

            await snd.play();

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.filterStatus = function (item) {
        return item === 'red' || item === 'blue';
    };

    vm.checkLastThenSubmit = async function (e, scanStationId, fieldId) {
        try {

            if (typeof(vm.scanStationFieldIdsToField[fieldId].submitDataField) != 'undefined' && vm.scanStationFieldIdsToField[fieldId].submitDataField == 1) {
                await vm.checkAllRulesThenAddData(vm.scanStationObj.dataToAdd, scanStationId);
                $scope.$apply();
                return;
            }

            let current_input_id = '';
            if (e != false) {
                if (typeof (e.target) != 'undefined') {
                    current_input_id = e.target.id;
                }
            }

            if (current_input_id !== '') {


                // The new grid case
                if (typeof ($('#' + current_input_id).hasClass('lastfield'))) {
                    // check if the element with  class .lastfield is the same as the current
                    if ($('.lastfield').attr('id') === current_input_id) {
                        await vm.checkAllRulesThenAddData(vm.scanStationObj.dataToAdd, scanStationId);
                    }
                }
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getDataForDataFiltering = async function (queryType, dataFilteringData = null) {
        try {

            // Making sure that we have the live data removed before any filtering, so we can see the update of the data later on
            // sometimes we want to keep this, e.g. for sockets and fully live data
            // we will acknowledge this in the docs
            if (vm.scanStationObj.stationDetails.getLiveDataWithoutRefreshing != 1) {
                vm.scanStationObj.liveData = []
            }

            if (dataFilteringData == null) {
                dataFilteringData = {};
            }

            dataFilteringData.stationID = vm.scanStationObj.id;
            dataFilteringData.jobID = vm.scanStationObj.jobID;

            if (queryType === 'all') {

                if (dataFilteringData.filterDataToQuery == null) {
                    dataFilteringData.filterDataToQuery = {};
                }
            }

            if (dataFilteringData.currentPage == null) {
                dataFilteringData.currentPage = vm.currentPage;
            }

            dataFilteringData.resultLimit = vm.resultLimit;

            dataFilteringData.reverse = vm.reverseData;

            vm.dataFilteringData = dataFilteringData;

            // we know if the setting is "all" then this has been set, otherwise it must exist elsewhere, if it doesnt
            // something has gone wrong so we dont do the filter
            if (dataFilteringData.filterDataToQuery == null || $.isEmptyObject(dataFilteringData.filterDataToQuery)) {
                await vm.getData(vm.scanStationObj.id);
                $scope.$apply();
                return false;
            }

            let data = await Data.getDataForDataFiltering(dataFilteringData, 'fieldSlug');

            if (queryType === 'all') {
                Data.updateEnterDataController(data.data, 'originalDataForDataFiltering');

            } else if (queryType === 'formception') {
                Data.updateEnterDataController(data.data, 'originalDataForDataFiltering');
                vm.scanStationObj.liveData = data.data.data;
                vm.upperLimit = data.data.data.countLimitInfo.scans_counted;
            } else {
                vm.scanStationObj.liveData = data.data.data;
                vm.upperLimit = data.data.data.countLimitInfo.scans_counted;
            }

            if (isEmpty(dataFilteringData.filterDataToQuery)) {
                // TODO: check to see if the broadcast is working fine or will need an await
                Data.updateEnterDataController(data.data, 'originalDataForDataFiltering');
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    const isEmpty = inputObject => {
        if (inputObject == null) {
            return false;
        }

        return Object.keys(inputObject).length === 0;
    };

    vm.getData = async function (scanStationID) {
        try {
            if (typeof (vm.resultLimit) == 'undefined') {
                vm.resultLimit = 10;
            }
            if (typeof (vm.currentPage) == 'undefined') {
                vm.currentPage = 1;
            }
            if (typeof (vm.reverseData) == 'undefined') {
                vm.reverseData = false;
            }

            // if we already have field sorting then we will no longer use the default one
            // if we dont we will initialise the default one
            if (vm.scanStationObj.stationDetails.sortByFieldId != null && vm.sortByFieldId == null) {
                vm.sortByFieldId = vm.scanStationObj.stationDetails.sortByFieldId;
            }

            // we clear the data, unless we specify otherwise
            if (vm.scanStationObj.stationDetails.getLiveDataWithoutRefreshing != 1) {
                vm.scanStationObj.liveData = {};
            }

            if (typeof (vm.includeCountsAndChecksTime) == 'undefined') {
                vm.includeCountsAndChecksTime = 'all-data';
            }
            vm.entryCycleKey = vm.dataToAdd[vm.entryCycleKeySlug];
            vm.scanStationObj.entryCycleKey = vm.scanStationObj.dataToAdd[vm.scanStationObj.entryCycleKeySlug];

            let data = await scanStation.getData(scanStationID, vm.scanStationObj.entryCycleKey, vm.includeCountsAndChecksTime,
                vm.scanStationObj.jobID, vm.scanStationObj.stateName, vm.resultLimit, vm.currentPage,
                vm.reverseData, vm.fromDate, vm.toDate, vm.calendarPrimaryFieldFormat, vm.sortByFieldId);

            vm.fromDate = null;
            vm.toDate = null;
            if (typeof (data) != 'undefined') {

                vm.scanStationObj.liveData = data.data.data;

                $scope.$apply();

                // Adding this conditional as it request to load on the first getData
                if (data.data.data.countLimitInfo !== undefined) {
                    vm.upperLimit = data.data.data.countLimitInfo.scans_counted;
                }
            }

            await vm.updateDropDownsRadiosAutoCompletesForAllFields();

            if (vm.scanStationObj.hasTotalsField) {
                await vm.getTotalsResults();
            }

            vm.savingData = false;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.updateDropDownsRadiosAutoCompletesForAllFields = async function () {
        angular.forEach(vm.scanStationObj.scanStationFields, async function (field) {

            if (field.field_type == 'dropdown-from-station' || field.field_type == 'radio-buttons-from-station'
                || field.field_type == 'autocomplete-field' || field.field_type == 'checkbox-buttons-from-station' || field.autocomplete == 1) {
                if (field.dropdownFromStationLookUp == null) {
                    field.dropdownFromStationLookUp = {
                        stationId: vm.id,
                        fieldIdToLookUp: field.id
                    };
                }

                angular.forEach(field.localFieldMatchingFieldPairs, function (fieldPair) {
                    fieldPair.localFieldValue = vm.scanStationObj.dataToAdd[vm.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                });

                // this function will be cleaning the local field value, as this was triggering again things after finish the insert/edit
                //vm.cleanLocalFieldMatchingFieldPairs(field.localFieldMatchingFieldPairs);

                await vm.getStationDataForDropDown(field.field_slug, field.dropdownFromStationLookUp, field.dropdownFromStationOnlyDataAfterDate, field.localFieldMatchingFieldPairs, field.dropdownFromStationReverseOrder, field.dropDownFromStationExcludeMatchingDataFromAnotherFieldData, field.localFieldExclusionMatchingFieldPairsArray);

            }
        });
    }

    vm.getCalendarData = async function (scanStationID) {
        try {
            vm.calendarData = {};
            let data = await scanStation.getCalendarData(scanStationID, vm.scanStationObj.jobID,
                vm.scanStationObj.stateName, vm.calendarFromDate, vm.calendarToDate, vm.calendarPrimaryFieldFormat);

            if (typeof (data) != 'undefined') {
                vm.calendarData = data.data.data;
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };


    vm.loadMoreData = function () {
        alert('This feature has been discontinued, please click the X button on the top of the entry form to see'
            + ' the timestamps and user id');
    };

    vm.getJobScanStations = async function (sectionID, jobID = null) {
        try {
            if (jobID == null) {
                jobID = vm.scanStationObj.jobID;
            }

            let data = await scanStation.getJobScanStations(jobID, sectionID);
            vm.jobScanStations = data.data.data;
            vm.jobSections = data.data.sections;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getExternalJobsByKeyWord = async function (keyword, secondInstanceOfDirective = false) {
        try {
            if (keyword.length >= 3) {
                let data = await scanStation.getExternalJobsByKeyWord(keyword);
                // TODO remove secondInstanceOfDirective once component to handle multiple of them is complete
                if (secondInstanceOfDirective == false) {
                    vm.externalJobs = data.data.data;
                } else {
                    vm.externalJobsTwo = data.data.data;
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.getSections = async function () {
        try {
            let data = await scanStation.getSections(vm.scanStationObj.jobID);
            vm.jobSections = data.data.sections;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getStationStationFieldsForRule = async function (id) {
        try {
            vm.scanStationFieldDesignOptions = []
            let data = await scanStation.getScanStationData(id, vm.scanStationObj.stateName);
            vm.scanStationFieldsForRule = data.data.scanStationFields;

            angular.forEach(vm.scanStationFieldsForRule, function (value) {
                vm.scanStationFieldDesignOptions[value.id] = {};
                vm.scanStationFieldDesignOptions[value.id].fieldNextLine = value.field_next_line;
                vm.scanStationFieldDesignOptions[value.id].fieldWidth = value.field_width;
            });

            if (vm.fieldIDForUpdatingRules !== undefined
                && vm.scanStationFieldDesignOptions[vm.fieldIDForUpdatingRules] !== undefined) {
                vm.fieldNextLine = vm.scanStationFieldDesignOptions[vm.fieldIDForUpdatingRules].fieldNextLine;
                vm.fieldWidth = vm.scanStationFieldDesignOptions[vm.fieldIDForUpdatingRules].fieldWidth;
            }

            $scope.$apply();

        } catch (e) {
            console.log(e)
        }

    };

    vm.undo = async function () {
        try {
            await scanStation.undo(vm.scanStationObj.id);
            vm.showUndo = false;
            await vm.getData(vm.scanStationObj.id);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getStationUsers = async function (scanStationID) {
        try {
            let data = await scanStation.getStationUsers(scanStationID);
            vm.stationUsers = data.data.data;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.addStationUser = async function (jobID, userID) {
        try {
            vm.updating = true;
            await scanStation.addStationUser(vm.scanStationObj.id, jobID, userID);
            await vm.getStationUsers(vm.id);
            vm.updating = false;

            $scope.$apply();

        } catch (res) {

            vm.updating = false;
            if (typeof (res.data.error) != 'undefined') {
                vm.prompt = res.data.error;
            } else {
                vm.prompt = 'Error: Unknown';
            }

            alert('Error, please check browser console to read error.');
            console.log(res);
        }

    };

    vm.deleteUserFromStation = async function (stationUserID) {
        try {
            vm.updating = true;
            await scanStation.deleteUserFromStation(stationUserID);
            await vm.getStationUsers(vm.scanStationObj.id);
            vm.updating = false;

            $scope.$apply();

        } catch (e) {
            console.log(e);
            vm.updating = false;
            alert('Error:' + JSON.stringify(e));
        }

    };

    vm.getSections = async function () {
        try {
            let data = await scanStation.getSections(vm.jobID);
            vm.sections = data.data.data;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.updateStationSetting = async function (setting, value) {
        try {

            // Adding validation of the value, needs to have a value to be able to update the station setting
            if (value !== undefined && value !== null) {
                vm.updating = true;
                await scanStation.updateStationSetting(vm.id, setting, value);
                vm.updating = false;
                await vm.triggerBlocUpdateSuccess('Bloc Setting Applied');
                $scope.$apply();
            }

        } catch (e) {
            console.log(e);
            vm.updating = false;
        }

    };

    vm.importDataFromExcel = async function () {
        try {
            let excelDataFile = vm.excelData;
            vm.updating = true;
            let data = await scanStation.importDataFromExcel(vm.scanStationObj.id, vm.scanStationObj.jobID, vm.importDuplicateOptions, excelDataFile); // add importDuplicateOptions Here
            alert('Data uploaded successfully - ' + data.data.success);
            vm.updating = false;
            await vm.getData(vm.scanStationObj.id);

            $scope.$apply();

        } catch (e) {
            console.log(e);
            alert('Something went wrong, please check the browser console to see the error');
            vm.updating = false;
            $scope.$apply();
        }

    };

    vm.updateDataFromStation = async function (rowID, key, value) {
        try {
            vm.updatingEntryData = true;
            await scanStation.updateDataFromStation(rowID, key, value);
            vm.updatingEntryData = false;

            $scope.$apply();

        } catch (e) {
            console.log(e);
            vm.updatingEntryData = false;
        }

    };

    vm.getAntiStaticSignedForUser = async function () {
        try {
            let data = await scanStation.getAntiStaticSignedForUser(vm.scanStationObj.jobID, vm.userID);
            vm.antiStaticSignedForUser = data.data.digitalSignature;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getSOPSignedForUser = async function () {
        try {
            let data = await scanStation.getSOPSignedForUser(vm.scanStationObj.jobID, vm.scanStationObj.id, vm.userID);
            vm.SOPSignedForUser = data.data.digitalSignature;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.signItemFrontEnd = async function (userID, password, itemToSign) {
        try {
            await scanStation.signItem(vm.scanStationObj.jobID, userID, password, itemToSign);
            if (itemToSign === 'anti_static_instructions') {
                await scanStation.logAntiStaticEntry(userID, password);
            }
            await vm.getAntiStaticSignedForUser();
            await vm.getSOPSignedForUser();

            $scope.$apply();

        } catch (e) {
            console.log(e);
            alert('Incorrect Password');
        }

    };

    vm.onGetUserMediaButtonClick = async function () {
        try {
            vm.takePhotoStatus = 'open';

            // Grab elements, create settings, etc.
            let video = <HTMLVideoElement>document.getElementById('video');

            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                // Not adding `{ audio: true }` since we only want video now
                let stream = await navigator.mediaDevices.getUserMedia({video: true})
                video.srcObject = stream;
                await video.play();
                $scope.$apply();
            }


        } catch (e) {
            console.log(e);
        }

    };

    vm.onTakePhotoButtonClick = function () {
        try {
            // Elements for taking the snapshot
            let canvas = <HTMLCanvasElement>document.getElementById('canvas');
            let context = canvas.getContext('2d');
            let video = <HTMLVideoElement>document.getElementById('video');

            context.drawImage(video, 0, 0);
            let image = new Image();
            image.src = canvas.toDataURL("image/jpeg");
            $('#photo-preview-' + vm.fileFieldSlugSelected).attr('src', image.src);
            vm.takePhotoStatus = 'closed';
            vm.filesToAdd[vm.fileFieldSlugSelected] = image.src;
            vm.scanStationObj.filesToAdd[vm.fileFieldSlugSelected] = image.src;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.scrollTop = function () {
        try {
            $('html, body').animate({
                scrollTop: "0px"
            }, 800);

        } catch (e) {
            console.log(e);
        }

    };

    vm.checkCanEditStation = async function () {
        try {
            await scanStation.checkCanEditStation(vm.scanStationObj.id);
            $scope.$apply();
            vm.canEditStation = true;

        } catch (e) {
            console.log(e);
            vm.canEditStation = false;
        }

    };

    vm.createNewRevisionOfStation = async function () {
        try {
            let data = await scanStation.createNewRevisionOfStation(vm.scanStationObj.id);
            alert('New Revision has been successfully created, click Ok and you will be redirected.');
            $state.go('edit-bloc', {
                id: vm.scanStationObj.jobID,
                stationId: data.data.newStationID
            });
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.duplicateStationFromWithinStation = async function () {
        try {
            let data = await scanStation.duplicateStationFromWithinStation(vm.id, vm.replicateFieldRoles, vm.replicateUserAccess, vm.replicateRules);
            alert('Bloc Duplication has been successful. '
                + 'You you will now be redirected to the edit page of the new bloc.');

            $state.go('edit-bloc', {
                id: vm.scanStationObj.jobID,
                stationId: data.data.newStationID
            });

        } catch (e) {
            console.log(e);
        }

    };

    vm.updateBWQuantityLimits = async function () {
        try {
            await scanStation.updateBWQuantityLimits(vm.quantityLimitUpdates);
            alert('Data Updated Successfully');
            await vm.getData(vm.scanStationObj.id);
            vm.showEditPopUp = false;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.triggerClickByFieldID = function (fieldID) {
        try {
            $('input[data-field-id="' + fieldID + '"]').triggerHandler("focus");
            // $timeout(function () {
            //     $('input[data-field-id="' + fieldID + '"]').triggerHandler( "focus" );
            // });

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.triggerClickBySpecialFieldKey = function (specialFieldKey) {
        try {
            $('input[data-special-field-key="' + specialFieldKey + '"]').triggerHandler("focus");
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    };

    vm.fetchHeaterNumbersToRemove = async function (e, useKeys) {
        try {
            if ((typeof (e.keyCode) != 'undefined' && (e.keyCode === 13 || e.keyCode === 9)) || useKeys === false) {
                let data = await scanStation.fetchHeaterNumbersToRemove(vm.heaterNumber, vm.boxNumber);
                vm.heaterNumbersToDelete = data.data.data;
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.deleteHeater = async function (id) {
        try {
            let e = false;
            await scanStation.deleteHeater(id);
            await vm.fetchHeaterNumbersToRemove(e, false);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.addSelectedRoleForFieldID = async function (selectedRoleSlug, fieldID) {
        try {
            await scanStation.addSelectedRoleForFieldID(selectedRoleSlug, fieldID);
            await vm.getSelectedRolesForFieldID(fieldID);
            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getSelectedRolesForFieldID = async function (fieldID) {
        try {
            let data = await scanStation.getSelectedRolesForFieldID(fieldID);
            vm.selectedRolesForFieldID = data.data.data;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeSelectedRoleForFieldID = async function (id, fieldID) {
        try {
            await scanStation.removeSelectedRoleForFieldID(id);
            await vm.getSelectedRolesForFieldID(fieldID);
            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getLastEntryByEntryCycleKey = async function () {
        try {
            let submittedStatus = vm.scanStationObj.stationDetails.uses_automatic_saving === 1 ? 0 : 1;
            let data = await scanStation.getLastEntryByEntryCycleKey(vm.scanStationObj.jobID, vm.scanStationObj.id,
                vm.scanStationObj.entryCycleKey, submittedStatus);

            vm.lastEntry = data.data.data;

            // specific field population takes priority over the option where all fields populate

            if (vm.scanStationObj.stationDetails.has_populate_latest_data_specific_fields == 1) {
                setTimeout(async function () {
                    console.log(vm.scanStationObj.liveData[0]);
                    angular.forEach(vm.scanStationObj.liveData[0], async function (value, index) {
                        if (vm.populateLatestDataFields.includes(index)) {
                            if (typeof value === 'string' || value instanceof String) {
                                value = value.replace("N/A", "");
                            }

                            vm.scanStationObj.dataToAdd[index] = value;
                            let field = vm.scanStationFieldSlugsToField[index];
                            await vm.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);

                            $scope.$apply();
                            // for the time field we need to set more variables
                            // TODO we may have to look at all other exceptions and do things by field classes
                            if (vm.scanStationFieldSlugsToField[index].field_type == 'date-time-selector') {
                                if (vm.scanStationFieldSlugsToField[index].dateTimeSetting == 'time') {
                                    setTimeout(async function () {
                                        vm.scanStationTimePickerFields[index] = moment(value, 'hh:mm:ss');
                                        vm.setSeconds(index);
                                        vm.updateTime(index);

                                        $scope.$apply();
                                    }, 2000);
                                }
                            }
                        }
                    });
                }, 2000);


            } else if (vm.lastEntry != null && typeof vm.fieldsToExcludeFromGetData != 'undefined') {
                angular.forEach(vm.lastEntry.entry_data, function (value, index) {
                    if (!vm.fieldsToExcludeFromGetData.includes(index)) {
                        if (typeof value === 'string' || value instanceof String) {
                            value = value.replace("N/A", "");
                        }
                        vm.scanStationObj.dataToAdd[index] = value;
                    }
                });
            } else {
                await vm.resetData(1);
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    vm.searchUsersByKey = async function (fieldSlug) {
        try {
            let data = await scanStation.searchUsersByKey(vm.scanStationObj.userSearchKey[fieldSlug]);
            if (typeof (vm.usersByKey) == 'undefined') {
                vm.usersByKey = [];
            }
            vm.usersByKey[fieldSlug] = data.data.data;
            vm.userResultsCount = vm.usersByKey.length;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.addUserToUserSelectData = function (email, fieldSlug) {
        try {
            if (!Array.isArray(vm.scanStationObj.dataToAdd[fieldSlug])) {
                vm.scanStationObj.dataToAdd[fieldSlug] = [];
            }

            vm.scanStationObj.dataToAdd[fieldSlug].push(email);
            vm.usersByKey = {};
            vm.userSearchKey = [];
            vm.scanStationObj.userSearchKey = [];

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeUserFromSelectUserList = function (userToDelete, fieldSlug) {
        try {
            angular.forEach(vm.scanStationObj.dataToAdd[fieldSlug], function (user, index) {
                if (user === userToDelete) {
                    vm.scanStationObj.dataToAdd[fieldSlug].splice(index, 1);
                }
            });

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.addDocumentSignTrigger = async function () {
        try {
            await scanStation.addDocumentSignTrigger(vm.newDocumentSignTrigger);
            await vm.getDocumentSignTriggers();
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.deleteDocumentSignTrigger = async function (id) {
        try {
            await scanStation.deleteDocumentSignTrigger(id);
            await vm.getDocumentSignTriggers();

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getDocumentSignTriggers = async function () {
        try {
            let data = await scanStation.getDocumentSignTriggers(vm.scanStationObj.id);
            vm.documentSignTriggers = data.data.data;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getDocumentSignTriggerData = async function (id) {
        try {
            let data = await scanStation.getDocumentSignTriggerData(id);
            vm.documentSignTriggerDataToUpdate = data.data.data;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getContent = function () {
        vm.tinymceModel = vm.fieldTypeToUpdateTo;
    };

    vm.addSelectedRoleForLoadingUsers = async function (roleDetails) {
        try {
            let RoleDetailsArray = roleDetails.split("|");

            // check if its already there
            vm.isInArray = false;
            angular.forEach(vm.selectedRolesForLoadingUsers, function (role) {
                if (role.id == RoleDetailsArray[0]) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.selectedRolesForLoadingUsers = vm.selectedRolesForLoadingUsers.concat([{
                    id: RoleDetailsArray[0],
                    title: RoleDetailsArray[1]
                }]);
            }

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.addStationUnit = async function (unit) {
        try {
            if (typeof (vm.stationUnits) == 'undefined' || vm.stationUnits == null) {
                vm.stationUnits = [];
            }

            vm.isInArray = false;
            angular.forEach(vm.stationUnits, function (value) {
                if (value == unit) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.stationUnits.push(unit);
            }

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeSelectedSOPFiles = async function (id) {
        try {
            angular.forEach(vm.selectedSOPFiles, function (file, index) {
                if (file.id == id) {
                    vm.selectedSOPFiles.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeSelectedRolesForLoadingUsers = async function (id) {
        try {
            angular.forEach(vm.selectedRolesForLoadingUsers, function (role, index) {
                if (role.id == id) {
                    vm.selectedRolesForLoadingUsers.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeStationUnit = async function (unit) {
        try {
            angular.forEach(vm.stationUnits, function (value, index) {
                if (value == unit) {
                    vm.stationUnits.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.reloadTinyMCEEditor = async function () {

        try {
            vm.tinyMceService.setTinyMCETemplate();
            let body = $('iframe').contents().find('#tinymce');

            if ($(body).hasClass('skin-setting-bright')) {
                $(body).addClass('skin-setting-dark').removeClass('skin-setting-bright')
            } else {
                $(body).addClass('skin-setting-bright').removeClass('skin-setting-dark')
            }

            $scope.$broadcast('$tinymce:refresh');

        } catch (e) {
            console.log(e);
        }

    }

    vm.showTinyMCEEditor = async function (source) {
        try {

            vm.tinyMceService.setTinyMCETemplate();

            if (source === 'edit') {
                vm.tinymceModel = vm.fieldDataToUpdateTo;
            }
            vm.scrollTo('wysiwyg-editor');
            vm.showTinyMCEEditorPopUp = true;
            vm.tinyMCEEditingSource = source;

        } catch (e) {
            console.log(e);
        }

    };

    vm.showTinyMCEEditorScanStationEdit = async function (source, htmlContent) {
        try {
            if (source === 'edit') {
                vm.tinymceModel = htmlContent;
            }
            vm.scrollTo('wysiwyg-editor');
            vm.showTinyMCEEditorPopUp = true;
            vm.tinyMCEEditingSource = source;

        } catch (e) {
            console.log(e);
        }

    };

    vm.scrollTo = function (tag, element = "html, body", usePosition = false) {
        try {
            let aTag = $("a[name='" + tag + "']");
            if (usePosition == false) {
                $(element).animate({scrollTop: aTag.offset().top - 150}, "slow");
            } else {
                $(element).animate({scrollTop: aTag[0].offsetTop - 150}, "slow");
            }

            return true;

        } catch (e) {
            console.log(e);
        }
    };

    vm.toTrusted = function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    };

    /**
     *
     * Gets the html of .scan-station-entry and creates a pdf from the html.
     *
     */
    vm.exportPDFFromStation = async function () {
        try {
            const scanStationHTML = angular.element('.scan-station-entry').html();

            let response = await scanStation.exportPDFFromStation(scanStationHTML, vm.exportToBlackAndWhite);
            const blob = new Blob([response.data], {
                type: 'application/octet-stream'
            });

            const URL = window.URL || window.webkitURL;
            vm.reportDownloadUrl = URL.createObjectURL(blob);

            const downloadButton = document.getElementById('downloadPdfBtn');
            downloadButton.click();

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.generatePublicFormURL = async function () {
        try {
            vm.publicFormValue = vm.scanStationObj.dataToAdd[vm.scanStationObj.stationDetails.public_form_field_slug];
            await vm.AddData(vm.scanStationObj.dataToAdd, vm.scanStationObj.id);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.displayDigitalSignature = function (fieldSlug, digitalSignaturePreview) {
        try {
            if (vm.scanStationObj.stateName === 'public-form' && digitalSignaturePreview === '') {
                vm.digitalSignatureButtons[fieldSlug] = true;
                alert('You must be logged in as a non public user to sign your digital signature.');
            } else {
                vm.scanStationObj.dataToAdd[fieldSlug] = digitalSignaturePreview;
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.dataNextPage = async function (nextPage) {
        try {
            vm.currentPage = await Data.dataNextPage(nextPage, vm.currentPage);

        } catch (e) {
            console.log(e);
        }
    };

    vm.dateTimeGetNow = function (format) {
        try {
            let currentDate = new Date();
            let result;
            let dateNow = currentDate.getFullYear() + '-' + ('0'
                + (currentDate.getMonth() + 1)).slice(-2) + '-' + ('0'
                + currentDate.getDate()).slice(-2);

            let minutes = (currentDate.getMinutes() < 10 ? '0' : '')
                + currentDate.getMinutes();

            let seconds = (currentDate.getSeconds() < 10 ? '0' : '')
                + currentDate.getSeconds();

            let hours = (currentDate.getHours() < 10 ? '0' : '')
                + currentDate.getHours();

            let timeNow = hours + ":" + minutes + ":"
                + seconds;

            switch (format) {
                case 'auto-generate-full-timestamp':
                    result = dateNow + " " + timeNow;
                    break;
                case 'auto-generate-date' :
                    result = dateNow;
                    break;
                case 'auto-generate-time':
                    result = timeNow;
                    break;
                case 'auto-generate-number-date':
                    result = dateNow;
                    break;
                case 'auto-generate-number-text-date':
                    result = dateNow;
                    break;
            }
            return result;

        } catch (e) {
            console.log(e);
        }

    };

    vm.createCellColour = async function (name, colour) {
        try {
            let colourScheme = <ColourScheme>{};
            colourScheme.id = vm.scanStationObj.id;
            if (name !== undefined || colour !== undefined) {
                colourScheme.name = name;
                colourScheme.colour = colour;
                await scanStation.addColourScheme(colourScheme);
                await vm.getColourSchemes();
                alert('Coloured cell created')

            } else {
                alert('Must not be empty')
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.deleteCellColour = async function (name) {
        try {
            if (name !== undefined) {
                await scanStation.deleteColourScheme(name, vm.scanStationObj.id);
                await vm.getColourSchemes();
            } else {
                alert('Cannot delete empty')
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.getColourSchemes = async function () {
        try {
            vm.colourSchemeArray = {};

            let data = await scanStation.getColourSchemes(vm.scanStationObj.id);

            angular.forEach(JSON.parse(data.data.data), function (value, index) {
                vm.colourSchemeArray[index] = (value.data);
            });

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.createAlert = async function (alertData, dateTimeStamp) {
        try {
            alertData.dateTimeStamp = dateTimeStamp;
            alertData.title = vm.alertDataTitle + '-' + alertData.dateTimeStamp;
            alertData.recurring = 0;
            alertData.alertType = 'standard';
            alertData.frequency = 'N/A';
            alertData.recipients = 'Custom';
            alertData.hour = '4:00';

            angular.forEach(alertData.customRecipients.split(','), function (value) {
                let validEmail = User.validateEmail(value);
                if (!validEmail) {
                    alert('Sorry invalid email: ' + value);
                    InvalidEmailExceptionFactory.throwException(value);
                }
            });

            let data = await Data.addAlert(alertData);
            alert(data.data.success);

            $scope.$apply();

        } catch (exception) {
            if (exception.constructor.name === 'InvalidEmailException') {
                return;
            }
            console.log(exception);
        }

    };

    function autoGenerateDateFormat(date, dateFormat) {
        try {
            let formattedDate = '';
            if (dateFormat === 'YYYYMMDD') {
                let splitDate = date.split('-');
                angular.forEach(splitDate, function (value) {
                    formattedDate += value;
                });

                return formattedDate;

            } else if (dateFormat === 'DD/MM/YYYY') {

                let splitDate = date.split('-').reverse();
                angular.forEach(splitDate, function (value, index) {
                    if (index !== 0) {
                        formattedDate += '/' + value;
                    } else {
                        formattedDate += value;
                    }
                });

                return formattedDate;

            } else if (dateFormat === 'DD.MM.YYYY') {

                let splitDate = date.split('-').reverse();
                angular.forEach(splitDate, function (value, index) {
                    if (index !== 0) {
                        formattedDate += '.' + value;
                    } else {
                        formattedDate += value;
                    }
                });

                return formattedDate;

            } else if (dateFormat === 'Single Week - DD/MM/YYY - DD/MM/YYYY') {

                let momentDate = moment(date); //saturday, the next one
                let weekNumber = Math.ceil(momentDate.date() / 7);
                let check = moment(date, 'YYYY-MM-DD');

                let month = check.format('MM');
                let year = check.format('YYYY');

                formattedDate = moment(date).startOf('isoWeek').format('DD/MM/YYYY') + ' - '
                    + moment(date).endOf('isoWeek').format('DD/MM/YYYY');

                return formattedDate;

            } else if (dateFormat === 'day-month-00th-year') {

                formattedDate = moment(date).format('dddd, MMMM Do YYYY');

                return formattedDate;

            } else {
                formattedDate = date;
            }

            return formattedDate;

        } catch (e) {
            console.log(e);
        }

    }


    vm.getAutoGenerateFieldData = async function (field, fieldType, fieldSlug) {
        try {
            let autoGenerateData = <AutoGenerateData>{};
            let referenceFieldData = '';
            let date;
            let data;

            if (fieldType.includes('number')) {
                autoGenerateData.digitLength = field.digitLength;
                autoGenerateData.startingNumber = field.startingNumber;
                if (field.referenceField != null) {
                    autoGenerateData.referenceField = vm.scanStationFieldIdsToField[field.referenceField].field_slug;
                    autoGenerateData.referenceFieldData = vm.scanStationObj.dataToAdd[autoGenerateData.referenceField];
                }
            }

            if (fieldType.includes('date') || fieldType.includes('time')) {
                date = vm.dateTimeGetNow(fieldType);
            }

            switch (fieldType) {
                case 'auto-generate-full-timestamp':
                    vm.scanStationObj.dataToAdd[fieldSlug] = date;
                    break;
                case 'auto-generate-time':

                    vm.scanStationObj.dataToAdd[fieldSlug] = date;
                    break;
                case 'auto-generate-date':

                    vm.scanStationObj.dataToAdd[fieldSlug] = autoGenerateDateFormat(date, field.dateFormat);
                    break;
                case 'auto-generate-number':
                    data = await scanStation.getAutoGeneratedFieldData(autoGenerateData, fieldType, vm.scanStationObj.id, fieldSlug);
                    vm.scanStationObj.dataToAdd[fieldSlug] = data.data.data;
                    $scope.$apply();
                    break;
                case 'auto-generate-number-text':
                    data = await scanStation.getAutoGeneratedFieldData(autoGenerateData, fieldType, vm.scanStationObj.id, fieldSlug);
                    vm.scanStationObj.dataToAdd[fieldSlug] = field.autoGenerateStartingText + data.data.data;
                    $scope.$apply();

                    break;
                case 'auto-generate-number-date':
                    date = autoGenerateDateFormat(date, field.dateFormat);
                    autoGenerateData.date = date;
                    data = await scanStation.getAutoGeneratedFieldData(autoGenerateData, fieldType, vm.scanStationObj.id, fieldSlug);
                    vm.scanStationObj.dataToAdd[fieldSlug] = date + data.data.data;
                    $scope.$apply();
                    break;
                case 'auto-generate-number-text-date':
                    date = autoGenerateDateFormat(date, field.dateFormat);
                    autoGenerateData.date = date;
                    autoGenerateData.text = field.autoGenerateStartingText;
                    data = await scanStation.getAutoGeneratedFieldData(autoGenerateData, fieldType, vm.scanStationObj.id, fieldSlug);
                    vm.scanStationObj.dataToAdd[fieldSlug] = field.autoGenerateStartingText + date + data.data.data;
                    $scope.$apply();
                    break;
            }

            /*vm.scanStationObj.dataToAdd[fieldSlug] = await vm.validateAutoGeneratedNumber(vm.scanStationObj.dataToAdd[fieldSlug],
                vm.scanStationObj.id);*/


        } catch (e) {
            console.log(e);
        }

    };

    vm.validateAutoGeneratedNumber = async (autogeneratedNumber = null, scanStationId) => {

        // getting the current number stored into the table autogenerate_queue
        let queuedNumber = await scanStation.getCurrentScanStationAutoGeneratedNumberAvailable(scanStationId);

        // if we cant find any number, we must save it and use it as new one
        if (queuedNumber === false) {
            queuedNumber = autogeneratedNumber;
            await scanStation.setCurrentScanStationAutoGeneratedNumberAvailable(scanStationId, queuedNumber);
            return queuedNumber;
        }

        let captured = /(?<string>[a-zA-Z]+)(?<number>\d+)/.exec(queuedNumber);
        let {string, number} = captured.groups;
        let iterableNumber = (parseInt(number) + 1).toString();
        queuedNumber = string + iterableNumber.padStart(number.length, '0');
        await scanStation.setCurrentScanStationAutoGeneratedNumberAvailable(scanStationId, queuedNumber);
        return queuedNumber;

    }

    vm.setFieldsEditEntryApply = async function (currentRow, rowIndex) {
        angular.forEach(currentRow, function (rowValue, key: any) {
            vm.scanStationObj.dataToAdd[key] = vm.scanStationObj.liveData[rowIndex][key];

            if (vm.scanStationFieldSlugsToField[key] != null && vm.scanStationFieldSlugsToField[key].field_type == 'checkbox-buttons-from-station') {
                vm.scanStationObj.dataToAdd[key] = vm.scanStationObj.liveData[rowIndex][key + '_blcwrx_checkbox_object'];
            }

            if (key === 'user_id') {
                vm.scanStationObj.dataToAdd[key] = $window.localStorage.userID;
            }

            if (key === 'user_id') {
                vm.scanStationObj.dataToAdd[key] = $window.localStorage.userID;
            }

            if (key === 'cartolytics_entry_row_id') {
                vm.scanStationObj.stationDataID = vm.scanStationObj.liveData[rowIndex][key];
            }

            if (vm.scanStationFieldSlugsToField[key] != null && vm.scanStationFieldSlugsToField[key].plainDataPermanentValueNeverChanges == 1) {
                let field = vm.scanStationFieldSlugsToField[key];
                vm.scanStationObj.dataToAdd[key] = vm.scanStationFieldSlugsToField[key].plainDataFieldValue;
                vm.getRules('no-event', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
            }

            if (vm.scanStationFieldSlugsToField[key] != null && vm.scanStationFieldSlugsToField[key].forceRuleTriggerAfterClickingEdit == 1) {
                let field = vm.scanStationFieldSlugsToField[key];
                console.log(vm.scanStationObj.dataToAdd[field.field_slug])
                vm.getRules('no-event', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
            }
        });
    }

    vm.setFieldsEditEntry = async function (currentRow, rowIndex) {
        try {
            vm.scrollTop();
            await vm.setFieldsEditEntryApply(currentRow, rowIndex);
            await vm.setFieldsEditEntryApply(currentRow, rowIndex);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.formatToCharacterLimit = function (result, charLimit = null) {
        if (charLimit !== null) {
            return result.substring(0, charLimit) + '...';
        } else {
            return result;
        }
    };

    vm.checkIfLoadMore = function () {
        try {
            angular.forEach(vm.scanStationObj.scanStationFields, function (data) {
                if (data.result_character_count != null || data.hide_from_get_data_list === 1) {
                    return vm.showLoadMoreData = true;
                }
            });

            // $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * TODO: Check this function, sounds weird that a set just change the value of the vm.showMoreSingleDataBox
     */
    vm.setShowMoreSingleDataBox = function () {
        vm.showMoreSingleDataBox = !vm.showMoreSingleDataBox;
    };

    vm.checkIfPageButtonClickable = async function (direction) {
        try {
            if (direction === 'forward') {
                if (((vm.currentPage * vm.resultLimit) < vm.upperLimit)) {

                    await vm.dataNextPage(1);

                    // TODO: check if is necessary await for: $rootScope.$broadcast(msg);
                    Data.updateEnterDataController((vm.currentPage), 'updatePageNumber');

                    if (vm.filterDataOptions != null) {
                        vm.filterDataOptions.currentPage = vm.currentPage;
                        vm.filterDataOptions.resultLimit = vm.resultLimit;
                    }

                    if (vm.dataFilteringData.filterDataToQuery != null && Object.keys(vm.dataFilteringData.filterDataToQuery).length > 0) {
                        await vm.getDataForDataFiltering('with-filter', vm.filterDataOptions);
                    } else {
                        await vm.getData(vm.scanStationObj.id);
                    }

                } else {
                    return false;
                }
            } else if (direction === 'backward') {

                if (vm.currentPage != 1) {
                    await vm.dataNextPage(0);

                    // TODO: check if is necessary await for: $rootScope.$broadcast(msg);
                    Data.updateEnterDataController(vm.currentPage, 'updatePageNumber');

                    if (vm.filterDataOptions != null) {
                        vm.filterDataOptions.currentPage = vm.currentPage;
                        vm.filterDataOptions.resultLimit = vm.resultLimit;
                    }

                    if (vm.dataFilteringData.filterDataToQuery != null && Object.keys(vm.dataFilteringData.filterDataToQuery).length > 0) {
                        await vm.getDataForDataFiltering('with-filter', vm.filterDataOptions);
                    } else {
                        await vm.getData(vm.scanStationObj.id);
                    }
                } else {
                    return false;
                }
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.saveScanStation = async function (id) {
        try {
            if (vm.showUndo === true) {
                vm.showUndo = false;
            }

            if (vm.showLoadMoreData === true) {
                vm.showLoadMoreData = false;
            }

            if (vm.prompt !== false) {
                vm.prompt = false;
            }

            const scanStationObyCopy = angular.copy(vm.scanStationObj);
            await scanStation.saveScanStation(scanStationObyCopy);
            await vm.getScanStationData(id, 'main-query');
            vm.scanStationObj.id = id;
            await vm.getData(vm.scanStationObj.id);
            await vm.getScanStationRules(id);
            vm.scanStationObj.isInEditMode = false;
            vm.scanStationObj.stationDataID = null;

            vm.scanStationObj.dataToAdd[vm.formceptionChildField] = scanStationObyCopy.dataToAdd[vm.formceptionParentField];

            let fieldToCheckRulesFor = null;
            angular.forEach(vm.scanStationObj.scanStationFields, function (value) {
                if (value.field_slug == vm.formceptionChildField) {
                    fieldToCheckRulesFor = value;
                }
            });

            if (fieldToCheckRulesFor != null) {
                await vm.getRules('no-event', fieldToCheckRulesFor.id, fieldToCheckRulesFor.special_field_key,
                    scanStationObyCopy.dataToAdd[vm.formceptionParentField], 0, vm.formceptionChildField);
            }

            await vm.getDataForDataFiltering('all', vm.filterDataOptions);
            if (vm.scanStationObj.hasTotalsField) {
                await vm.getTotalsResults();
            }

        } catch (e) {
            console.log(e);
        }

    };


    vm.addFilterData = function (fieldSlug, fieldData) {

        try {
            if (typeof (vm.dataFilteringData.resultLimit) == 'undefined') {
                vm.dataFilteringData.resultLimit = 10;
            }

            if (typeof (vm.dataFilteringData.filterDataToQuery) == 'undefined') {
                vm.dataFilteringData.filterDataToQuery = {}
            }
            if (typeof (vm.dataFilteringData.filterDataToQuery[fieldSlug]) == 'undefined') {
                vm.dataFilteringData.filterDataToQuery[fieldSlug] = [];
            }
            if (fieldData === "ignore") {
                angular.forEach(vm.dataFilteringData.filterDataToQuery, function (value, index) {
                    if (index === fieldSlug) {
                        delete vm.dataFilteringData.filterDataToQuery[index];
                    }
                });
            } else {
                vm.dataFilteringData.filterDataToQuery[fieldSlug].push(fieldData);
            }

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeFilter = function (fieldSlug, fieldData, fieldKey) {
        try {
            angular.forEach(vm.dataFilteringData.filterDataToQuery[fieldSlug], function (value, index) {
                if (fieldData === value) {
                    vm.dataFilteringData.filterDataToQuery[fieldSlug].splice(index, 1);
                    if (vm.dataFilteringData.filterDataToQuery[fieldSlug].length <= 0) {

                        delete vm.dataFilteringData.filterDataToQuery[fieldSlug];

                        if (vm.dataFilteringSelect == null) {
                            vm.dataFilteringSelect = {};
                        }

                        vm.dataFilteringSelect[fieldKey] = 'ignore';
                    }
                }
            });

            // now we need to check if we are finished with vm.specificFieldSearch or not, if there is only blocworx_free_search left
            // then we can turn vm.specificFieldSearch off
            let specificFieldSearch = false;
            angular.forEach(vm.dataFilteringData.filterDataToQuery, function (filter, key) {
                let keyString = key.toString();
                if (keyString != 'blocworx_free_search') {
                    specificFieldSearch = true;
                }
            });

            if (specificFieldSearch == false) {
                vm.specificFieldSearch = false;
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.returnScanStation = async function () {
        try {

            let result = scanStation.returnScanStation();
            vm.scanStationObj = result[0];
            await vm.getScanStationData(vm.scanStationObj.id, 'main-query');
            await vm.getData(vm.scanStationObj.id);

            await vm.getDataForDataFiltering('all', vm.filterDataOptions);
            await vm.getScanStationRules(vm.scanStationObj.id);

            if (result[1] === true) {
                vm.showFormceptionPopup = false;
            }

            if (vm.showUndo === true) {
                vm.showUndo = false;
            }

            if (vm.prompt !== false) {
                vm.prompt = false;
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    vm.checkForOtherForm = async function (scanStationID) {
        try {
            if (vm.showFormceptionPopup === true) {
                await vm.saveScanStation(scanStationID);
            }
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.setForNewForm = function (fieldData) {
        try {
            fieldData = fieldData.split(',');
            vm.formceptionID = fieldData[0];
            vm.formceptionChildField = fieldData[1];
            vm.formceptionParentField = fieldData[2];

            let formceptionData = {
                formceptionID: fieldData[0],
                formceptionChildField: fieldData[1],
                formceptionParentField: fieldData[2]
            };

            if (vm.scanStationObj.formceptionData.length <= 0) {
                vm.scanStationObj.formceptionData.push(formceptionData);
            } else {
                let pushed = false;
                angular.forEach(vm.scanStationObj.formceptionData, function (formception) {
                    if ((formceptionData['formceptionID'] !== formception['formceptionID']) && pushed === false) {
                        vm.scanStationObj.formceptionData.push(formceptionData);
                        pushed = true;
                    }
                });
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkIfEntryIsFormceptionEntry = function (fieldSlug) {
        try {
            checkFormceptionDuplicates();
            let formceptionEntry = false;
            angular.forEach(vm.scanStationObj.formceptionData, function (formception) {
                if (formceptionEntry === false) {
                    if (fieldSlug === formception['formceptionParentField']) {
                        formceptionEntry = true;
                    }
                }
            });

            return formceptionEntry;

        } catch (e) {
            console.log(e);
        }

    }

    vm.setSeconds = function (fieldSlug) {
        try {
            let d = new Date();
            let n = d.getSeconds();

            const timePicker = vm.scanStationTimePickerFields[fieldSlug];
            if (timePicker !== null && timePicker !== undefined && 'setSeconds' in timePicker) {
                timePicker.setSeconds(n);
            }

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * This method will be responsible to get a timepicker value, has a basic validation of:
     * check if the object datepicker exists
     * check if the method exists
     * @return 0 if something is invalid, otherwise the correct value.
     **/
    vm.getTimePicker = function (timePicker, getMethod) {
        try {
            if (timePicker !== null && timePicker !== undefined && getMethod in timePicker) {
                return timePicker[getMethod]();
            }
            return undefined;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This method sets the time format on key press
     **/
    vm.setTimeFromFieldChange = async function (fieldSlug, fieldIndex) {
        try {

            const timePicker = vm.scanStationTimePickerFields[fieldSlug];
            if (timePicker != null) {
                console.log(timePicker);
                let timeFormat = moment(timePicker).format('HH:mm:ss');
                console.log(timeFormat)

                vm.scanStationObj.dataToAdd[fieldSlug] = timeFormat;
                let field = vm.scanStationObj.scanStationFields[fieldIndex];
                await vm.getRules('no-event', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
            } else {
                vm.scanStationObj.dataToAdd[fieldSlug] = 'Invalid Time';
            }


        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This method is responsible to update the time value with some basic rules
     * to at least have a 0 on the left  for numbers lower than 10
     **/
    vm.updateTime = function (fieldSlug) {
        try {

            if (vm.scanStationTimePickerFields[fieldSlug] == null) {
                vm.scanStationTimePickerFields[fieldSlug] = vm.newDate;
            }

            const timePicker = vm.scanStationTimePickerFields[fieldSlug];
            // TODO: Adrian: here i can see vm.getTimePicker...
            let hours = vm.getTimePicker(timePicker, 'getHours');
            let minutes = this.getTimePicker(timePicker, 'getMinutes');
            let seconds = this.getTimePicker(timePicker, 'getSeconds');

            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            vm.scanStationObj.dataToAdd[fieldSlug] = hours + ':' + minutes + ':' + seconds;

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateTimeFromDataToAddField = async function (fieldSlug) {
        try {
            vm.scanStationTimePickerFields[fieldSlug] = moment(vm.scanStationObj.dataToAdd[fieldSlug], 'hh:mm:ss');

        } catch (e) {
            console.log(e);
        }

    }

    vm.clear = function (fieldSlug) {
        vm.scanStationDatePickerFields[fieldSlug] = null;
    };

    vm.toggleMin = function () {
        vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
        vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.open1 = function (fieldSlug) {
        try {

            vm.popup1.opened[fieldSlug] = true;

        } catch (e) {
            console.log(e);
        }

    };

    vm.setDate = function (year, month, day) {
        try {
            // TODO: Adrian we must figure it out what this is doing
            let fieldSlug = 'fieldSlug';

            vm.scanStationDatePickerFields[fieldSlug] = new Date(year, month, day);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.setScanStationDatePickerFieldValueFromEmpty = function (fieldSlug) {
        vm.scanStationDatePickerFields[fieldSlug] = new Date();
    }

    /**
     * This will refresh the data for
     * @param dateSrcData
     * @param fieldSlugToChange
     * @param addNumberOfDays
     */
    vm.refreshDatepicker = (dateSrcData, fieldSlugToChange, fieldSlugOrigin, addNumberOfDays = 0) => {

        let loadedNewDateData = DateService.currentDateToCheck(dateSrcData, addNumberOfDays);
        let formattedData = DateService.formatDate(loadedNewDateData);

        // To work with datepicker we just need to send an object of date
        vm.scanStationDatePickerFields[fieldSlugToChange] = loadedNewDateData;

        // to save the data we format before save it
        vm.scanStationObj.dataToAdd[fieldSlugToChange] = formattedData;
    }

    /**
     * Method to add X amount of days to a date field value if
     * @param fieldSlug
     * @param numberOfDaysToAdd
     */
    vm.updateDateFieldsWithNewValues = async function (fieldSlug, numberOfDaysToAdd) {

        // this will be checking if exist numberOfDaysToAdd and if doesn't it will set as 0
        let addNumberOfDays  = numberOfDaysToAdd ? +numberOfDaysToAdd : 0;

        angular.forEach(vm.fieldIdsThatChangeDates, async function (fieldCombination) {

            let dateSrcData = undefined;

            if (fieldCombination.fieldSlugWithNumberValue == fieldSlug) {

                let fieldDateObject = vm.scanStationFieldIdsToField[fieldCombination.dateToAddFromFieldId];

                // This is the way to get the data from another field, or undefined if we can't have a data src
                if(fieldDateObject != null && fieldDateObject.field_slug != undefined) {
                    dateSrcData = vm.scanStationObj.dataToAdd[fieldDateObject.field_slug];
                } else {
                    dateSrcData = undefined;
                }

                let loadedNewDateData = DateService.currentDateToCheck(dateSrcData, addNumberOfDays);

                vm.scanStationDatePickerFields[fieldCombination.fieldSlugToChange] = loadedNewDateData;
                await vm.updateDatePicker(fieldCombination.fieldSlugToChange);

            }

            // this case is checking that we have a field_slug to update the value, and only will be refreshing the data
            if(fieldCombination.triggerIfChanges != undefined && fieldCombination.triggerIfChanges.field_slug == fieldSlug){

                // loading the days from the dataToAdd days quantity
                let fieldSlugToSearchForData = vm.scanStationFieldIdsToField[fieldCombination.fieldIdSrc].field_slug;
                let days = vm.scanStationObj.dataToAdd[fieldSlugToSearchForData];

                // loading the value of the date that was changed
                let dateSrcSlugField = vm.scanStationFieldIdsToField[fieldCombination.dateToAddFromFieldId];
                let changedDataValue = vm.scanStationObj.dataToAdd[dateSrcSlugField.field_slug];

                vm.refreshDatepicker(changedDataValue, fieldCombination.fieldSlugToChange, fieldCombination.triggerIfChanges.field_slug,days);
            }

        });
    }

    vm.today = async (fieldSlug) => {
        try
        {
            if (typeof (vm.scanStationDatePickerFields) == 'undefined') {
                vm.scanStationDatePickerFields = {};
            }

            vm.setScanStationDatePickerFieldValueFromEmpty(fieldSlug);
            await vm.updateDatePicker(fieldSlug);

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will update a datepicker.
     *
     * @param fieldSlug
     */
    vm.updateDatePicker = async (fieldSlug) => {
        try
        {
            if (typeof (vm.scanStationDatePickerFields[fieldSlug]) == 'undefined') {
                vm.setScanStationDatePickerFieldValueFromEmpty(fieldSlug);
            }

            // adding the new date, making sure that we have 2-digit on day,month
            vm.scanStationObj.dataToAdd[fieldSlug] = DateService.formatDate(vm.scanStationDatePickerFields[fieldSlug]);

            // Update of the field on get rules
            let field = vm.scanStationFieldSlugsToField[fieldSlug];
            await vm.getRules('no-event', field.id, vm.scanStationFieldIdsToField[field.id].special_field_key, vm.scanStationObj.dataToAdd[fieldSlug], field.fieldIndex, fieldSlug);

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateDateFromDataToAddField = function (fieldSlug) {
        try {
            if (vm.scanStationObj.isInEditMode && typeof vm.scanStationDatePickerFields[fieldSlug] == 'undefined') {
                vm.scanStationDatePickerFields[fieldSlug] = moment(vm.scanStationObj.dataToAdd[fieldSlug], 'DD/M/YYYY').toDate();
            }
            if (vm.scanStationDatePickerFields[fieldSlug] != '' && vm.scanStationDatePickerFields[fieldSlug] != null) {
                vm.scanStationDatePickerFields[fieldSlug] = moment(vm.scanStationObj.dataToAdd[fieldSlug], 'DD/M/YYYY').toDate();
            }

        } catch (e) {
            console.log(e);
        }

    }

    function getDayClass(data) {
        try {
            let date = data.date,
                mode = data.mode;

            if (mode === 'day') {
                let dayToCheck = new Date(date).setHours(0, 0, 0, 0);
                for (let i = 0; i < vm.events.length; i++) {
                    let currentDay = new Date(vm.events[i].date).setHours(0, 0, 0, 0);
                    if (dayToCheck === currentDay) {
                        return vm.events[i].status;
                    }
                }
            }

            return '';

        } catch (e) {
            console.log(e);
        }

    }


    vm.updateEnterDataController = function (updatedData, dataField) {
        try {
            Data.updateEnterDataController(updatedData, dataField);
            // $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    };

    function checkFormceptionDuplicates() {
        try {
            if (vm.scanStationObj.formceptionData.length > 1) {
                let uniqueFormceptionData = [];
                angular.forEach(vm.scanStationObj.formceptionData, function (formceptionData, index) {
                    if (index > 0) {
                        if (formceptionData['formceptionParentField'] !==
                            vm.scanStationObj.formceptionData[index - 1]['formceptionParentField']) {
                            uniqueFormceptionData.push(formceptionData);
                        }
                    }
                });

                vm.scanStationObj.formceptionData = uniqueFormceptionData;
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteData = async function (entryID) {
        try {

            await Data.deleteData(entryID);
            vm.scanStationObj.isInEditMode = false;

            await vm.getData(vm.scanStationObj.id);

            if (vm.filterData !== false) {
                await vm.getDataForDataFiltering('all', vm.filterDataOptions);
            }

            vm.scanStationObj.stationDataID = undefined;

            for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
                let field = vm.scanStationObj.scanStationFields[i];
                if (field.triggerRulesBeforeDelete == 1) {
                    await vm.triggerRulesAndWaitFor1Second(field);
                    $scope.$apply();
                }
            }

            await vm.resetData(1);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    };

    vm.updateLocalMathsResults = async function () {
        try {
            angular.forEach(vm.scanStationMathsResultsFields, async function (mathsFieldResultParameters, mathsFieldResultIndex) {
                let currentMathsParameters = angular.copy(mathsFieldResultParameters);
                let processMathsField = false;
                angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                    if (typeof vm.scanStationObj.dataToAdd[field.field_slug] != 'undefined' && vm.scanStationObj.dataToAdd[field.field_slug] != null) {
                        currentMathsParameters.equation = currentMathsParameters.equation.toString().replace('[' + field.field_slug + ']', vm.scanStationObj.dataToAdd[field.field_slug]);
                        processMathsField = true;
                    }
                });

                try {
                    try {
                        if (eval(currentMathsParameters.equation) === undefined) {
                            vm.scanStationObj.dataToAdd[mathsFieldResultIndex] = currentMathsParameters.defaultText;
                        } else {
                            vm.scanStationObj.dataToAdd[mathsFieldResultIndex] = eval(currentMathsParameters.equation);
                            await vm.setNumericField(vm.scanStationFieldSlugsToField[mathsFieldResultIndex].id, mathsFieldResultIndex, vm.scanStationObj.dataToAdd[mathsFieldResultIndex], vm.scanStationFieldSlugsToField[mathsFieldResultIndex].fieldIndex);
                            $scope.$apply();
                        }
                    } catch (error) {
                        vm.scanStationObj.dataToAdd[mathsFieldResultIndex] = 'blocworx-unset-maths';
                        return false;
                    }

                } catch (e) {
                    vm.scanStationObj.dataToAdd[mathsFieldResultIndex] = 'blocworx-unset-maths';
                    throw e; // this will throw the exception to the main try catch
                }

            });

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getTotals = async function (fieldSlug, totalResultsAssociatedFields, valueToFind) {
        try {
            if (valueToFind === undefined || valueToFind === 0 || !valueToFind || vm.scanStationObj.isInEditMode == true) {
                return;
            }
            if (typeof vm.scanStationFieldIdsToField[totalResultsAssociatedFields.keyFieldId] != 'undefined' && typeof vm.scanStationFieldIdsToField[totalResultsAssociatedFields.fieldIdToCount] != 'undefined') {
                let parentField = vm.scanStationFieldIdsToField[totalResultsAssociatedFields.keyFieldId].field_slug;
                let childField = vm.scanStationFieldIdsToField[totalResultsAssociatedFields.fieldIdToCount].field_slug;
                let total = 0;


                angular.forEach(vm.scanStationObj.liveData, function (row) {
                    if (row[parentField] == valueToFind) {
                        if (row[childField] != 'N/A') {
                            total += parseFloat(row[childField]);
                        }
                    }
                });

                if (total) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = total;
                    await vm.setNumericField(vm.scanStationFieldSlugsToField[fieldSlug].id, fieldSlug, vm.scanStationObj.dataToAdd[fieldSlug], vm.scanStationFieldSlugsToField[fieldSlug].fieldIndex);
                    $scope.$apply();
                } else if (total == 0) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = '0';
                }
                //TODO FUTURE REVIEW WHY THIS WAS BEING CALLED
                // This is related somehow to viewing all the results field underneath, but its unused functionality
                //await vm.getTotalsResults();
            }

        } catch (e) {
            console.log(e);
        }
    }

    vm.getTotalsResults = async function () {
        try {
            vm.allResultsArray = {};
            angular.forEach(vm.scanStationObj.scanStationFields, async function (field) {
                if (field.field_type == 'total-results' && typeof vm.scanStationFieldIdsToField[field.totalResultsAssociatedFields.keyFieldId] != 'undefined' && typeof vm.scanStationFieldIdsToField[field.totalResultsAssociatedFields.fieldIdToCount] != 'undefined') {
                    let parentField = vm.scanStationFieldIdsToField[field.totalResultsAssociatedFields.keyFieldId].field_slug;
                    let valueToFind = vm.scanStationFieldIdsToField[field.totalResultsAssociatedFields.fieldIdToCount].field_slug;
                    await vm.getTotals(field.field_slug, field.totalResultsAssociatedFields, valueToFind);
                    vm.allResultsArray[field.field_name] = vm.scanStationObj.dataToAdd[field.field_slug];
                }
            });

        } catch (e) {
            console.log(e);
        }
    }

    vm.createFieldVisibilityParameters = function () {
        vm.fieldVisibilityParameters = {
            'Hide in Excel Report': 'report_list',
            'Hide in Data Results Box': 'results_box',
            'Visually Hide in the form but remain functional': 'in_form',
            'Hide in Mail Alerts': 'hide_from_mail_alerts',
            'Hide from External Data Look Up': 'hide_from_external_data_lookup',

        }
    }

    vm.setFieldVisibilityParameter = async function (fieldID, visibilityParam) {
        try {
            await scanStation.setFieldVisibilityParameter(fieldID, visibilityParam);
            await vm.getFieldVisibilityOptions(fieldID);
            await vm.triggerBlocUpdateSuccess('Field Visibilty Parameter Added');
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.getFieldVisibilityOptions = async function (fieldID) {
        try {
            let data = await scanStation.getFieldVisibilityOptions(fieldID);
            vm.currentFieldVisibilityParameters = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.deleteFieldVisibilityOptions = async function (hiddenFieldOption) {
        try {
            let fhID = hiddenFieldOption.id;
            await scanStation.deleteFieldVisibilityOptions(fhID);
            await vm.getFieldVisibilityOptions(hiddenFieldOption.field_id);
            await vm.triggerBlocUpdateSuccess('Field Visibilty Parameter Removed');


            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.setFormVisibilityParameter = async function (scanStationID, visibilityParam) {
        try {
            await scanStation.setFormVisibilityParameter(scanStationID, visibilityParam);
            await vm.getFormVisibilityParameter(scanStationID);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.getFormVisibilityParameter = async function (scanStationID) {
        try {
            let data = await scanStation.getFormVisibilityParameter(scanStationID);
            vm.currentFormVisibilityParameter = data.data.data;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.printToPDF = async function (scanStationID, dataToAdd) {
        try {
            vm.reportDownloadUrl = "";
            let response = await scanStation.printToPDF(scanStationID, dataToAdd, vm.chosenTemplateFile, vm.pdfOrWord);
            let blob = new Blob([response.data], {
                type: 'application/octet-stream'
            });

            let URL = window.URL || window.webkitURL;
            vm.reportDownloadUrl = URL.createObjectURL(blob);


            let element = await vm.getElementToClick('downloadPdfBtn');

            $scope.$apply();
            element.click();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getElementToClick = function (element) {
        return document.getElementById(element);
    }

    vm.parseResultsBoxFieldData = async function (field, fieldSlug) {
        try {

            if (typeof (field.resultsBoxReverseOrder) == 'undefined') {
                field.resultsBoxReverseOrder = 0;
            }

            vm.parseResultsBoxArray[fieldSlug] = {
                'scanStationID': field.resultsBoxStationAndFields.stationId,
                'resultsBoxRemoteFieldId': field.resultsBoxStationAndFields.remoteFieldId,
                'resultsBoxLocalFieldId': field.resultsBoxStationAndFields.localFieldId,
                'sortByFieldId': field.resultsBoxStationAndFields.sortByFieldId,

                'resultsBoxReverseOption': field.resultsBoxReverseOrder
            }

            let data = await scanStation.getScanStationDetails(vm.parseResultsBoxArray[fieldSlug].scanStationID);
            let stationDetails = data.data.data[0];

            vm.parseResultsBoxArray[fieldSlug].jobID = stationDetails.job_id;

            if (stationDetails.independant_station_slug != null) {
                vm.parseResultsBoxArray[fieldSlug].scanStationIndependantStationSlug = stationDetails.independant_station_slug;
            }

            data = await scanStation.getScanStationFields(vm.parseResultsBoxArray[fieldSlug].scanStationID);
            vm.parseResultsBoxFieldsArray[fieldSlug] = data.data.data;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * This is the way to load the result box data on the
     * scan_station_data table.
     *
     * @param field
     * @param row
     * @param filterDataToQuery
     */
    vm.loadResultBox = async (field, row, filterDataToQuery) => {
        try {
            let fieldSlug = field.field_slug;
            let scanStationId = field.resultsBoxStationAndFields.stationId;
            let scanStationDataId = row.cartolytics_entry_row_id;

            // loading the table header
            let data = await scanStation.getScanStationFields(scanStationId);
            vm.resultBoxHeaderData[fieldSlug] = data.data.data;

            let scanStationDetails = await scanStation.getScanStationDetails(scanStationId);
            let stationDetails = scanStationDetails.data.data[0];

            vm.resultBoxArray[fieldSlug] = {
                'scanStationID': field.resultsBoxStationAndFields.stationId,
                'resultsBoxRemoteFieldId': field.resultsBoxStationAndFields.remoteFieldId,
                'resultsBoxLocalFieldId': field.resultsBoxStationAndFields.localFieldId,
                'resultsBoxReverseOption': field.resultsBoxReverseOrder,
                'jobID': stationDetails.job_id,
                'scanStationIndependantStationSlug': stationDetails.independant_station_slug
            }

            angular.forEach(vm.resultBoxArray, async function (parseResults, fieldSlug) {

                if (typeof vm.scanStationFieldIdsToField[parseResults.resultsBoxLocalFieldId] != 'undefined') {

                    let dataFilteringData = <DataFiltering>{};
                    dataFilteringData.stationID = parseResults.scanStationID;
                    dataFilteringData.resultLimit = 500;
                    dataFilteringData.currentPage = 1;
                    dataFilteringData.filterDataToQuery = {};
                    dataFilteringData.filterDataToQuery[parseResults.resultsBoxRemoteFieldId] = [filterDataToQuery];
                    dataFilteringData.currentStationID = vm.id;
                    dataFilteringData.reverse = parseResults.resultsBoxReverseOption;

                    if (parseResults.sortByFieldId != null) {
                        dataFilteringData.sortByFieldId = parseResults.sortByFieldId;
                    }

                    // changing from field slug to scan_station_data_id
                    vm.resultBoxData[scanStationDataId] = await Data.loadResultBoxData(dataFilteringData, 'fieldId');
                }
            });

            $scope.$apply();


        } catch (e) {
            console.log(e);
        }

    }

    vm.getResultsBoxArrayData = async function (resultsBoxFieldSlug, data) {
        try {
            angular.forEach(vm.parseResultsBoxArray, async function (parseResults, fieldSlug) {
                if (typeof vm.scanStationFieldIdsToField[parseResults.resultsBoxLocalFieldId] != 'undefined' && resultsBoxFieldSlug == vm.scanStationFieldIdsToField[parseResults.resultsBoxLocalFieldId].field_slug) {
                    let dataFilteringData = <DataFiltering>{};
                    dataFilteringData.stationID = parseResults.scanStationID;
                    dataFilteringData.resultLimit = 500;
                    dataFilteringData.currentPage = 1;
                    dataFilteringData.filterDataToQuery = {};
                    dataFilteringData.filterDataToQuery[parseResults.resultsBoxRemoteFieldId] = [data];
                    dataFilteringData.currentStationID = vm.id;
                    dataFilteringData.reverse = parseResults.resultsBoxReverseOption;
                    if (parseResults.sortByFieldId != null) {
                        dataFilteringData.sortByFieldId = parseResults.sortByFieldId;
                    }
                    await vm.getFilteredData(dataFilteringData, fieldSlug, 'fieldId');
                    $scope.$apply();
                }
            });


        } catch (e) {
            console.log(e);
        }

    }

    vm.editCheckFields = async function (entryRow) {
        try {
            vm.alreadyUpdatedSubFormChildFields = [];

            // factory step: vm.subformArray
            angular.forEach(entryRow, function (data, dataSlug) {
                angular.forEach(vm.subformArray, function (subFormDatum, subFormSlug) {
                    let field = vm.scanStationFieldSlugsToField[subFormSlug];
                    angular.forEach(field.subFormParentChildFields, function (parentChildCombo) {
                        if (typeof vm.scanStationFieldSlugsToField[dataSlug] != 'undefined' && vm.scanStationFieldSlugsToField[dataSlug].id == parentChildCombo.parentFieldId) {
                            vm.updateSubForm(data, subFormDatum['subFormParentChildFields'], parentChildCombo.childFieldId, subFormSlug);
                        }
                    });
                });
            });

            angular.forEach(vm.scanStationObj.scanStationFields, async function (field) {

                angular.forEach(entryRow, async function (data, key) {
                    if (key == field.field_slug) {
                        if (field.field_type === 'results-box') {
                            await vm.getFilteredData(JSON.parse(data), key, 'fieldId');
                        }
                        if (field.field_type == 'date-time-selector') {
                            if (field.dateTimeSetting == 'time') {
                                vm.updateTimeFromDataToAddField(field.field_slug);
                            }
                            if (field.dateTimeSetting == 'date') {
                                vm.updateDateFromDataToAddField(field.field_slug);
                            }
                        }
                        if (field.field_type == 'logged-in-user-info') {

                            // TODO clean up confusion with username from main.username,

                            if (field.userInfoRequired != 'username') {
                                //vm.scanStationObj.dataToAdd[field.field_slug] = '';vm.scanStationObj.dataToAdd[field.field_slug] = vm.loggedInUsersName;
                            }
                            if (field.userInfoRequired == 'username') {
                                //vm.scanStationObj.dataToAdd[field.field_slug] = '';vm.scanStationObj.dataToAdd[field.field_slug] = vm.loggedInUsersEmail;
                            }
                        }
                    }
                });
            });
        } catch (e) {
            console.log(e);
        }

    }

    vm.getFilteredData = async function (dataFilteringData, fieldSlug, fieldIdOrFieldSlug) {
        try {
            let data = await Data.getDataForDataFiltering(dataFilteringData, fieldIdOrFieldSlug);
            vm.parseBoxResults[fieldSlug] = data.data.data;
            angular.forEach(vm.parseBoxResults[fieldSlug], function (row, rowKey: string) {
                if (rowKey == 'count' || rowKey == 'fieldCounts' || rowKey == 'countLimitInfo' || rowKey == 'fieldNames') {
                    delete vm.parseBoxResults[fieldSlug][rowKey];
                }
            });
            vm.scanStationObj.dataToAdd[fieldSlug] = JSON.stringify(dataFilteringData);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.openInNewTab = function (type, param1, param2) {
        try {
            let url = '';

            if (type == 'scan-station') {
                url = $state.href('scan-station', {id: param1, stationId: param2});
            } else if (type == 'module') {
                url = $state.href('module-form', {uniqueModuleSlug: param1, stationId: param2});
            }
            $window.open(url, '_blank');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.updateDynamicCheckboxField = function (fieldSlug, populateCheckedValueElseWhereFieldID, event) {
        try {
            if (event.target.checked == true) {
                let fieldSlugToGetDataFrom = vm.scanStationFieldIdsToField[populateCheckedValueElseWhereFieldID].field_slug;
                vm.scanStationObj.dataToAdd[fieldSlug] = vm.scanStationObj.dataToAdd[fieldSlugToGetDataFrom];
                vm.checkboxesWithDynamicDataFields[fieldSlug] = vm.scanStationObj.dataToAdd[fieldSlug];
            } else {
                vm.checkboxesWithDynamicDataFields[fieldSlug] = 'blocworx-empty';
                vm.scanStationObj.dataToAdd[fieldSlug] = '';
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.flipResults = function () {
        try {
            // let resultsHeadWidth = $('.results-thead').outerWidth();
            // $('.form-results-section tbody').css('position','absolute');
            // $('.form-results-section tbody').css('left',resultsHeadWidth + 10 + 'px');
            // let elem = $('.form-results-tbody').children().last();
            // //let offset = elem.offset().left - elem.parent().offset().left;
            // alert(elem.offset().right);

            //$scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateAutoGenerateFields = async function () {
        try {
            angular.forEach(vm.scanStationObj.scanStationFields, async function (field) {
                if (!vm.isInEditMode) {
                    await vm.getAutoGenerateFieldData(field, field.field_type, field.field_slug)
                }
            });

        } catch (e) {
            console.log(e);
        }

    }

    vm.setScanStationFieldParameter = async function (fieldID, fieldParameter, paramValue, parameterInstruction = '') {
        try {
            vm.updating = true;
            let data = await scanStation.setScanStationFieldParameter(fieldID, fieldParameter, paramValue, parameterInstruction);
            $rootScope.$broadcast('reloadSpecificGrids', [vm.modifyingGridId]);

            vm.updating = false;
            await vm.triggerBlocUpdateSuccess('Field Parameter Updated');

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }


    vm.checkShowCounter = function (fieldSlug) {
        try {
            angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                if (fieldSlug == field.field_slug) {
                    angular.forEach(vm.scanStationObj.liveData.fieldCounts, function (value, liveDataSlug) {

                        if (fieldSlug == liveDataSlug && field.show_counter == 1 && field.includeForShowCounter == 1) {

                            angular.forEach(value, function (data, valueSlug) {
                                if (vm.scanStationObj.dataToAdd[fieldSlug] === valueSlug) {
                                    vm.fieldEntryCount[fieldSlug][vm.scanStationObj.dataToAdd[fieldSlug]] =
                                        vm.scanStationObj.liveData.fieldCounts[liveDataSlug][valueSlug] + 1;
                                }
                            });

                        }

                    });
                }
            })

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkIfExcludedFromLastEntryDataPopulating = function (fieldSlug) {
        try {
            angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                if (fieldSlug == field.field_slug) {
                    if (typeof (field.excludeFromGetLatestData) != 'undefined' && field.excludeFromGetLatestData == '1') {
                        alert('true ' + field.field_slug)
                        return true;
                    } else {
                        alert('false ' + field.field_slug)
                        return false;
                    }
                }
            });

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.exportFilteredData = function () {
        try {
            let blob = new Blob([document.getElementById('exportable').innerHTML], {
                type: "text/csv"
            });
            saveAs(blob, "Report.csv");

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.calculateTimeDifference = function (fieldSlug, field, timeDiffField) {
        try {
            if (field.timeOrDateDifferenceOption === 'time') {
                let x = moment(vm.scanStationObj.dataToAdd[timeDiffField.startFieldSlug], field.timeDateDifferenceFormat, true);
                let y = moment(vm.scanStationObj.dataToAdd[timeDiffField.finishFieldSlug], field.timeDateDifferenceFormat, true);

                // optional format for the second field, defaults to the first format if none exists
                if(field.timeDateDifferenceFormatForEndField != null && field.timeDateDifferenceFormatForEndField != '') {
                    y = moment(vm.scanStationObj.dataToAdd[timeDiffField.finishFieldSlug], field.timeDateDifferenceFormatForEndField, true);
                }

                if (!x.isValid() || !y.isValid()) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = 'Incorrect Format'
                } else {
                    vm.scanStationObj.dataToAdd[fieldSlug] = moment.utc(y.diff(x)).format("HH:mm");
                    if (typeof field.defaultResultFormat != 'undefined' && field.defaultResultFormat == 'with-decimal-points') {
                        vm.scanStationObj.dataToAdd[fieldSlug] = parseFloat(moment.duration(vm.scanStationObj.dataToAdd[fieldSlug]).asHours().toFixed(2));
                    }

                    if (typeof field.defaultResultFormat != 'undefined' && field.defaultResultFormat == 'round-to-hours') {
                        vm.scanStationObj.dataToAdd[fieldSlug] = Math.round(moment.duration(vm.scanStationObj.dataToAdd[fieldSlug]).asHours());
                    }


                }

            } else if (field.timeOrDateDifferenceOption === 'date') {
                let x = moment(vm.scanStationObj.dataToAdd[timeDiffField.startFieldSlug], field.timeDateDifferenceFormat, true);
                let y = moment(vm.scanStationObj.dataToAdd[timeDiffField.finishFieldSlug], field.timeDateDifferenceFormat, true);

                // optional format for the second field, defaults to the first format if none exists
                if(field.timeDateDifferenceFormatForEndField != null && field.timeDateDifferenceFormatForEndField != '') {
                    y = moment(vm.scanStationObj.dataToAdd[timeDiffField.finishFieldSlug], field.timeDateDifferenceFormatForEndField, true);
                }


                if (!x.isValid() || !y.isValid()) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = 'Incorrect Format'
                } else {
                    vm.scanStationObj.dataToAdd[fieldSlug] = y.diff(x, 'days');
                    if (typeof field.defaultResultFormat != 'undefined' && field.defaultResultFormat == 'with-decimal-points') {
                        vm.scanStationObj.dataToAdd[fieldSlug] = y.diff(x, 'days', true);
                    }

                }
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }


    vm.setScanStationParameter = async function (stationID, stationParam, paramValue) {
        try {
            vm.updating = true;
            let data = await scanStation.setScanStationParameter(stationID, stationParam, paramValue);
            if (data.data.data == 'Successfully Created') {

                // TODO: Get function must return things to add to something, this sounds like builder function
                await vm.getScanStationData(vm.scanStationObj.id, 'non-fields-related');
            }
            vm.updating = false;
            await vm.triggerBlocUpdateSuccess('Bloc Setting Applied');


            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.removeScanStationParameter = async function (stationID, stationParam) {
        try {
            vm.updating = true;
            let data = await scanStation.removeScanStationParameter(stationID, stationParam);
            vm.updating = false;
            await vm.triggerBlocUpdateSuccess('Bloc Setting Updated');
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }


    vm.createEmailAlertParam = async function (stationID, stationParam, emailAddressParam, emailParam) {
        try {
            let paramValue = {
                emailAddressParam: emailAddressParam,
                emailParam: emailParam
            };
            await vm.setScanStationParameter(stationID, stationParam, paramValue);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }


    }

    vm.getSelectData = async function (scanStationID, fieldSlug) {
        try {
            let data = await Data.getSelectData(scanStationID, fieldSlug);
            vm.emailAlertOptionEmails = [];
            vm.emailAlertOptionEmailsResults = [];

            angular.forEach(data.data.data, function (datum) {
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(datum[fieldSlug])) {
                        vm.emailAlertOptionEmailsResults.push(datum[fieldSlug])
                    }
                }
            )

            if (vm.emailAlertOptionEmailsResults.length > 0) {
                vm.emailAlertOptionEmails.push(vm.emailAlertOptionEmailsResults[vm.emailAlertOptionEmailsResults.length - 1]);
            }


            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.emailAlertOptionRemoveEmailAddress = function (index, email) {
        try {
            vm.removedEmailAddress.push(email);
            vm.emailAlertOptionEmails.splice(index, 1);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.emailAlertOptionAddRemovedEmailAddress = function (index, email) {
        try {
            vm.emailAlertOptionEmails.push(email);
            vm.removedEmailAddress.splice(index, 1);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.emailAlertOptionSendEmail = async function (recipients, emailMessage) {
        try {
            vm.waitingforAlert = true;
            await Data.emailAlertOptionSendEmail(recipients, emailMessage);
            vm.waitingforAlert = false;

            alert('Success! Your email has been sent.');
            vm.showEmailBox = false;

            $scope.$apply();

        } catch (e) {
            console.log(e);
            vm.waitingforAlert = false;
            alert('Unfortunately it looks like something went wrong with this. Please contact support@blocworx.com'
                + ' and please confirm the emails have not been received.');
        }

    }

    vm.setDataAddSuccess = async function (dataAddSuccess) {
        vm.dataAddSuccess = dataAddSuccess;
    }

    vm.hideDataAddSuccessMsg = async function () {
        // please keep timeout, this is not a workaround, its used to allow the message to remain there for a period of time before fading out.
        setTimeout(async function () {
            await vm.setDataAddSuccess(0);
        }, 600);
    }

    vm.addToAndGateParamArray = function (fieldSlug, fieldSlugToAdd, expectedValue) {
        try {
            if (typeof vm.andGateParamArray[fieldSlug] == "undefined") {
                vm.andGateParamArray[fieldSlug] = {};
            }
            if (typeof vm.andGateParamArray[fieldSlug]['fields'] == "undefined") {
                vm.andGateParamArray[fieldSlug]['fields'] = {};
            }

            if (typeof fieldSlugToAdd == 'undefined' || typeof expectedValue == 'undefined') {
                alert('Field or Expected Value cannot be empty');
            } else {
                vm.andGateParamArray[fieldSlug]['fields'][fieldSlugToAdd] = expectedValue;
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.addParamsToAndGateParamArray = function (fieldSlug, index, expectedValue) {
        try {
            if (typeof vm.andGateParamArray[fieldSlug] == "undefined") {
                vm.andGateParamArray[fieldSlug] = {};
            }

            if (typeof vm.andGateParamArray[fieldSlug][index] == "undefined") {
                vm.andGateParamArray[fieldSlug][index] = {};
            }

            if (typeof expectedValue == 'undefined') {
                vm.andGateParamArray[fieldSlug][index] = expectedValue;
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.initAndGateParams = function (fieldSlug) {
        try {
            if (typeof vm.andGateParamArray[fieldSlug] == "undefined") {
                vm.andGateParamArray[fieldSlug] = {};
            }

            if (typeof vm.andGateParamArray[fieldSlug]['pass'] == "undefined") {
                vm.andGateParamArray[fieldSlug]['pass'] = '';
            }

            if (typeof vm.andGateParamArray[fieldSlug]['fail'] == "undefined") {
                vm.andGateParamArray[fieldSlug]['fail'] = '';
            }


        } catch (e) {
            console.log(e);
        }

    }

    vm.removeFromAndGateParamArray = function (fieldSlug, fieldSlugToRemove) {
        try {
            delete vm.andGateParamArray[fieldSlug]['fields'][fieldSlugToRemove];

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.createAndGateParam = async function (fieldID, param, paramValue) {
        try {
            await vm.setScanStationFieldParameter(fieldID, param, (JSON.stringify(paramValue)));

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    // method related to the conditional value field parameter
    vm.parseAndGateParamCheckForPass = function (paramsField, ownerFieldSlug) {
        try {
            let pass = true;
            //

            let paramsFieldToUpdate = angular.copy(paramsField);
            // remove ones we may want to ignore
            angular.forEach(paramsField, function (expectedData, fieldSlug) {
                if (vm.scanStationFieldSlugsToField[ownerFieldSlug].conditionalValueIgnoreHiddenFields == 1 && !GridFactory.scanStationFormValidate(vm, vm.scanStationFieldSlugsToField[fieldSlug])) {
                    delete paramsFieldToUpdate[fieldSlug];
                }
            });
            angular.forEach(paramsFieldToUpdate, function (expectedData, fieldSlug) {
                // ignore fields if the setting is turned on
                if (expectedData.includes('between:') && pass == true) {
                    let expectedDataRemoveText = expectedData.replace('between:', '');
                    let numberSplit = expectedDataRemoveText.split(',');
                    let lowerNumber = parseFloat(numberSplit[0]);
                    let higherNumber = parseFloat(numberSplit[1]);
                    let numberToCheck = parseFloat(vm.scanStationObj.dataToAdd[fieldSlug]);
                    if (isNaN(lowerNumber) || isNaN(higherNumber) || isNaN(numberToCheck) || numberToCheck < lowerNumber || numberToCheck > higherNumber) {
                        pass = false;
                    } else {
                        pass = true;
                    }

                } else if (pass) {
                    if (vm.scanStationObj.dataToAdd[fieldSlug] != null && vm.scanStationObj.dataToAdd[fieldSlug] == expectedData) {
                        pass = true;
                    } else {
                        pass = expectedData == '[blocworx-any-word]' && vm.scanStationObj.dataToAdd[fieldSlug] != null && vm.scanStationObj.dataToAdd[fieldSlug] != '';
                    }
                }
            });

            return pass;

        } catch (e) {
            console.log(e);
        }

    }

    vm.parseAndGateParamCheckForPending = function (paramsField, ownerFieldSlug) {
        try {
            let pending = true;

            let paramsFieldToCheck = angular.copy(paramsField);

            // remove ones we may want to ignore

            angular.forEach(paramsField, function (expectedData, fieldSlug) {
                if (vm.scanStationFieldSlugsToField[ownerFieldSlug].conditionalValueIgnoreHiddenFields == 1 && !GridFactory.scanStationFormValidate(vm, vm.scanStationFieldSlugsToField[fieldSlug])) {
                    delete paramsFieldToCheck[fieldSlug];
                }
            });

            angular.forEach(paramsFieldToCheck, function (expectedData, fieldSlug) {
                pending = ((
                    typeof vm.scanStationObj.dataToAdd[fieldSlug] == 'undefined')
                    || vm.scanStationObj.dataToAdd[fieldSlug] == ''
                    || !GridFactory.scanStationFormValidate(vm, vm.scanStationFieldSlugsToField[fieldSlug]));
            });

            return pending;

        } catch (e) {
            console.log(e);
        }

    }

    vm.parseAndGateParam = async function (fieldID, specialFieldKey, currentSelectedFieldIndex) {
        try {
            if (vm.andGateParamArray != 'null') {
                angular.forEach(vm.andGateParamArray, async function (params, index) {
                    let resultChange = false;
                    let andGatePending = vm.parseAndGateParamCheckForPending(params['fields'], index);
                    if (!andGatePending) {
                        let andGateSuccess = vm.parseAndGateParamCheckForPass(params['fields'], index);
                        if (!andGateSuccess) {
                            if (params['fail'] != null && vm.scanStationObj.dataToAdd[index] != params['fail']) {
                                vm.scanStationObj.dataToAdd[index] = params['fail'];
                                resultChange = true;
                            }
                        } else {
                            if (params['pass'] != null && vm.scanStationObj.dataToAdd[index] != params['pass']) {
                                vm.scanStationObj.dataToAdd[index] = params['pass'];
                                resultChange = true;
                            }
                        }
                    } else {
                        if (params['pending'] != null && vm.scanStationObj.dataToAdd[index] != params['pending']) {
                            vm.scanStationObj.dataToAdd[index] = params['pending'];
                            resultChange = true;
                        }
                    }

                    if (resultChange) {
                        await vm.getRules('no-event', fieldID, specialFieldKey, vm.scanStationObj.dataToAdd[index], currentSelectedFieldIndex, index);
                        await vm.triggerBackgroundDataUpdate(vm.scanStationObj.dataToAdd, vm.scanStationObj.id, vm.scanStationObj.dataToAdd[index]);
                    }
                });
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }


    function checkForNullAlert(param) {
        try {
            let notNull = false

            if (arguments.length > 0 && !notNull) {
                angular.forEach(arguments, function (arg) {
                    if (typeof arg == 'undefined' || arg.length < 0) {
                        notNull = true;
                        return notNull;
                    }
                });
            }

            return notNull;

        } catch (e) {
            console.log(e);
        }

    }

    // rewrite of the first function to accept array
    function checkForNullValues(valuesToCheck: Array<any>) {
        try {
            let notNull = false;
            if (valuesToCheck.length > 0) {
                angular.forEach(valuesToCheck, function (arg) {
                    if (typeof arg === 'undefined' || arg === null) {
                        notNull = true;
                        return notNull;
                    }
                })
            }

            return notNull;

        } catch (e) {
            console.log(e);
        }

    }

    vm.restrictByUsernameAddField = function (fieldToAdd) {
        try {
            if (typeof vm.scanStationObj.stationDetails.restrictDataByUsername == 'undefined'
                || vm.scanStationObj.stationDetails.restrictDataByUsername == null) {
                vm.scanStationObj.stationDetails.restrictDataByUsername = [];
            }

            vm.scanStationObj.stationDetails.restrictDataByUsername.push(fieldToAdd);

        } catch (e) {
            console.log(e);
        }

    }

    vm.restrictByUsernameDeleteField = function (index) {
        try {
            vm.scanStationObj.stationDetails.restrictDataByUsername.splice(index, 1);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.setScanStationParameterJSON = async function (fieldID, param, paramValue) {
        try {
            await vm.setScanStationParameter(fieldID, param, (JSON.stringify(paramValue)));
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.setScanStationParameterCommaSeparated = async function (fieldID, param, paramValue) {
        try {
            await vm.setScanStationParameter(fieldID, param, paramValue.toString());
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkInIFrame = function () {
        try {
            return window.self === window.top;

        } catch (e) {
            console.log(e);
        }

    }

    // two way communication between sub blocs and parent blocs

    $window.addEventListener('message', async function (data) {
        try {
            // Reading from Child Sub Bloc
            // Update Data based on data passed down from parent Bloc
            if (typeof(data.data.stationID) != 'undefined') {
                if (data.data.stationID == vm.scanStationObj.id) {
                    angular.forEach(vm.scanStationObj.scanStationFields, async function (field, index) {
                        if (field.id == data.data.childFieldId) {

                            vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[data.data.childFieldId].field_slug] = data.data.updatedData;

                            await vm.getRules('no-event', field.id, field.special_field_key,
                                vm.scanStationObj.dataToAdd[index], index, field.field_height);

                            await vm.triggerBackgroundDataUpdate(vm.scanStationObj.dataToAdd, vm.scanStationObj.id,
                                vm.scanStationObj.dataToAdd[index]);

                            $scope.$apply();

                        }
                    });

                    // a new sub bloc option to show the last entry in the sub bloc, common for sub blocs that only have one piece of data
                    if (data.data.clickShowMoreLastEntry == 1) {
                        vm.thisSubBlocLoadsLatestData = true;
                    }

                }
            }

            // Listening in Child Sub Bloc
            // Make the Sub Bloc field paramaters and details available inside the sub bloc to make use of more options
            if (data.data.subBlocFieldDetails != null) {
                vm.inSubBlocOwnerFieldDetails = data.data.subBlocFieldDetails;
                if (vm.inSubBlocOwnerFieldDetails.compactView == 1) {
                    $('.is-in-iframe').addClass('compact-view');
                }
            }

            // Listening in Parent Bloc
            if (data.data == 'updateAllSubForms') {
                angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                    if (field.field_type == 'formception-2') {
                        vm.updateAllSubForms();
                    }
                });

            }


        } catch (e) {
            console.log(e);
        }

    });


    function setSubFormFiltering(stationID, fieldSlug, data) {
        try {
            let dataFilteringData = <DataFiltering>{};
            dataFilteringData.stationID = stationID
            dataFilteringData.resultLimit = 10;
            dataFilteringData.currentPage = 1;
            dataFilteringData.filterDataToQuery = {};

            if (data.length > 0) {
                dataFilteringData.filterDataToQuery[fieldSlug] = [data];
            }

            return dataFilteringData;

        } catch (e) {
            console.log(e);
        }

    }

    vm.fetchSubFormDataFromParent = function () {
        window.parent.postMessage('updateAllSubForms', '*');
    }


    vm.updateAllSubForms = function () {
        angular.forEach(vm.scanStationObj.dataToAdd, function (data, fieldSlug) {
            if (vm.scanStationFieldSlugsToField[fieldSlug] != null) {
                let field_id = vm.scanStationFieldSlugsToField[fieldSlug].id;
                angular.forEach(vm.subformArray, function (subFormDatum, subFormSlug) {
                    let fieldSlugToUpdate = null;
                    let field = vm.scanStationFieldSlugsToField[subFormSlug];
                    angular.forEach(field.subFormParentChildFields, function (parentChildCombo) {
                        if (field_id == parentChildCombo.parentFieldId) {
                            vm.updateSubForm(data, subFormDatum['subFormParentChildFields'], parentChildCombo.childFieldId, subFormSlug, subFormDatum['clickShowMoreLastEntry']);
                        }
                    });

                });
            }
        });

        angular.forEach(vm.subformArray, function (subFormDatum, subFormSlug) {
            // set the sub bloc field details to make available inside the sub bloc
            vm.setSubBlocFieldDetails(subFormSlug);
        });

    }

    /**
     *  Method to set the sub bloc field details inside the sub bloc to allow for more functionality based on field settings
     *  @param subFormSlug
     */

    vm.setSubBlocFieldDetails = function (subFormSlug) {
        try {
            let dataToSend = {
                subBlocFieldDetails: vm.scanStationFieldSlugsToField[subFormSlug],
            }

            let subform = <HTMLIFrameElement>document.getElementById(vm.subformArray[subFormSlug]['subFormElementID']);
            if (subform != null) {
                subform.contentWindow.postMessage(dataToSend, '*');
            }

        } catch (e) {
            console.log(e);
        }
    }

    vm.updateSubForm = function (updatedData, masterParentFields, childFieldId, subFormSlug, clickShowMoreLastEntry) {
        try {
            let dataToSend = {
                stationID: vm.subformArray[subFormSlug]['subFormID'],
                updatedData: updatedData,
                childFieldId: childFieldId,
                clickShowMoreLastEntry: clickShowMoreLastEntry
            }

            let subform = <HTMLIFrameElement>document.getElementById(vm.subformArray[subFormSlug]['subFormElementID']);
            if (subform != null) {
                subform.contentWindow.postMessage(dataToSend, '*');
            }

        } catch (e) {
            console.log(e);
        }
    }


    vm.buildSubFormChildValues = async function (subFormParentChildFields, fieldSlug, formceptionLink) {
        try {
            let dataToPassInUrlForThisForm = {};
            vm.dataToPassInUrls = [];
            $scope.$watch(vm.scanStationObj.dataToAdd, function () {

                angular.forEach(subFormParentChildFields, function (subFormParentChildFieldPair) {
                    if (subFormParentChildFieldPair.parentFieldId != null && typeof vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[subFormParentChildFieldPair.parentFieldId].field_slug] != 'undefined') {
                        dataToPassInUrlForThisForm[subFormParentChildFieldPair.childFieldId] = vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[subFormParentChildFieldPair.parentFieldId].field_slug];
                    }
                });
                if (Object.keys(dataToPassInUrlForThisForm).length !== 0) {
                    formceptionLink = formceptionLink + '?urlData=' + JSON.stringify(dataToPassInUrlForThisForm).replace(/&/g, "[bw]ampersand[/bw]");
                }

                vm.finalFormceptionLink[fieldSlug] = formceptionLink;
            });


        } catch (e) {
            console.log(e);
        }

    }

    vm.loadJsonDataFromUrl = async function () {
        try {

            if (typeof ($state.params.urlData) != 'undefined' && typeof ($state.params.urlData) != 'undefined') {
                let urlData = JSON.parse($state.params.urlData);
                angular.forEach(urlData, function (value, fieldId) {
                    if (value != null) {
                        value = value.replace('[bw]ampersand[/bw]', '&');
                        if (typeof vm.scanStationFieldIdsToField[fieldId] != 'undefined') {
                            vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[fieldId].field_slug] = value;
                            let fieldIndex = 0;
                            // TODO See can we use indexOf
                            angular.forEach(vm.scanStationObj.scanStationFields, function (fieldValue, index) {
                                if (fieldId == fieldValue.id) {
                                    fieldIndex = index;
                                }
                            });

                            vm.getRules('no-event', fieldId, vm.scanStationFieldIdsToField[fieldId].special_field_key, value, fieldIndex, vm.scanStationFieldIdsToField[fieldId].field_slug);
                        }
                    }
                });
                $scope.$apply();
            }

            if (typeof ($state.params.urlFieldSlugData) != 'undefined' && typeof ($state.params.urlFieldSlugData) != 'undefined') {
                let urlFieldSlugData = JSON.parse($state.params.urlFieldSlugData);
                angular.forEach(urlFieldSlugData, function (value, fieldSlug) {
                    if (typeof vm.scanStationFieldSlugsToField[fieldSlug] != 'undefined') {
                        vm.scanStationObj.dataToAdd[fieldSlug] = value;
                        let fieldIndex = 0;
                        // TODO See can we use indexOf
                        angular.forEach(vm.scanStationObj.scanStationFields, function (fieldValue, index) {
                            if (fieldSlug == fieldValue.field_slug) {
                                fieldIndex = index;
                            }
                        });

                        setTimeout(function () {
                            vm.getRules('no-event', vm.scanStationFieldSlugsToField[fieldSlug].id, vm.scanStationFieldSlugsToField[fieldSlug].special_field_key, value, fieldIndex, vm.scanStationFieldSlugsToField[fieldSlug].field_slug);
                        }, 500);
                    }
                });
                $scope.$apply();
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.dataToFormSearchStations = function (input, fieldSlug) {
        try {
            let result = []
            angular.forEach(vm.dataToFormStations[fieldSlug], function (foundData) {
                if (typeof foundData['scan_station_name'] != 'undefined' && foundData['scan_station_name'].toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0) {
                    result.push(foundData['scan_station_name']);
                }
            });
            vm.dataToFormSearchResults[fieldSlug] = result;

        } catch (e) {
            console.log(e);
        }

    }

    vm.dataToFormGetJobScanStations = async function (jobID, fieldSlug) {
        try {
            if (jobID == null) {
                jobID = vm.scanStationObj.jobID;
            }

            let data = await scanStation.getJobScanStations(jobID, '');
            vm.dataToFormStations[fieldSlug] = data.data.data;
            await vm.buildDataToFormsJSONData(fieldSlug);
            if (vm.scanStationFieldSlugsToField[fieldSlug].formListLatestDataSlugs != null) {
                await vm.buildBlocListLatestData(fieldSlug);
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.buildDataToFormsJSONData = async function (fieldSlug) {
        try {
            if (typeof vm.scanStationFieldSlugsToField[fieldSlug] != 'undefined') {
                let field = vm.scanStationFieldSlugsToField[fieldSlug];
                if (typeof (field.formListLocalRemoteFields) != 'undefined') {
                    vm.jsonDataForDataToFormFields[fieldSlug] = [];
                    // first get the parents and children
                    let parentChildFieldCominationsObject = {};
                    angular.forEach(field.formListLocalRemoteFields, function (value) {
                        if (typeof vm.scanStationFieldIdsToField[value.localFieldId] != 'undefined') {
                            parentChildFieldCominationsObject[value.remoteFieldSlug] = vm.scanStationObj.dataToAdd[vm.scanStationFieldIdsToField[value.localFieldId].field_slug];
                        }
                    });
                    vm.jsonDataForDataToFormFields[fieldSlug]['dataToSend'] = JSON.stringify(parentChildFieldCominationsObject);
                }

            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.buildBlocListLatestData = async function (fieldSlug, filteringOptions = null) {
        try {
            if (typeof vm.scanStationFieldSlugsToField[fieldSlug] != 'undefined') {

                let stationIdsToCheck = [];

                angular.forEach(vm.dataToFormStations[fieldSlug], function (station) {
                    stationIdsToCheck.push(station.id);
                });

                let allFieldsData = vm.getAllFieldsData();

                let blocListLatestData = await scanStation.buildBlocListLatestData(vm.scanStationFieldSlugsToField[fieldSlug].id, stationIdsToCheck, filteringOptions, allFieldsData);

                console.log(blocListLatestData.data.data);
                angular.forEach(vm.dataToFormStations[fieldSlug], function (station) {
                    station.latestData = blocListLatestData.data.data[station.id];
                });

            }

            // $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }


    vm.dataToFormCheckLinkedStation = async function (dataToCheck, fieldToCheck, fieldToWatch, linkedStation, dataToFormFieldSlug, jobID) {
        try {

            let filteringOptions = setSubFormFiltering(linkedStation, fieldToCheck, dataToCheck);
            let data = await scanStation.dataToFormCheckLinkedStation(filteringOptions, fieldToWatch, linkedStation, jobID);

            vm.dataToFormStations[dataToFormFieldSlug] = data.data.data;

            if (vm.scanStationFieldSlugsToField[dataToFormFieldSlug].formListLatestDataSlugs != null) {
                await vm.buildBlocListLatestData(dataToFormFieldSlug, filteringOptions);
                $scope.$apply();
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.addToSyncStationFieldArray = function (syncedStationFieldArray, fieldToAdd) {
        try {
            if (typeof syncedStationFieldArray == 'undefined') {
                syncedStationFieldArray = [];
            }

            let duplicateField = vm.checkSyncArrayForDuplicates(syncedStationFieldArray, fieldToAdd);

            if (!duplicateField) {
                syncedStationFieldArray.push(fieldToAdd);
            } else {
                alert('Field already added');
            }

            return syncedStationFieldArray;

        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteFromSyncStationFieldArray = function (syncedStationFieldArray, index) {
        try {
            syncedStationFieldArray.splice(index, 1);
            return syncedStationFieldArray;

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkSyncArrayForDuplicates = function (syncedStationFieldArray, fieldToAdd) {
        try {
            let duplicatesFound = false;
            if (duplicatesFound === false) {
                angular.forEach(syncedStationFieldArray, function (field) {
                    if (fieldToAdd == field) {
                        duplicatesFound = true;
                    }
                });
            }

            return duplicatesFound;

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkSyncArrayForPrimaryField = function (fieldToAdd, primaryField) {
        try {
            if (fieldToAdd === primaryField) {
                alert('Secondary field cannot be Primary Field');
                return true;
            } else {
                return false;
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.profileSyncParseParamArray = function (primaryField, fullNameField, secondaryFields) {
        try {
            return {
                primaryField: primaryField,
                fullNameField: fullNameField,
                secondaryFields: secondaryFields
            };

        } catch (e) {
            console.log(e);
        }

    }

    vm.profileSyncSetScanStationParam = async function (scanStationID, primaryField, fullNameField, secondaryFields) {
        try {
            let scanStationParam = vm.profileSyncParseParamArray(primaryField, fullNameField, secondaryFields);
            await vm.setScanStationParameter(scanStationID, 'sync-with-profile', scanStationParam);
            await vm.checkInstanceForProfileSync();

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This method will be checking instance for the profile Sync.
     *
     * Adrian: can you explain this better?
     *
     */
    vm.checkInstanceForProfileSync = async function () {
        try {
            let data = await scanStation.checkInstanceForProfileSync(vm.scanStationObj.cartolyticsCustomerID);
            let result = data.data;

            if (result.result === true) {
                vm.ableToSyncToProfiles = false;
                vm.instanceSyncToProfiles = true;
                vm.mainStationSyncedToProfiles = result.stationID;
                vm.mainStationNameSyncedToProfiles = result.stationName;
                vm.mainStationSyncedToProfileModuleSlug = result.moduleSlug;
                vm.mainStationSyncedToProfilePrimaryField = result.primaryField;

                if (vm.scanStationObj.id === vm.mainStationSyncedToProfiles) {
                    vm.syncedToProfilesAbleToDelete = true;
                    vm.thisStationIsProfileStation = true;
                    vm.primaryProfileSyncField = result.primaryField;
                }
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteProfileSyncForInstance = async function (stationID) {
        try {
            await scanStation.deleteProfileSyncForInstance(stationID);
            vm.ableToSyncToProfiles = true;
            vm.instanceSyncToProfiles = false;
            vm.syncedToProfilesAbleToDelete = false;
            vm.profileSyncingPrimaryField = null;
            vm.profileSyncingFullNameField = null;
            vm.profileSyncingSecondaryArray = null;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.profileSyncExtraDataSetScanStationParam = async function (scanStationID, primaryField, fullNameField, secondaryFields) {
        try {
            if (typeof secondaryFields == 'undefined') {
                secondaryFields = [];
            }
            let scanStationParam = vm.profileSyncParseParamArray(primaryField, fullNameField, secondaryFields);
            scanStationParam['extraFields'] = profileSyncExtraDataSetOtherFields(primaryField, secondaryFields);

            await vm.setScanStationParameter(scanStationID, 'sync-with-profile-extra-data', scanStationParam);

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    function profileSyncExtraDataSetOtherFields(primaryField, secondaryFields) {
        try {
            let extraFields = [];

            angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                extraFields.push(field.field_slug);
            });

            angular.forEach(extraFields, function (field, index) {
                if (field === primaryField) {
                    extraFields.splice(index, 1);
                }
            });

            angular.forEach(extraFields, function (field, index) {
                if (secondaryFields.length !== 0) {
                    angular.forEach(secondaryFields, function (sField) {
                        if (field === sField) {
                            extraFields.splice(index, 1);
                        }
                    });
                }
            });

            return extraFields;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This method sends data to the Bartender Controller in order to drop a file to trigger a print
     * on Bartender (Using Bartender Integration
     * @return true if successful, otherwise false
     **/
    vm.generateFileForBartender = async function (fieldSlug) {
        try {

            let folderToDropInto = '';
            let fileNameToCreate = '';
            let fieldValuesToSend = {};

            let bartenderField = vm.scanStationFieldSlugsToField[fieldSlug];

            angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                if (field.field_slug == fieldSlug && field.field_type == 'bartender-file-generator') {

                    folderToDropInto = field.bartenderSftpUserName;
                    fileNameToCreate = field.bartenderFileName;
                    let bartenderFieldSlugs = field.bartenderFieldSlugs.split(',');
                    angular.forEach(bartenderFieldSlugs, function (fieldSlug) {
                        fieldValuesToSend[fieldSlug] = vm.scanStationObj.dataToAdd[fieldSlug];
                    });
                }
            });

            if (folderToDropInto === '') {
                // TODO: implement an exception that will show a specific window
                return false;
            }

            if (bartenderField.sftpOrBrowser == 'browser-download') {
                let textFileJson = JSON.stringify(fieldValuesToSend);
                let blob = new Blob([textFileJson], {type: "text/plain;charset=utf-8"});
                saveAs(blob, fileNameToCreate);
            } else {
                await scanStation.generateFileForBartender(folderToDropInto, fileNameToCreate, fieldValuesToSend);
                alert('Data has been sent to printer, please collect your label');
            }

            $scope.$apply();
            return true;
        } catch (exception) {
            console.log(exception)
            alert('Something went wrong with the print, please contact suppport@blocworx.com');
            return false;
        }
    }

    vm.getMergedFieldSlugs = function (fieldMergeFormat) {
        try {
            let mergedFieldSlugs = [];

            // for the cases that we do not have the Field Merge Format
            if (fieldMergeFormat == undefined) {
                return mergedFieldSlugs
            }

            let splitString = fieldMergeFormat.split('[BW]');
            angular.forEach(splitString, function (value, index) {
                if (index > 0) {
                    let remove_after = value.indexOf('[/BW]');
                    mergedFieldSlugs.push(value.substring(0, remove_after));
                }
            });

            $scope.$apply();
            return mergedFieldSlugs;

        } catch (e) {
            console.log(e);
        }
    }

    vm.detectFieldAndBuildMerge = function (fieldSlug) {
        try {
            angular.forEach(vm.fieldsUsedForMergeField, function (fieldsUsedAndFormat, mergedFieldSlug) {
                if (fieldsUsedAndFormat[1].includes(fieldSlug)) {
                    let newString = fieldsUsedAndFormat[0];
                    angular.forEach(fieldsUsedAndFormat[1], function (singleFieldSlug) {
                        let replacementValue = '';
                        if (typeof (vm.scanStationObj.dataToAdd[singleFieldSlug]) != 'undefined') {
                            replacementValue = vm.scanStationObj.dataToAdd[singleFieldSlug]
                        }
                        newString = newString.replace(singleFieldSlug, replacementValue);

                    });
                    newString = newString.replaceAll("[BW]", "");
                    newString = newString.replaceAll('[/BW]', '');
                    vm.scanStationObj.dataToAdd[mergedFieldSlug] = newString;
                }
            });
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.uploadMultipleFiles = async function (fieldSlug) {
        try {
            let files = vm.scanStationObj.multiFilesToAdd[fieldSlug];
            vm.pleasewait = true;

            let data = await scanStation.uploadMultipleFiles(vm.jobID, vm.id, files);
            if (typeof (vm.scanStationObj.dataToAdd[fieldSlug]) == 'undefined') {
                vm.scanStationObj.dataToAdd[fieldSlug] = '';
            }

            vm.scanStationObj.dataToAdd[fieldSlug] += data.data.data;
            alert('File uploaded');
            vm.pleasewait = false;
            $scope.$apply();

        } catch (e) {
            console.log(e);
            vm.pleasewait = false;
        }

    };

    /**
     * This will be responsible to update all checkbox data after click on the show more
     * button.
     */
    vm.cleanCheckboxFromStation = (scanStationObj) => {

        // TODO: Ask adrian what is the parameter to not clean the data
        scanStationObj.dataToAdd.checkboxData = "";
        scanStationObj.dataToAdd.checkboxFromStation = {};
        scanStationObj.isInViewMode = true;
    }


    /**
     * This function is triggered when we have an option to trigger graph on the:
     * Edit Mode -> Parameters -> Tick this box to trigger graph updates on the page
     * @param fieldSlug
     */
    vm.triggerChartUpdates = async function (fieldSlug) {
        try {
            // This is for the case when we are on edit mode, we just need to find a field to trigger
            // that is the way to get one of the fields slug to trigger the action.
            if (Array.isArray(fieldSlug)) {
                fieldSlug = fieldSlug.find(x => x !== undefined);
                if (typeof fieldSlug['field_slug'] !== 'undefined') {
                    fieldSlug = fieldSlug['field_slug'];
                } else {
                    fieldSlug = '';
                }
            }
            // TODO: check if needs the await before the async angular.forEach
            angular.forEach(vm.scanStationObj.scanStationFields, async function (field) {
                if (fieldSlug == field.field_slug) {
                    angular.forEach(field.parameters, async function (fieldParameter) {
                        // if we have the triggerGraph or value its the first one
                        if (fieldParameter.scan_station_field_parameter == 'triggerGraphUpdate' && fieldParameter.parameter_value == '1') {
                            // trigger pie charts
                            angular.forEach(vm.scanStationObj.scanStationFields, async function (fieldForCharts) {
                                if (fieldForCharts.field_type == 'pie-chart') {
                                    let withLabel = true;
                                    // TODO: check if this works without the timeout. maybe this is runing in the wrong time
                                    await PieChartField.buildPieChart(fieldForCharts, vm.scanStationObj.dataToAdd, withLabel, vm.scanStationFieldIdsToField);
                                    $scope.$apply();
                                }
                            });

                            // trigger echarts
                            $('.echart-button-refresh-data').each(function () {
                                $(this).trigger('click');
                            });
                        }
                    });
                }
            });


        } catch (e) {
            console.log(e);
        }
    }

    /*
    Method for adding multiple field show conditions, i.e. other fields must equal certain values for this field to appear
     */

    vm.addMultiFieldShowConditions = async function () {
        if (typeof(vm.multiFieldShowConditions) == 'undefined') {
            vm.multiFieldShowConditions = [];
            vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules] = [];
        } else if (typeof(vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules]) == 'undefined') {
            vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules] = [];
        }

        vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules] = Object.values(vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules]);

        vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules].push(
            {
                localFieldId: vm.selectedMultiShowConditionField,
                valueItMustEqual: vm.multiShowConditionValue,
            }
        );

        delete vm.selectedMultiShowConditionField;
        delete vm.multiShowConditionValue;
        await vm.setScanStationFieldParameter(vm.fieldIDForUpdatingRules, 'multiFieldShowConditions', vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules], 'force-array-to-json');

        $scope.$apply();

    }

    vm.removeMultiFieldShowCondition = async function (index) {

        delete vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules][index];
        await vm.setScanStationFieldParameter(vm.fieldIDForUpdatingRules, 'multiFieldShowConditions', vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules], 'force-array-to-json');

    }

    vm.startDigitalSignature = function (fieldSlug) {
        setTimeout(function () {
            // TODO remove timeout
            let canvas = document.getElementById("signature-pad-" + fieldSlug);
            if (typeof vm.signaturePad == 'undefined') {
                vm.signaturePad = [];
            }
            vm.signaturePad[fieldSlug] = new SignaturePad(canvas);
            // Rebinds all event handlers
            vm.signaturePad[fieldSlug].on();

        }, 300);

    }

    vm.addDigitalSignature = async function (fieldSlug) {
        const data = vm.signaturePad[fieldSlug].toDataURL('image/png');
        vm.scanStationObj.dataToAdd[fieldSlug] = data;
    };

    /**
     * Method that will return the status in a text format
     * @returns {boolean}
     */
     
    vm.scanStationFormContainerValidate = function () {

        // Refactored the validation statement
        let validation1 = (vm.requires_anti_static == 1 && vm.antiStaticSignedForUser != null);
        let validation2 = (validation1 || vm.requires_anti_static == null || vm.requires_anti_static == 0)
        let validation3 = ((vm.selectedSOPFiles.length == 0) || (vm.selectedSOPFiles.length > 0 && vm.SOPSignedForUser != null));

        return (validation2 && validation3);
    }

    vm.setNumericField = async function (fieldId, fieldSlug, data, fieldIndex) {

        if (vm.scanStationFieldIdsToField[fieldId].numericField != null && vm.scanStationFieldIdsToField[fieldId].numericField == 1) {
            let decimalPlaces = 2;
            if (vm.scanStationFieldIdsToField[fieldId].numericFieldDecimalPlaces != null) {
                decimalPlaces = vm.scanStationFieldIdsToField[fieldId].numericFieldDecimalPlaces;
            }
            let number = parseFloat(data);
            if (isNaN(number)) {
                $('#station-field-' + fieldIndex).addClass('minor-issue');
                return true;
            } else {
                vm.scanStationObj.dataToAdd[fieldSlug] = parseFloat(number.toFixed(decimalPlaces));
            }

            return true;

        } else {
            return true;
        }
    }

    vm.getRolesAndPermissions = async function () {
        try {
            let data = await RolesAndPermissionsFactory.getRolesAndPermissions();
            vm.rolesAndPermissions = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.updateStationRolesAndPermissions = async function () {
        try {
            let data = await RolesAndPermissionsFactory.updateStationRoles(vm.scanStationObj.stationDetails.role_groups, vm.id);
            await vm.updateScanStation('non-fields-related');
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.removeRoleFromExistingStation = async function (roleId) {
        let index = vm.scanStationObj.stationDetails.role_groups.indexOf(roleId);
        delete vm.scanStationObj.stationDetails.role_groups[index];
        await vm.updateStationRolesAndPermissions();
        $scope.$apply();
    }

    vm.sendIframeType = async function (iframeType) {
        $rootScope.$broadcast('iframeType', iframeType);
    }

    $scope.iframeType = $scope.$on('iframeType', async function (event, iframeType) {
        //$('.is-in-iframe').addClass(iframeType);
    });

    vm.setBlocMessage = async function (message) {
        vm.blocUpdateMessage = message;
        return true;
    }
    vm.triggerBlocUpdateSuccess = async function (message) {

        await vm.setBlocMessage(message);

        await $('.bloc-editor-update-complete').addClass('show');

        setTimeout(async function () {
            await $('.bloc-editor-update-complete').removeClass('show');
        }, 3000);
    }

    /**
     * This will run for the action to show the template field
     * after press the button tp edit the WYSIWYG
     * @param fieldId
     */
    vm.tinyMCEActionsToRender = (fieldId) => {
        vm.showEditorForUser[fieldId] = true;
        vm.tinyMceService.setTinyMCETemplate();
    }

    vm.filterFieldsLocked = function (fieldId, userRoles) {

        let isFilterByField = (vm.scanStationFieldIdsToField[fieldId].filterByField == 1 || vm.scanStationFieldIdsToField[fieldId].filterByFieldSubBloc == 1);
        let cannotOverrideLockedFields = (!userRoles.includes('admin') && !userRoles.includes('admin-front-end') && !userRoles.includes('can-unlock-filtered-fields'));
        let hasLockedStationSetting = (vm.scanStationObj.stationDetails.force_data_filtered_fields === 1);
        return (isFilterByField && cannotOverrideLockedFields && hasLockedStationSetting);
    }

    /**
     * This will return what class should be added to the main div inner-standard-box-wrapper
     */
    vm.getScanStationDataClass = (customizedLayout) => {
        try {

            // this is the main class for the 100% block
            let classname = 'custom-compact'

            if (customizedLayout) {
                // this is the classname when we have a small space
                classname = 'custom-full';
            }

            return classname;

        } catch (e) {
            console.log(e);
        }
    }

    vm.triggerGetDataFiltering = async function (e) {
        if (e.keyCode === 13 || e.keyCode === 9 || e.type === 'click') {

            vm.filterData = true;
            if (vm.blocworxFreeSearch != '' && vm.blocworxFreeSearch != null) {
                vm.addFilterData('blocworx_free_search', vm.blocworxFreeSearch);
            }

            await vm.getDataForDataFiltering('with-filter', vm.dataFilteringData);

            vm.blocworxFreeSearch = '';
            $scope.$apply();
        }
    }

    /*
    This method checks if we have the show more viewing set in the url for the bloc state
    its mainly used for sub blocs so we can decide if the person can edit things or not
     */

    vm.isShowMoreViewing = function () {
        let showMoreViewing = $state.params.showMoreViewing;
        let showMoreResult = (showMoreViewing == 'true' || showMoreViewing === true);
        return showMoreResult;
    }

    vm.updatePerPagePagination = async function () {
        vm.currentPage = 1;

        await vm.updateEnterDataController(vm.resultLimit, 'updateResultLimit');

        (vm.dataFilteringData.filterDataToQuery != null && !$.isEmptyObject(vm.dataFilteringData.filterDataToQuery)) ? await vm.getDataForDataFiltering('with-filter', vm.dataFilteringData) : await vm.getData(vm.scanStationObj.id)
        $scope.$apply();

    }

    /**
     * This will be responsible to load or not a class for the current data, that can be having
     * a rules:
     * mobile-off = this wont be showing on mobile
     * tablet-off = this wont be showing on tablet
     */
    vm.mobileAndTabletRules = (field) => {

        let classesToAdd = [];

        if (field !== undefined && field.hideFromMobile !== undefined && field.hideFromMobile == 1) {
            classesToAdd = [...classesToAdd, 'mobile-off']
        }

        if (field !== undefined && field.hideFromTablet !== undefined && field.hideFromTablet == 1) {
            classesToAdd = [...classesToAdd, 'tablet-off']
        }

        return classesToAdd.length == 0 ? '' : classesToAdd.join(' ');
    }

    vm.goBack = function () {
        window.history.back();
    }

    vm.initialiseCheckboxValue = function (fieldSlug) {
        let field = vm.scanStationFieldSlugsToField[fieldSlug];
        if (vm.scanStationObj.dataToAdd[fieldSlug] == null) {
            vm.scanStationObj.dataToAdd[fieldSlug] = field.falseValue;
        }
    }

    vm.initialiseDefaultButtonValue = function (fieldSlug) {
        let field = vm.scanStationFieldSlugsToField[fieldSlug];
        if (vm.scanStationObj.dataToAdd[fieldSlug] == null) {
            vm.scanStationObj.dataToAdd[fieldSlug] = field.defaultButtonValue;
        }
    }

    vm.initialiseDefaultValue = async function (fieldSlug) {
        let field = vm.scanStationFieldSlugsToField[fieldSlug];
        if (vm.scanStationObj.dataToAdd[fieldSlug] == null) {
            vm.scanStationObj.dataToAdd[fieldSlug] = field.defaultValue;
            if (field.applyRulesOnInitiation == 1) {
                await vm.getRules('after-submission', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
            }
        }
    }

    /*
    This method is used to see if the table cell has a value that is in the predetermined colour scheme
     */
    vm.checkCellColourScheme = function (row, fieldSlug) {

        if (vm.scanStationObj.stationDetails.row_colour_scheme_options != null && vm.scanStationObj.stationDetails.row_colour_scheme_options.dataList != null) {
            let hasColour = vm.scanStationObj.stationDetails.row_colour_scheme_options.dataList.includes(row[fieldSlug]);

            if (hasColour && vm.scanStationObj.stationDetails.row_colour_scheme_options[row[fieldSlug]] != null) {
                return vm.scanStationObj.stationDetails.row_colour_scheme_options[row[fieldSlug]].scheme;
            } else {
                return '';
            }

        } else {
            return '';
        }
    }

    /**
     * This is validating the standard form that shows with
     * all the fields from a scan station.
     */
    vm.validateStandardForm = () => {
        let valid = false;

        // if(vm.scanStationObj.showForm && vm.showMoreSingleDataBox != true){
        if (vm.scanStationObj.showForm) {
            valid = true;
        }

        return valid;
    }

    /**
     * This is responsible to remove the modal class on the body,
     * so we can avoid to scroll down when we are on a modal on
     * edit field.
     */
    vm.modalOff = () => {
        vm.modalStatus = 'off';
        $('body').removeClass('modal-on').addClass("modal-off");

        // if we have set update id, we should show it after close the popup
        if (vm.fieldIDForUpdatingRules !== null) {
            $('.show-field-rule-parameters').show();
        }
    }

    /**
     * This is responsible to remove the modal class on the body,
     * so we can avoid to scroll down when we are on a modal on
     * edit field.
     */
    vm.modalOn = () => {
        vm.modalStatus = 'on'
        $('body').removeClass('modal-off').addClass("modal-on");

        // if we have set update id, we should hide it so on ModdalOff can be showing again
        if (vm.fieldIDForUpdatingRules !== null) {
            $('.field-rules > .close-popup').trigger("click");
            $('.show-field-rule-parameters').hide();
        }
    }

    /**
     * This adds conditional for a field to search.
     *
     * @param fieldSlug
     * @param searchValue
     * @param event
     */
    vm.addConditionForFieldSearch = async function (fieldSlug, searchValue, event) {
        vm.addFilterData(fieldSlug, searchValue);
        vm.filterData = true;
        vm.specificFieldSearch = true;
        vm.currentPage = 1;
        vm.dataFilteringData.currentPage = 1;
        await vm.triggerGetDataFiltering(event);
    }

    /**
     * This will be getting a string and it will transform into
     * a slug.
     *
     * @param fieldName
     */
    vm.getFieldSlug = (fieldName: string) => {

        // check if we have some value
        if (fieldName == null) {
            return false
        }

        // step 1 : transform the field name to lower case and trim the value
        let fieldSlug = fieldName.toLowerCase().trim();

        // step 2 : replace space for _
        fieldSlug = fieldSlug.replace(/\s/g, '_');

        // step 3: adding slug before the field-slug, so .123456 will be .slug-123456
        if(fieldSlug != undefined && fieldSlug != ''){
            fieldSlug = `slug-${fieldSlug}`;

        }

        return fieldSlug;
    }

    /**
     Determines if we can show the astericks on a field or not for cannot be empty rules
     */
    vm.canShowAstericks = function (hasCannotBeEmptyRule, field) {
        if (vm.scanStationObj.stationDetails.hideAstericks != 1 && hasCannotBeEmptyRule == 1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     This function decides if we will show the reverse data tickbox along with whether it should be loading filtered data or not
     */
    vm.showFilteredReverseTickBox = function () {

        if (vm.filterDataOptions == null || (vm.filterDataOptions != null && vm.filterDataOptions.filterDataToQuery == null) || (vm.filterDataOptions.filterDataToQuery == null != null && vm.filterDataOptions.filterDataToQuery.length == 0)) {
            return 'get-data';
        } else {
            return 'get-filtered-data';
        }

    }

}

export default ScanStationController;